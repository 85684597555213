import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { PublicRoutes, QueryParamKeys, useLogout } from "src/common";
import { Loading } from "src/components";

function LogoutComponent() {
  const [searchParams] = useSearchParams();
  const [redirect_url, setRedirectURL] = useState<string | undefined>();
  const { handleStateLogout } = useLogout();

  useEffect(() => {
    handleLogout();
  }, []);

  function handleLogout() {
    handleStateLogout();
    // NOTE: If redirect comes in as a querystring handle custom redirect
    const query_redirect_url = searchParams.get(QueryParamKeys.REDIRECT_URL_QUERY_KEY);
    setRedirectURL(!!query_redirect_url ? query_redirect_url : `/${PublicRoutes.choose_account_route}`);
  }

  return !!redirect_url ? <Navigate to={redirect_url} /> : <Loading />;
}

export default React.memo(LogoutComponent);