import React, { FocusEvent } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { Checkbox, Icon } from "src/components";
import { AdminUIActionTypes, CheckboxStatesTypes } from "src/common";
import { Row, Col, Button } from "react-bootstrap";

type AllProps = {
  user_id: string;
  role: string;
  display_name: string;
  handleAction: ActionHandlerFunction;
  is_selected: CheckboxStatesTypes;

}

function UserExceptionListRow(props: AllProps) {
  const { user_id, handleAction, is_selected, display_name, role } = props;
  const formatted_user_id = user_id.toLowerCase();
  return (
    <Row className="align-items-center address-row">
      <Col sm={4} className="d-flex align-items-center">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleAction({ actionType: AdminUIActionTypes.SingleSelect, params: { e, id: user_id } })}
          value={user_id}
          selected={is_selected}
          label="Select User"
        />
        <p className="mb-0 ms-2">{display_name}</p>
      </Col>
      <Col sm={4} className="p-0">
        <p className="m-0">{formatted_user_id}</p>
      </Col>
      <Col sm={3} className="p-0 text-capitalize">
        <p className="m-0">{role}</p>
      </Col>
      <Col sm={1} className="text-end">
        <Button
          variant="icon"
          size="sm"
          onClick={() => handleAction({ actionType: AdminUIActionTypes.DeleteUserIdFromSyncPermissionList, params: { users: [user_id] } })}
          title="Remove User from Sync Permissions"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Remove User from Sync Permissions"
        >
          <Icon className="ficon-x" />
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(UserExceptionListRow);