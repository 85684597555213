import * as React from "react";
import { Link } from "react-router-dom";
import { Nav, Badge } from "react-bootstrap";
import { Icon } from "src/components";
import { Account, AppConfiguration, PrivateRoutes, useAppSelector } from "src/common";
import { RootState } from "src/store/configureStore";

type AllProps = {
  active_path?: string;
  handleAction: () => void;
};

function SettingsNavigationComponent(props: AllProps) {
  const pending_approvals = useAppSelector((state: RootState) => state.settings.pending_approvals);
  const organization = useAppSelector((state: RootState) => state.account.current_account)?.org_info;

  const { active_path } = props;
  return (
    <Nav className="mt-0">
      <Link
        title="Profile"
        className={`nav-link${active_path === PrivateRoutes.profile_route ? " active" : ""}`}
        to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.profile_route}`}
      >
        <Icon className="ficon-user" />
        <span>Profile</span>
      </Link>
      {!!AppConfiguration.buildForExpress() &&
        <>
          <Link
            title="Profile"
            className={`nav-link${active_path === PrivateRoutes.password_recovery_route ? " active" : ""} ${!!localStorage.getItem("express_recovery_not_setup") ? "dot" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.password_recovery_route}`}
          >
            <Icon className="ficon-save" />
            <span>Account Recovery</span>
          </Link>
          <Link
            title="Change Password"
            className={`nav-link${active_path === PrivateRoutes.change_password_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.change_password_route}`}
          >
            <Icon className="ficon-key" />
            <span>Change Password</span>
          </Link>
          <Link
            title="Change Authentication"
            className={`nav-link${active_path === PrivateRoutes.change_authentication_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.change_authentication_route}`}
            >
            <Icon className="ficon-shuffle" />
            <span>Change Authentication</span>
          </Link>
        </>
      }
      {!!AppConfiguration.buildForApp() &&
        <>
          <Link
            title="Approvals"
            className={`nav-link${active_path === PrivateRoutes.approvals_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.approvals_route}`}
          >
            <Icon className="ficon-check-circle" />
            <span>Approvals</span>
            {
              pending_approvals > 0 &&
              <Badge bg="info">{pending_approvals}</Badge>
            }
          </Link>
          <Link
            title="Activity Logs"
            className={`nav-link${active_path === PrivateRoutes.activity_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.activity_route}`}
          >
            <Icon className="ficon-file-text" />
            <span>Activity Logs</span>
          </Link>
          {
            (!organization || Account.isShowMUAPropt(organization)) &&
            <Link
              title="Add to Mail Client"
              className={`nav-link${active_path === PrivateRoutes.install_profile_route ? " active" : ""}`}
              to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.install_profile_route}`}>
              <Icon className="ficon-mail" />
              <span>Add to Mail Client</span>
            </Link>
          }
          <hr />
          <Link
            title="Device Management"
            className={`nav-link${active_path === PrivateRoutes.device_management_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.device_management_route}`}>
            <Icon className="ficon-monitor" />
            <span>Device Management</span>
          </Link>
          <Link
            title="Add Device"
            className={`nav-link${active_path === PrivateRoutes.add_device_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.add_device_route}`}>
            <Icon className="ficon-plus-square" />
            <span>Add Device</span>
          </Link>
          <hr />
          <Link
            title="Recovery Group"
            className={`nav-link${active_path === PrivateRoutes.recovery_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.recovery_route}`}>
            <Icon className="ficon-users" />
            <span>Recovery Group</span>
          </Link>
          <Link
            title="Account Recovery"
            className={`nav-link${active_path === PrivateRoutes.account_backup_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.account_backup_route}`}>
            <Icon className="ficon-code" />
            <span>Account Recovery</span>
          </Link>
          <Link
            title="Recovery Approval"
            className={`nav-link${active_path === PrivateRoutes.approve_recovery_route ? " active" : ""}`}
            to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.approve_recovery_route}`}>
            <Icon className="ficon-user-check" />
            <span>Recovery Approval</span>
          </Link>
        </>}
    </Nav>
  );
}

export default React.memo(SettingsNavigationComponent);
