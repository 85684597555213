import * as React from "react";
import { Badge, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OrgInfo } from "@preveil-api";
import { PrivateRoutes, OrgUserRoles, GlobalConstants, useAppSelector } from "src/common";
import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  organization: OrgInfo;
  active_path?: string;
};

function AdminNavigationComponent(props: AllProps) {
  const { active_path, organization } = props;
  const is_admin = !!organization && organization.role === OrgUserRoles.admin;
  const users_without_recovery = useAppSelector((state: RootState) => state.admin.users_without_recovery);

  return is_admin ?
    <Nav className="mt-0">
      <Link title="Users"
        className={`nav-link${active_path === PrivateRoutes.users_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.users_route}`}>
        <Icon className="ficon-user" />
        <span>Users</span>
        {
          (!!users_without_recovery && users_without_recovery > 0) &&
          <Badge bg="light" className={users_without_recovery > GlobalConstants.MAX_USERS_WITHOUT_RECOVERY_COUNT ? "exceed_limit" : ""}>
            {users_without_recovery}
          </Badge>
        }
      </Link>
      <Link title="Approval Groups"
        className={`nav-link${active_path === PrivateRoutes.approval_groups_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.approval_groups_route}`}>
        <Icon className="ficon-life-buoy" />
        <span>Approval Groups</span>
      </Link>
      <Link title="Trusted Community"
        className={`nav-link${active_path === PrivateRoutes.trusted_community_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.trusted_community_route}`}>
        <Icon className="ficon-users" />
        <span>Trusted Community</span>
      </Link>
      <Link title="Email Groups"
        className={`nav-link${active_path === PrivateRoutes.groups_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.groups_route}`}>
        <Icon className="ficon-user-plus" />
        <span>Email Groups</span>
      </Link>
      <hr />
      <Link title="Approval Requests"
        className={`nav-link${active_path === PrivateRoutes.requests_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.requests_route}`}>
        <Icon className="ficon-clipboard" />
        <span>Approval Requests</span>
      </Link>
      <Link title="Activity Logs"
        className={`nav-link${active_path === PrivateRoutes.activity_logs_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.activity_logs_route}`}>
        <Icon className="ficon-file-text" />
        <span>Activity Logs</span>
      </Link>
      <Link title="Data Export"
        className={`nav-link${active_path === PrivateRoutes.data_export_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.data_export_route}`}>
        <Icon className="ficon-download-cloud" />
        <span>Data Export</span>
      </Link>
      <Link title="Email Gateway"
        className={`nav-link${active_path === PrivateRoutes.email_gateway_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.email_gateway_route}`}>
        <Icon className="ficon-mail" />
        <span>Secure Email Gateway</span>
      </Link>
      <hr />
      <Link title="General Settings"
        className={`nav-link${active_path === PrivateRoutes.settings_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.settings_route}`}>
        <Icon className="ficon-settings" />
        <span>General Settings</span>
      </Link>
      <Link title="Device Settings"
        className={`nav-link${active_path === PrivateRoutes.device_settings ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.device_settings}`}>
        <Icon className="ficon-monitor" />
        <span>Device Settings</span>
      </Link>
      <Link title="Cloud Lock Settings"
        className={`nav-link${active_path === PrivateRoutes.cloud_lock_route ? " active" : ""}`}
        to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.cloud_lock_route}`}>
        <Icon className="ficon-lock" />
        <span>Cloud Lock Settings</span>
      </Link>
    </Nav> : <></>;
}

export default React.memo(AdminNavigationComponent);
