import React, { useEffect, useRef, useState, DragEvent, Dispatch } from "react";
import { useNavigate } from "react-router-dom";
import { IActionHandler, MessageDisplayType } from "@preveil-api";
import {
  Account, useAppDispatch, Message, useAppSelector, GlobalErrorMessages, MessageAnchors, Pagination, isNumber, MessageHandlerDisplayType,
  isRootPath, DriveRouteType, DriveErrorMessages, DirectoryEntity, EntryItem, RootInfo, DriveUrlParams, useFetchDirectory, useFetchLink,
  DefaultRoutes, SORT_DIRECTION, sortBy, DriveEntryType, useDriveProcessNotification, DriveManagerEventWatch, AppConfiguration, EntryUpdate,
  useCheckHandleExePresentMutation, useEditFileMutation, CheckHandleExePresentApiResponse, MessageToastTypes, SupportRoutes, useDeleteItems,
  DriveCloudEditFileSyncMessages, TrashConfirmationMessages, TrashErrorMessages, TrashMessageAnchors, TrashSuccessMessages, DriveTypeMapping,
  DefaultCollectionName, viewerUnsupported, buildNavigationLink, useFetchLinkItem, DriveSuccessMessages, useDuplicateFile, DriveMessages,
  useCollectionRenameNotification, useFSProcessNotification, AsyncJobTypeMap, AsyncJobStatusMap, RenameValidationErrorMessages,
  Collection
} from "src/common";
import { uiActions, driveActions } from "src/store";
import { RootState } from "src/store/configureStore";
import { Loading, PageHeader } from "src/components";
import { Toolbar, ListView, Breadcrumbs, FileUpload, EmptyMessage, DriveSearch } from ".";
import { FileDownloadAndView, Move, SelectiveSync } from "./entries";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  root_info: RootInfo;
  router_params: DriveUrlParams;
  drag_event?: { type: string, e: DragEvent };
  setLegacyFileId: Dispatch<React.SetStateAction<string | undefined>>;
}

const is_web = AppConfiguration.buildForWeb();
function DriveManagerComponent(props: AllProps) {
  const { current_account, root_info, router_params, drag_event, setLegacyFileId } = props;
  const { collection_id, id, file_id, item_type } = router_params;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // STATES
  const [page, setPage] = useState<string>("1");
  const [show_move_modal, setShowMoveModal] = useState<boolean>(false);
  const [file_entry, setFileEntry] = useState<EntryItem>();
  const [creating_new_folder, setCreatingNewFolder] = useState<boolean>(false);
  const [show_selective_sync_modal, setShowSelectiveSyncModal] = useState<boolean>(false);
  const [current_sync_directory, setCurrentSyncDirectory] = useState<DirectoryEntity>();
  const is_loading = useAppSelector((state: RootState) => state.drive.is_loading);
  const selected = useAppSelector((state: RootState) => state.drive.selected);
  const pagination = useAppSelector((state: RootState) => state.drive.pagination);
  const reload_drive_list = useAppSelector((state: RootState) => state.drive.reload_drive_list);
  const total_count = useAppSelector((state: RootState) => state.drive.pagination_info?.total_count);
  const current_directory = useAppSelector((state: RootState) => state.drive.current_directory);
  const collection_info = useAppSelector((state: RootState) => state.drive.current_collection);
  const breadcrumbs = useAppSelector((state: RootState) => state.drive.breadcrumbs);
  const entry_update = useAppSelector((state: RootState) => state.drive.entry_update);
  const upload_request = useAppSelector((state: RootState) => state.drive.upload_request);
  const rename_job = useAppSelector((state: RootState) => state.ui.rename_job);
  const global_local_sync = useAppSelector((state: RootState) => state.account.global_local_sync);
  const new_global_sync_change = useAppSelector((state: RootState) => state.app.new_global_sync_change);
  const file_upload_ref = useRef<{ click: () => void }>();
  // HOOKS
  const { fetchDirectory, resetDirectory, error: dir_error } = useFetchDirectory(current_account, true, !is_web);
  const { fetchLink, resetLink, error: link_error } = useFetchLink(current_account, true, !is_web);
  const { fetchLinkEntity, resetLinkEntity } = useFetchLinkItem(current_account, true);
  const { duplicated_file, error: duplicate_error, duplicateFile, resetDuplicate } = useDuplicateFile(current_account);
  const { deleteItems, delete_complete, errors, successes, resetDeleteItems } = useDeleteItems(current_account);
  const { new_notification } = useDriveProcessNotification(current_directory?.collection_id, DriveManagerEventWatch);
  // Handles notifications for FS collection rename jobs
  const { new_notification: rename_job_notification } = useFSProcessNotification(AsyncJobTypeMap.COLLECTION_RENAME, rename_job?.id);
  // Handles collection rename events from CS
  useCollectionRenameNotification(current_account, is_web);
  const [checkHandleExe] = useCheckHandleExePresentMutation();
  const [editFile] = useEditFileMutation();

  useEffect(() => {
    return () => {
      ResetPagination();
      dispatch(driveActions.resetSort());
      dispatch(driveActions.resetBreadcrumbs());
      dispatch(uiActions.handleMessageDismiss());
    };
  }, []);

  useEffect(() => {
    if (!!duplicated_file || !!duplicate_error) {
      !!duplicated_file && dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_duplicating_file.replace(MessageAnchors.new_name, duplicated_file))));
      !!duplicate_error && dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_duplicating_file)));
      resetDuplicate();
    }
  }, [duplicated_file, duplicate_error]);

  // Description: Handles normal navigation to other directories
  useEffect(() => {
    handleFetchItem(false);
    // NOTE: Add collection_id to detect direct links failures (Id will be undefined in both instances)
  }, [id, collection_id]);

  // Description: Trigger reload of the same current directory
  useEffect(() => {
    if (reload_drive_list) {
      handleFetchItem();
      dispatch(driveActions.setReloadDriveList(false));
    }
  }, [reload_drive_list]);

  // Description: On change of the  Global Cloud Sync Lock by Admin settings, adjust current NodePermissions
  useEffect(() => {
    // NOTE: Only trigger refresh when current_directory and collection are loaded
    (global_local_sync !== undefined && !!collection_info) && handleFetchItem();
  }, [global_local_sync]);

  useEffect(() => {
    // NOTE: display toaster only when a notification to turn on global sync is received
    if (!!new_global_sync_change  && !!global_local_sync && current_account?.org_info) {
      dispatch(
        uiActions.handleSetMessage(new Message(DriveMessages.cloud_lock_on, MessageHandlerDisplayType.toaster, MessageToastTypes.warning))
      );
    }
  }, [new_global_sync_change]);

  // Description: Handle Errors in fetching Link and directory
  useEffect(() => {
    if (link_error || dir_error) {
      link_error && resetLink();
      dir_error && resetDirectory();
      // NOTE: If this is a root path and dir_error then trigger Loading clean up
      !isRootPath(collection_id) && navigate(DefaultRoutes.drive_root);
      is_loading && dispatch(driveActions.setLoading(false));
    }
  }, [link_error, dir_error]);

  // Description: after setting threads clear setLoading
  useEffect(() => {
    if (!!current_directory) {
      DriveManagerRequests.handleCurrentDirectorySuccess(current_directory);
      !!file_id && handleNavigatingIntoFileUrl(file_id);

      // NOTE: disable is_loading if item_type is not a file
      (is_loading && item_type !== DriveRouteType.FILE) && dispatch(driveActions.setLoading(false));
    }
  }, [current_directory]);

  useEffect(() => {
    handleDeleteResponse();
  }, [delete_complete]);

  // Description: Update UI when Notifications come in
  useEffect(() => {
    (!!new_notification && !is_loading && !upload_request) && handleFetchItem();
  }, [new_notification]);

  useEffect(() => {
    !!entry_update && updateEntries(entry_update);
  }, [entry_update]);

  // Description: check to see if this is supposed to have a link_entity in current_directory
  //              Get link when the url type is directory but this is a maintainer node and requires link information
  useEffect(() => {
    !!collection_info && !!current_directory && Collection.isRootChildNode(collection_info, current_directory.id, root_info.id)
      && fetchLinkEntity(collection_info.collection_id);
  }, [collection_info]);

  useEffect(() => {
    if (!!rename_job_notification) {
      if (rename_job_notification.status === AsyncJobStatusMap.COMPLETED) {
        dispatch(uiActions.handleSetMessage(
          new Message(
            DriveSuccessMessages.success_rename.replace(MessageAnchors.prev_name, rename_job?.old_name || "").replace(MessageAnchors.new_name, rename_job?.new_name || ""),
            MessageHandlerDisplayType.toastr,
            MessageToastTypes.success
          )
        ));
      } else if (rename_job_notification.status === AsyncJobStatusMap.FAILED || rename_job_notification.status === AsyncJobStatusMap.HARD_FAILED) {
        dispatch(uiActions.handleSetMessage(
          new Message(
            RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, rename_job?.old_name ?? ""),
            MessageHandlerDisplayType.toastr,
            MessageToastTypes.error
          )
        ));
      }
    }
  }, [rename_job_notification]);

  // -------------------------------------------------------------------------------
  // Description: Handle all children component actions and store it
  // -------------------------------------------------------------------------------
  const DriveManagerRequests = {
    // Description: Refresh the data in the current mailbox
    handleRefresh: handleFetchItem,

    // Description: Handles select all from List View header
    handleSelect: (params: { entry?: EntryItem, selected: boolean }) => {
      let new_selected = selected.slice();
      const _id = !!params.entry ? params.entry.id : null;
      if (!!_id) { // Note: individual check
        if (params.selected) {
          !_.find(new_selected, (_selected: string) => (_selected === _id)) &&
            new_selected.push(_id);
        } else {
          const index = _.findIndex(new_selected, (_selected: string) => (_selected === _id));
          index >= 0 && (new_selected.splice(index, 1));
        }
      } else {
        // NOTE: Filter out locked files as those entries should not be selected, deleted, etc when they are locked.
        new_selected = [];
        (!!current_directory && params.selected) &&
          _.forEach(current_directory.entries, (entry: EntryItem) => {
            if (!entry.is_locked) { new_selected.push(entry.id); }
          });
      }
      dispatch(driveActions.setSelected(new_selected));
    },

    handleSelectFiles: () => {
      !!file_upload_ref.current && file_upload_ref.current.click();
    },

    // Description: Upload files to a row or a directory
    handleUpload: (params: { destination?: EntryItem, destination_id?: string, files: FileList }) => {
      const { destination, files } = params;
      const linked_collection_id = destination?.type === DriveEntryType.LINK ? destination?.linked_collection_id : undefined;
      const link_id = linked_collection_id ||
        (!!collection_info && collection_info.collection_name !== DefaultCollectionName ? collection_info.collection_id : undefined);
      const destination_id = destination?.id || params.destination_id;
      // NOTE: using link_collection_id to identify LINK rows and node_id for DIR rows. 
      const tracker_id = !!destination ? (destination?.type !== DriveEntryType.LINK ? destination_id : link_id) :
        (!!collection_info && (collection_info.maintainer_id === collection_info.id && !!collection_info.is_root)) ? link_id : destination_id;
      !!destination && dispatch(driveActions.setSelected(selected.filter(id => id !== destination.id)));
      if (!destination_id) return;
      const _collection_id = destination?.collection_id || current_directory?.collection_id;
      const node_id = destination?.id || current_directory?.id;
      if (!_collection_id || !node_id) return;
      const mapped_files: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        !!file && mapped_files.push(file);
      }
      const upload = {
        collection_id: _collection_id,
        node_id,
        files: mapped_files,
        linked_collection_id,
        destination: {
          directory: !destination ? current_directory : undefined,
          id: destination_id,
          tracker_id
        }
      };
      dispatch(driveActions.setFileUploadRequest(upload));
    },

    // Description: Delete 
    handleDelete: (entry?: EntryItem) => {
      if (!!entry || !!current_directory) {
        const items = !!entry ? [entry] : !!current_directory ? current_directory.entries.filter(entry => selected.includes(entry.id)) : [];
        const delete_items = items.map(entry => ({ id: entry.id, collection_id: entry.collection_id, type: entry.type }));
        let message: string = "";
        let title: string = "";
        if (items.length === 1 && items[0].type === DriveEntryType.LINK) {
          // Deleting one shared folder
          title = TrashConfirmationMessages.delete_link_title.replace(TrashMessageAnchors.name, items[0].name);
          message = TrashConfirmationMessages.delete_link;
        } else if (items.length > 1 && getSelectedItemTypes(items).length === 1 && getSelectedItemTypes(items)[0] === DriveEntryType.LINK) {
          // Deleting several shared folders
          title = TrashConfirmationMessages.delete_links_title;
          message = TrashConfirmationMessages.delete_links.replace(TrashMessageAnchors.item_count, `${selected.length}`);
        } else if (items.length > 1 && getSelectedItemTypes(items).length > 1) {
          // Deleting a mix of items
          title = TrashConfirmationMessages.delete_mixed_items_title.replace(TrashMessageAnchors.item_count, `${selected.length}`);
          message = TrashConfirmationMessages.delete_mixed_items.replace(TrashMessageAnchors.item_count, `${selected.length}`);
        } else {
          // Default message
          const delete_display = items.length === 1 ? `"${items[0].name}"` : `${selected.length} items`;
          message = TrashConfirmationMessages.confirm_delete_message.replace(TrashMessageAnchors.name, delete_display);
          title = `Delete ${items.length === 1 ? `${DriveTypeMapping[items[0].type]}: "${items[0].name}"` : `${selected.length} items`}?`;
        }
        const confirmation_dialog = new Message(message, MessageHandlerDisplayType.confirm, MessageToastTypes.primary, title,
          {
            label: "Yes",
            data: true,
            action: () => { dispatch(driveActions.setLoading(true)); deleteItems(delete_items); },
          },
          { label: "No" },
        );
        dispatch(uiActions.handleSetMessage(confirmation_dialog));
      }
    },

    // Description: View File
    handleDownloadAndView: (entry: EntryItem) => {
      setFileEntry(entry);
    },
    // Description: Download File
    handleDownload: (entry?: EntryItem) => {
      const _entries = !!entry ? [entry] : !!current_directory ? current_directory.entries.filter(entry => selected.includes(entry.id)) : [];
      const file_entries = _entries.filter(entry => entry.type === DriveEntryType.FILE);
      const download_requests = file_entries.map(entry => ({
        collection_id: entry.collection_id,
        file_id: entry.id,
        file_name: entry.name
      }));
      if (file_entries.length !== _entries.length) {
        dispatch(uiActions.handleSetMessage(new Message(DriveMessages.only_files_can_be_downloaded, MessageHandlerDisplayType.toastr,
          MessageToastTypes.warning)));
      }
      download_requests.length > 0 && dispatch(driveActions.setFileDownloadRequest(download_requests));
      dispatch(driveActions.setSelected([]));
    },

    // Description: Create new Folder
    handleCreateFolder: (entry: EntryItem) => {
      setCreatingNewFolder(true);
    },

    // Description: Send Toolbar actions to SidebarPanel in Listview (share for now)
    handleDetails: (current_directory_id: string) => {
      dispatch(driveActions.setActiveShareId(current_directory_id));
    },

    // Description: Edit File
    handleEditFile: (entry: EntryItem) => {
      checkHandleExePresent(entry);
    },

    // Description: Duplicate File
    handleDuplicateFile: (entry: EntryItem) => {
      const confirmation_dialog = new Message(`Are you sure you want to duplicate ${entry.name}?`, MessageHandlerDisplayType.confirm, MessageToastTypes.primary, `Duplicate File ${entry.name}?`,
        {
          label: "Yes",
          data: true,
          action: () => duplicateFile(entry.collection_id, entry.id, entry.name),
        },
        { label: "No" },
      );
      dispatch(uiActions.handleSetMessage(confirmation_dialog));
    },

    handleMoveModal: (params: { show: boolean, entry?: EntryItem }) => {
      const { show, entry } = params;
      !!entry && dispatch(driveActions.setSelected([entry.id]));
      setShowMoveModal(show);
    },

    //  Description: Navigate to page passed - different behavior for search
    handlePaging: (new_page: string) => {
      (new_page !== page && isNumber(new_page)) ? setPage(new_page) :
        DriveManagerRequests.handlePageErrorMessage({
          message: GlobalErrorMessages.default, stack: {
            error: `There was a Problem paginating to Page: ${new_page}`
          }
        });
    },

    // Description: On successful get Directory and set pagination object
    handleCurrentDirectorySuccess: (_current_directory: DirectoryEntity) => {
      const totalPageRows = _current_directory.entries.length;
      const totalRows = total_count || _current_directory.entries.length;
      if (totalPageRows > 0 && totalRows > 0) {
        const paginationItem = new Pagination(Pagination.getPageIndex(page), totalPageRows, totalRows, pagination.pageSize).pagination_item;
        !!paginationItem && dispatch(driveActions.setPagination(paginationItem));
      } else {
        dispatch(driveActions.resetPagination());
      }
    },

    // Description: Handle all page / components top level errors
    handlePageErrorMessage: (params: { message: string, stack?: any, display_type?: MessageDisplayType }) => {
      const display_type = !!params.display_type ? params.display_type : MessageHandlerDisplayType.logger;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack));
    },

    // Description: Handles opening and closing the selective sync modal
    handleSelectiveSyncModal: (params: { show: boolean, current_directory: DirectoryEntity }) => {
      setCurrentSyncDirectory(params.current_directory);
      setShowSelectiveSyncModal(params.show);
    },

    // Description: Handle showing and hiding the sync status notification tray
    handleSelectiveSyncNotification(params: { request_id: string }) {
      dispatch(uiActions.setSyncJobID(params.request_id));
      dispatch(uiActions.setShowSyncStatus(true));
      dispatch(driveActions.setSelected([]));
    }
  };

  // Description: Given a list of EntryItem, Get a set of selected item types (specified by DriveEntryType)
  function getSelectedItemTypes(entries: EntryItem[]) {
    const types = _.map(entries, (entry: EntryItem) => entry.type);
    return _.uniq(types);
  }

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((DriveManagerRequests as any)[callback] instanceof Function) {
      (DriveManagerRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      DriveManagerRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: Fetch the correct list view depending on type and collection_id / slug
  function handleFetchItem(reset_pagination: boolean = true) {
    dispatch(driveActions.setLoading(true));
    !reset_pagination && ResetPagination();
    resetLinkEntity();
    dispatch(driveActions.setSelected([]));
    if (isRootPath(collection_id) && !!root_info) {
      // NOTE: Fetch root with root_info
      fetchDirectory(root_info.collection_id, root_info.id);
    } else if (!!collection_id && item_type === DriveRouteType.DIRECT_LINK) {
      // NOTE: Handle Direct links from legacy V1
      fetchLink(collection_id, "", true);
    } else if (!!collection_id && !!id && !!item_type) {
      item_type === DriveRouteType.DIR || item_type === DriveRouteType.FILE ? fetchDirectory(collection_id, id) :
        item_type === DriveRouteType.LINK ? fetchLink(collection_id, id) :
          DriveManagerRequests.handlePageErrorMessage({
            message: DriveErrorMessages.default,
            stack: { error_message: DriveErrorMessages.incorrect_entry_type },
            display_type: MessageHandlerDisplayType.toastr
          });
    } else {
      DriveManagerRequests.handlePageErrorMessage({ message: DriveErrorMessages.error_finding_item, stack: { error_message: DriveErrorMessages.incorrect_entry_type }, display_type: MessageHandlerDisplayType.toastr });
      dispatch(driveActions.setLoading(false));
    }
  }

  // Description: Handle the complete response after delete
  function handleDeleteResponse() {
    if (delete_complete && !!current_directory) {
      if (errors.length === 0) {
        if (successes.length === 1) {
          const item = current_directory.entries.find(i => i.id === successes[0]);
          if (!!item) {
            const message = item.type === DriveEntryType.LINK ? TrashSuccessMessages.success_hiding_one : TrashSuccessMessages.success_deleting_one;
            dispatch(uiActions.handleSetMessage(new Message(message.replace(TrashMessageAnchors.name, item.name))));
          }
        } else {
          dispatch(uiActions.handleSetMessage(new Message(TrashSuccessMessages.success_deleting_many.replace(MessageAnchors.item_count, successes.length.toString()))));
        }
      } else if (successes.length === 0) {
        if (errors.length === 1) {
          const item = current_directory.entries.find(i => i.id === errors[0]);
          !!item && dispatch(uiActions.handleRequestErrors(new Message(TrashErrorMessages.error_deleting_one_item.replace(TrashMessageAnchors.name, item.name))));
        } else {
          dispatch(uiActions.handleRequestErrors(new Message(TrashErrorMessages.error_deleting_all_items.replace(TrashMessageAnchors.item_count, errors.length.toString()))));
        }
      } else {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(
              TrashErrorMessages.error_deleting_some_items
                .replace(TrashMessageAnchors.item_count, errors.length.toString())
                .replace(TrashMessageAnchors.total_count, selected.length.toString()),
            ),
          ),
        );
      }
      handleFetchItem();
      dispatch(driveActions.setSelected([]));
      resetDeleteItems();
    }
  }

  // Description: Checks to see if the user has the handle.exe utility downloaded on their computer (this file is needed to edit on my preveil)
  // if it is not present, a modal pops up instructing the user to install handle.exe. If it is present, we start the edit file session
  async function checkHandleExePresent(entry: EntryItem) {
    await checkHandleExe({ user_id: current_account.user_id })
      .unwrap()
      .then((response: CheckHandleExePresentApiResponse) => {
        response === "OK" ? startEditFileSession(entry) : InstallMissingSoftwareModal();
      })
      .catch(() => {
        // NOTE: Dialog to alert the user instructions on how to install the Cloud editor (no error logging needed)
        InstallMissingSoftwareModal();
      });
  }

  // Description: This is the modal instructing the user to install the handle.exe utility. It will take them to a support page.
  function InstallMissingSoftwareModal() {
    const confirmation_dialog = new Message(DriveCloudEditFileSyncMessages.install_handle_exe, MessageHandlerDisplayType.confirm, MessageToastTypes.primary, "Install Missing Software",
      {
        label: "See Instructions",
        data: true,
        action: () => { window.open(SupportRoutes.handle_exe, "_blank"); },
      },
      { label: "Cancel" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  // Description: this starts the edit file session and catches any errors. some errors are: "no available apps to open the file", "received unsupported extentsion"
  async function startEditFileSession(entry: EntryItem) {
    // NOTE MAYBE TO DO: Implement useFSProcessNotification for on closing the app 
    // Would need to pass a request_id in this call (see SyncDetailsComponent for similar utility)
    await editFile({
      user_id: current_account.user_id,
      collection_id: entry.collection_id,
      file_id: entry.id,
      file_name: entry.name
    }).unwrap()
      .then(() => {
        // Add message for waiting to open folder
        dispatch(uiActions.handleSetMessage(new Message(DriveCloudEditFileSyncMessages.opening_app_message, MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
      })
      .catch((error) => {
        const error_message = error.data === DriveCloudEditFileSyncMessages.filesync_no_app_error ?
          DriveCloudEditFileSyncMessages.filesync_no_default_app_error :  // "No default application to open this file. Please specify an application to use." :
          error.data.includes("unsupported") ?
            DriveCloudEditFileSyncMessages.filesync_unsupported_error : error.data; // "This file has an unsupported extension and can therefore not be edited. We apologize for the inconvenience." : error.data;
        dispatch(uiActions.handleRequestErrors(new Message(error_message), error));
      });
  }

  // Description: Reset state supporting client side pagination
  function ResetPagination() {
    setPage("1");
    dispatch(driveActions.resetPagination());
  }

  // Description: Keep track of new files uploaded to current directory
  function updateEntries(entry_update: EntryUpdate) {
    if (entry_update.new_entries > 0 && !!current_directory && entry_update.destination_id === current_directory.id) {
      const entries = sortBy(current_directory.entries.concat(entry_update.latest), { field: "name", direction: SORT_DIRECTION.ascending });
      dispatch(driveActions.setCurrentDirectorySuccess(Object.assign({}, current_directory, { entries })));
    }
  }

  // Description: Handle navigation into a file url
  function handleNavigatingIntoFileUrl(file_id: string) {
    if (!!current_directory) {
      const entry = current_directory.entries.find((entry: EntryItem) => entry.id === file_id);
      if (!!entry && entry.type === DriveEntryType.FILE && !viewerUnsupported(entry)) {
        setFileEntry(entry);
      } else {
        dispatch(uiActions.handleSetMessage(new Message(DriveErrorMessages.file_not_supported, MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
        !!root_info && root_info.collection_id === collection_id && root_info.id === id ?
          navigate(DefaultRoutes.drive_root) :
          navigate(buildNavigationLink(collection_id, id, DriveEntryType.DIR));
        dispatch(driveActions.setLoading(false));
      }
    }
  }

  // Description: Reset File Entry on close and on error
  function resetFileEntry() {
    setFileEntry(undefined);
    setLegacyFileId(undefined);
  }

  // Description: Render the correct view
  function RenderView() {
    const props = { current_account, selected, is_loading, page, pagination };
    return !!current_directory ?
      <ListView {...props}
        current_directory={current_directory}
        handleAction={handlePageActions}
        creating_new_folder={creating_new_folder}
        setCreatingNewFolder={setCreatingNewFolder}
        drag_event={drag_event}
        is_web={is_web} /> : is_loading ?
        <Loading className="in-place mt-5" /> :
        <EmptyMessage />;
  }

  return <>
    <PageHeader >
      <div className="header-flex">
        {
          <Breadcrumbs
            current_account={current_account}
            id={id}
            paths={breadcrumbs}
            collection_info={collection_info}
            handleAction={handlePageActions} />
        }{
          (!!collection_info && !!current_directory) &&
          <DriveSearch
            current_account={current_account}
            collection_info={collection_info}
            current_directory={current_directory}
            selected={selected} />
        }
      </div>
      <Toolbar
        current_account={current_account}
        collection_info={collection_info}
        is_loading={is_loading}
        is_root={isRootPath(collection_id)}
        selected={selected}
        pagination={pagination}
        current_directory={current_directory}
        handleAction={handlePageActions} />
    </PageHeader>
    <FileUpload ref={file_upload_ref} current_directory={current_directory} handleAction={handlePageActions} />
    <div id="outer-scope" className="cover-content">
      {RenderView()}
    </div>
    {!!show_move_modal && !!current_directory && !!collection_info && (
      <Move
        count={selected.length}
        current_directory={current_directory}
        handleAction={handlePageActions}
        current_account={current_account}
        collection_info={collection_info}
      />
    )}

    {!!show_selective_sync_modal && !!current_sync_directory && (
      <SelectiveSync
        current_directory={current_sync_directory}
        handleAction={handlePageActions}
        current_account={current_account}
      />
    )}

    {(!!file_entry) && (
      <FileDownloadAndView
        file_entry={file_entry}
        current_account={current_account}
        router_params={router_params}
        permissions={collection_info?.permissions}
        resetFileEntry={resetFileEntry} />
    )}
  </>;
}

export default React.memo(DriveManagerComponent);
