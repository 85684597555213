import React, { useEffect, useState } from "react";
import { Image, Button, Card } from "react-bootstrap";
import { PublicRoutes, QueryParamKeys, useAppSelector, useLogout } from "src/common";
import errorImg from "src/assets/images/embarassed-face.svg";

function CertificateNotFoundComponent() {
  const [user_id, setUserId] = useState<string>();
  const current_user_id = useAppSelector((state) => state.account.current_account?.user_id);
  const { handleStateLogout } = useLogout();

  useEffect(() => {
    !!current_user_id && setUserId(current_user_id);
    handleStateLogout();
  }, []);

  return <Card className="mx-auto max-600 mt-5">
    <Card.Body className="not-found">
      <Image alt="PreVeil Logo" className="mb-4" src={errorImg} />
      <h3>Device Certificate Not Found!</h3>
      <p>For enhanced security, your organization requires a proper certificate to be installed on your device before adding your PreVeil account.</p>
      <p>Please contact your system administrator and ask them to install the required certificate on the device you are trying to connect to.</p>
      <Button href={`/${PublicRoutes.login_route}${!!user_id ? `?${QueryParamKeys.USER_ID_QUERY_KEY}=${user_id}` : ""}`}>Go Back</Button>
    </Card.Body>
  </Card>;
}

export default React.memo(CertificateNotFoundComponent);
