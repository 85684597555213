import React, { MouseEvent } from "react";
import { Row, Col, Dropdown, Button, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ActionHandlerFunction } from "@preveil-api";
import {
  AppConfiguration,
  DriveInvitationInfo,
  DriveUIActionTypes,
  ProcessingType,
  NodePermissionLabels,
  buildNavigationLink,
  DriveEntryType,
  useAppSelector
} from "src/common";
import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  invitation: DriveInvitationInfo;
  handleAction: ActionHandlerFunction;
  responding: boolean;
};

const is_web = AppConfiguration.buildForWeb();
function InvitationsRowComponent(props: AllProps) {
  const { invitation, handleAction, responding } = props;
  const processing = invitation.processing === ProcessingType.accepting || invitation.processing === ProcessingType.rejecting;
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const allow_sync = invitation.sync_by_default && !!invitation.global_local_sync;
  const web_format = is_web || !invitation.global_local_sync;
  const navigate = useNavigate();

  function respondButton(accept: boolean) {
    const button_text = accept ?
      (web_format ? "Accept" : (allow_sync ? "Accept With Syncing" : "Accept Without Syncing")) :
      "Reject";
    return <Button
      variant={accept ? "outline-secondary" : "no-outline-secondary"}
      size="sm"
      onClick={() => {
        handleAction({
          actionType: DriveUIActionTypes.RespondToShareInvitation,
          params: {
            invitation, accept,
            sync: invitation.permissions_label === NodePermissionLabels.view_only ? false : allow_sync
          },
        });
      }}>
      {button_text}
    </Button>;
  }

  // Description: When an invitation row is clicked (if it is accepted and has a link_id), navigate to the link in my preveil.
  function handleNameClick() {
    if (invitation.processing === ProcessingType.accepted && !!root_info && !!invitation.link_id) {
      const url = buildNavigationLink(root_info?.collection_id, invitation.link_id, DriveEntryType.LINK);
      navigate(url);
    }
  }

  return (
    <Row className={`${invitation.processing !== ProcessingType.accepted ? "not-accepted" : ""}`} onClick={handleNameClick}>
      <Col md="3" className="entry-name">
        <span>
          <Icon className="fsi-shared" />{invitation.name}
        </span>
      </Col>
      <Col md="3" className="overflow-text">{invitation.sharer_user_id}</Col>
      <Col md="2" className="overflow-text">{invitation.permissions_label}</Col>
      <Col className="text-start overflow-text d-md-none d-lg-block">{invitation.expiration.split(" ")[0]}</Col>
      <Col md="auto" className="btn-panel">
        {
          invitation.processing === ProcessingType.accepted ?
            <span className="light-blue-text me-2">Accepted</span> :
            <>
              {
                processing && responding ?
                  <ProgressBar
                    variant="secondary"
                    animated
                    now={50}
                    label={`${50}% completed`}
                    visuallyHidden /> :
                  <>
                    {respondButton(true)}
                    {
                      web_format && respondButton(false)}
                    {
                      !web_format && (
                        <Dropdown
                          as="button"
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          className="btn btn-icon btn-sm"
                          title="Accept"
                          disabled={false}>
                          <Dropdown.Toggle as="span">
                            <Icon className="ficon-more-vertical" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            {invitation.permissions_label !== NodePermissionLabels.view_only && <Dropdown.Item
                              title="Accept"
                              data-tooltip-id="pv-tooltip"
                              data-tooltip-content="Accept"
                              data-tooltip-place="right"
                              onClick={() =>
                                handleAction({
                                  actionType: DriveUIActionTypes.RespondToShareInvitation,
                                  params: { invitation, accept: true, sync: !allow_sync },
                                })
                              }>
                              <Icon className="ficon-check" />
                              {allow_sync ? "Accept Without Syncing" : "Accept With Syncing"}
                            </Dropdown.Item>}
                            <Dropdown.Item
                              title="Reject"
                              data-tooltip-id="pv-tooltip"
                              data-tooltip-content="Reject"
                              data-tooltip-place="right"
                              onClick={() =>
                                handleAction({
                                  actionType: DriveUIActionTypes.RespondToShareInvitation,
                                  params: { invitation, accept: false, sync: allow_sync },
                                })
                              }>
                              <Icon className="ficon-x" /> Reject
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                  </>
              }
            </>
        }
      </Col>
    </Row>
  );
}

export default React.memo(InvitationsRowComponent);
