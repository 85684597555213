import React from "react";
import { Icon } from "src/components";

function CloudLockSettingsDeactivatedView() {
  return (
    <div className="cloud-lock-off">
      <Icon className="cloud-lock-image" />
      <p>Set permissions for users to sync or un-sync drive content to their devices.</p>
    </div>
  );
}

export default React.memo(CloudLockSettingsDeactivatedView);