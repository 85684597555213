import React from "react";
import { ActionHandlerFunction, CollectionServerUser } from "@preveil-api";
import { CheckboxStatesTypes } from "src/common";
import { Loading } from "src/components";
import { UsersExceptionListPanel, CloudLockSettingsToolbar } from ".";

type AllProps = {
  org_users: CollectionServerUser[];
  is_loading: boolean;
  cloud_lock_status: boolean;
  exception_users_list: string[];
  filtered_users_exception_list: string[];
  selected_users_exception_list: string[];
  handlePageActions: ActionHandlerFunction;
  filter_param: string;
  checkbox_state: CheckboxStatesTypes;
}

function CloudLockSettingsActivatedView(props: AllProps) {
  const { 
    org_users, 
    is_loading, 
    cloud_lock_status, 
    handlePageActions, 
    filter_param, 
    checkbox_state, 
    exception_users_list, 
    filtered_users_exception_list, 
    selected_users_exception_list 
  } = props;

  const total_items_filtered = filtered_users_exception_list.length;
  const disable_delete_button = selected_users_exception_list.length === 0;
  const total_users_label = exception_users_list.length > 1 ? "Users" : "User";
  return (
    <>
      <div className="cloud-lock-legend">
        <h4>Users sync permissions</h4>
        <p>Targeted users can be granted sync permissions below:</p>
      </div>

      <CloudLockSettingsToolbar
        is_loading={is_loading}
        is_add_button_disabled={is_loading}
        is_delete_disabled={disable_delete_button}
        is_refresh_disabled={!cloud_lock_status || is_loading}
        total_items_filtered={total_items_filtered}
        total_items={exception_users_list.length}
        handleActions={handlePageActions}
        filter_param={filter_param}
        label={total_users_label}
      />

      {(is_loading || org_users.length === 0) ? (
        <div className="cover-content list-group">
          <div className="admin-panel-center">
            <Loading className="in-place" />
          </div>
        </div>
      ) :
        <UsersExceptionListPanel
          all_selected_state={checkbox_state}
          filtered_users_exception_list={filtered_users_exception_list}
          total_users_exception_list={exception_users_list}
          org_users={org_users}
          selected_users_exception_list={selected_users_exception_list}
          handleActions={handlePageActions}
        />
      }
    </>
  );
}

export default React.memo(CloudLockSettingsActivatedView);