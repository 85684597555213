/*
 *   File:           messages.ts
 *   Description:    Store message for components
 *   Author:         PreVeil, LLC
 */

// Description: Use these anchors to replace data dynamically in text
export const MessageAnchors = {
  user_id: "##USER_ID##",
  user_name: "##USER_NAME##",
  actionType: "##ACTION_TYPE##",
  mailbox_name: "##MAILBOX_NAME##",
  search_term: "##SEARCH_TERM##",
  file_name: "##FILE_NAME##",
  folder_name: "##FOLDER_NAME##",
  recipient_count: "##RECIPIENT_COUNT##",
  message_content: "##MESSAGE_CONTENT##",
  node_id: "##NODE_ID##",
  item_count: "##ITEM_COUNT##",
  move_name: "##MOVE_NAME##",
  total_count: "##TOTAL_COUNT##",
  prev_extension: "##PREV_EXTENSION##",
  new_extension: "##NEW_EXTENSION##",
  prev_name: "##PREV_NAME##",
  new_name: "##NEW_NAME##",
  mail_client: "##MAIL_CLIENT##"
};

// ------------------------------------------------------------------------------------------------------------------------
// Defaults
// ------------------------------------------------------------------------------------------------------------------------
export const GlobalSuccessMessages = {
  default: "Default success message",
  account_created: "Your account has been activated!",
};

export const GlobalErrorMessages = {
  default: "There was an error with your request",
  server_error: "Undefined bad gateway server error",
  crypto_disconnected: "Crypto Server is not online",
  no_handler_found: `No handler found for ${MessageAnchors.actionType}`,
  no_current_account_set: "The current account object is not set yet",
  error_finding_cs_user: "There was an error finding a user email for contacts",
  server_ws_authentication_failure: "Notify_ws received an invalid authentication result message",
  server_ws_challenge_failure: "Notify_ws received an invalid BigInt."
};

// Description: QS params passed to email /account in url redirects.
// NOTE 1: Mirror QueryParamValues in Constants file
// NOTE 2: Needs to be explicitely typed for retrieving data
export const QueryParamMessages: Record<string, string> = {
  new: "Your account has been activated!",
  recovered: "Account successfully recovered",
  added: "Device successfully added",
  existing: "Your account has already been activated"
};

export const WebsocketErrors = {
  default: "There was a problem with your request. Please try again!",
  missing_host_url: "The url to websocket host is undefined",
  websocket_disconnected: "Lost secure connection: Please try again",
  bad_pin: "Incorrect Pin. Please try again",
  forbidden: "You don't have the permission to add new devices. Please contact your admins",
  too_many_attempts: "Too many failed attempts: Please try again",
  disconnected: "Lost secure connection: Please try again",
  notify_websocket_disconnected: "Lost secure connection to Notify_ws: Please try again",
};

// ------------------------------------------------------------------------------------------------------------------------
// Account
// ------------------------------------------------------------------------------------------------------------------------
export const AccountErrorMessages = {
  error_login_in:
    "There was a problem login into your selected account. Please try again, or if the problem persists contact support.",
  bad_sms_code: "The verification code you entered is incorrect. Please try again!",
  bad_totp_code: "The verification code you entered is incorrect. Please try again!",
  bad_auth_header: "The verification code you entered is incorrect. Please try again!",
  bad_password: "The password you entered is incorrect. Please try again!",
  bad_code_reset: "The verification code was entered wrong five times. Please try again.",
  locked_out:
    "There has been too many attempts to login, and your account has been locked out. Please, try again at a later time",
  user_exists:
    "The user you are trying to create already exists. Please, try to log in or contact support.",
  phone_number: "There was a problem with the phone number you entered. Please try again!",
  login_submit_password: "Problem getting the correct Password. Please, try again!",
  bad_verification_code: "The verification code you entered is incorrect. Please try again!",
  bad_account_claim_receipt:
    "There was a problem setting the information you entered. Please, try again!",
  account_user_id_error: "The user email you entered is not valid. Please try again!", // Use this for improperly user account
  create_submit_verification_code:
    "There was an error creating the user account at Step 2. Please, try again!",
  create_submit_password:
    "There was an error creating the user account at Step 4. Please, try again.",
  create_express_account_validation: "Problem Validating the New Express User. Please, try again.",
  create_express_account_default: "Problem setting the New Express User. Please, try again.",
  create_account_step2_failed:
    "There was an error creating the user account at Step 2. Please, try again!",
  upgrade_account_failed:
    "Failed to update your account. Please try again, or if the problem persists contact support.",
  backup_restore_account_failed:
    "Failed to restrore your account from the backup code entered. Please try again, or if the problem persists contact support.",
  bad_user_email: "Problem setting the User Id on Create account. Please, try again!",
  create_organization_error:
    "There was a problem creating the new organization. Please, try again!",
  domain_lock_error: "Contact your organization's IT administrator to get an invitation to join PreVeil.",
  restore_account_error: "There was a problem restoring your account. Please, try again!",
  restore_account_code_error: "The recovery code you entered is not correct. Please, try again!",
  restore_account_password_error:
    "The recovery code or the password you entered is not correct. Please, try again!",
  approval_group_qs_stack_error: "Missing query string param(s): secret or user_id",
  missing_user_id: "The user id is missing",
  recover_key_failed: "There was an error recovering your key. Please try again!",
  claim_account_missing_params: "The link provided is not correct. Please try again!",
  account_activation_error: `The account for ${MessageAnchors.user_id} cannot be activated. Please try again or contact support.`,
  certificate_not_found: "User does not have the required certificates",
  kss_undefined: "KeyExchangeManager is not defined in handleConnectMessage",
  kss_transfer_undefined: "KeyExchangeManager is not defined in handleKeyTransferMessage",
  kss_handshake_undefined: "KeyExchangeManager is not defined in handleVerifyHashCompleteHandshake",
  kss_success_undefined: "KeyExchangeManager or message are not defined in handleSuccess",
  error_fetching_user_notifications: "`There was an error getting user invite notifications.",
  error_fetching_historic_keys: "`There was an error getting users Historic Keys.",
  error_fetching_org_info: "There was an error getting organization name.",
  password_required_error: "The password is required.",
  password_helper: "Password must be at least 8 characters long.",
  no_email_auth_header_and_prior_code_still_active: "The prior email code is still active. Please try again in 15 minutes.",
  error_resetting_password: "Error resetting password. Please try again later.",
  error_downloading_recovery_file: "Error downloading recovery file. Please try again later.",
  error_fetching_express_recovery_notification: "Error checking whether express recovery is set up.",
  previous_password_existed: "new_auth_token supplied must differ from prior auth_token",
  cannot_user_previous_password: "You entered one of your previous passwords. Please choose a new password that you haven't used before.",
  error_changing_password: "Error changing password. Please try again later.",
  default: "There was a problem with your request. Please try again or contact support.",
};

export const WelcomeModalHeader = {
  express: "Welcome To PreVeil Express!",
  page_one: "Add to Mail Client",
  page_two: "Protect Your Account",
};

// ------------------------------------------------------------------------------------------------------------------------
// Settings
// ------------------------------------------------------------------------------------------------------------------------
export const SettingsErrorMessages = {
  missing_user_id: "No current account user id. Please try again!",
  missing_requested_user_id: "The requested user id is missing. Please try again!",
  bad_pin: "Submitted wrong Pin. Please try again!",
  not_valid_user:
    "The PreVeil User ID entered is not associated with an account. Please try again!",
  default: "There was a problem with your request. Please try again!",
  no_signature_exists: "No Signature Exists",
  error_fetching_signature: "There was an error fetching your email signature. Please try again!",
  failed_to_install_profile: "Failed to Add Profile. Please try again!",
  failed_to_install_profile_mac: "Failed to Add Profile. This tool does not work for newer versions of mac. Please contact support to manually add PreVeil to Apple Mail!",
  error_saving_signature: "There was an error saving your email signature. Please try again!",
  invalid_email: "Not a valid email address",
  invalid_user: "Not a valid user",
  invalid_email_address: "Not a valid address, please update (double click) or remove",
  not_part_of_org: "This address is not part of the organization or does not have access to this feature.",
  cannot_add_yourself: "Cannot add yourself in the Recovery Group",
  existing_user: "This user is already a part of your Recovery Group",
  empty_recovery_group: "Can't save an empty recovery group",
  error_saving_recovery_group: "Error saving Recovery Group: Please try again or contact technical support",
  error_deleting_request: "Error deleting request",
  error_fetching_responses: "Error fetching request responses",
  error_creating_backup: "There was an error creating a new account backup. Please ty again or contact support!",
  error_getting_backup: "There was an error getting the existing account backup. Please ty again or contact support!",
  error_deleting_backup: "There was an error deleting the existing account backup. Please ty again or contact support!",
  error_fetching_activity: "Error fetching activity!",
  error_fetching_devices: "Error fetching devices!",
  error_generating_qr: "ERROR GENERATING A QR CODE IMAGE",
  error_changing_authentication: "There was an error changing your authentication method. Please try again or contact support!",
  error_changing_phone_number: "There was an error changing your phone number. Please try again or contact support!",
  bad_totp_code: "The verification code you entered is incorrect. Please wait until the code refreshes and try again!",
  same_phone_number: "You have entered your current phone number. Please enter a new phone number to continue."
};

export const SettingsSuccessMessages = {
  signature_added: "Your Signature was succesfully added!",
  recovery_group_saved: "Recovery group saved: No need to worry about losing your devices",
  request_deleted: "Successfully deleted request",
  account_backup_created: "Your Account Backup was successfully created!",
  account_backup_deleted: "Your Account Backup was successfully deleted!",
  installed_mail_client: `Profile successfully added to ${MessageAnchors.mail_client}`,
  succesfully_downloaded_file: "You have successfully downloaded a new Account Recovery file to your browser downloads folder.",
  succesfully_changed_authentication_methods: "Your authentication method has successfully been changed!",
  succesfully_changed_phone_number: "Your mobile phone number has successfully been changed!",
};

export const SettingsMessages = {
  confirm_delete: "Are you sure you want to delete the selected request(s)?",
  confirm_disconnect: "remove your account from this device?",
  approval_not_found: "The invitation you requested has already been handled.",
  sms_popover_message: "<p>Text message authentication is <b>a simple type of 2FA</b>. When you sign in you will receive a text message with an authentication code. Fill in the code to login.</p>",
  authenticator_popover_message: "<p>An authenticator is also a <b>type of 2FA</b> that involves an app. The app generates a unique six-digit code from the quick response (QR) code displayed on your screen once you scan it. After entering the code you will be authorized.</p>"
};

export const EmptyViewMessages = {
  no_activity: "There is no activity in the current timeframe"
};
// ------------------------------------------------------------------------------------------------------------------------
// Mail  
// ------------------------------------------------------------------------------------------------------------------------
export const MailErrorMessages = {
  no_qs_action_message_found: `No message found for action: ${MessageAnchors.actionType}`,
  no_mailbox_found: "There was an error with the  mail folder you are trying to access. Please try again or contact support.",
  mailbox_already_exists: "A mail folder with the name entered already exists. Please try again.",
  error_deleting_mailbox: `There was an error deleting "${MessageAnchors.mailbox_name}". Please try again or contact support.`,
  error_creating_mailbox: `There was an error creating "${MessageAnchors.mailbox_name}". Please try again or contact support.`,
  error_renaming_mailbox: `There was an error renaming "${MessageAnchors.mailbox_name}". Please try again or contact support.`,
  no_search_results_found: "No matching results for your search",
  no_thread_found: "No matching mail found. <small class=\"d-block text-muted\">Please try again or contact support.</small>",
  error_searching: `There was an error searching for "${MessageAnchors.search_term}". Please try again or contact support.`,
  error_getting_mail_threads: "There was an error getting your mail. Please try again or contact support.",
  error_getting_message: "There was an error getting this message.",
  error_downloading_attachment: `There was an error downloading attachment: "${MessageAnchors.file_name}." Please try again or contact support.`,
  error_loading_attachment: "There was an error finding this mail attachment(s). Please try reloading the page.",
  error_removing_compose: "There was an error removing the compose message",
  error_creating_compose: "There was an error creating the compose message content.",
  error_sending_mail: "There was an error sending the message. Please try again or contact support.",
  error_opening_editor_file_upload: "There was an error getting file attachments. Please try again or contact support.",
  error_saving_draft: "There was an error saving a copy of the message. Please reload and try again, or contact support.",
  error_discard_draft: "There was an error discarding this draft. Please reload and try again, or contact support.",
  no_collection_id_found: "No collection identifier found.", // Only for logger use
  error_no_mail_recipients: "Please, add add at least one recipient to your mail.",
  error_building_recipients: "No contacts available to build the web mail recipients.",
  error_initializing_compose: "compose_message was not properly initialized.",
  error_setting_mail_flag: "There was an error changing the status of one or more of your messages. Please try again or contact support.",
  error_deleting_messages: "There was an error deleting your messages. Please try again or contact support.",
  error_moving_messages: "There was an error moving one or more of your messages. Please try again or contact support.",
  same_mailbox_target_warning: " Cannot move message to current folder.",
  error_finding_destination_mailbox: "There was an error finding the destination folder. Please try again or contact support.",
  error_external_link: "There was an error with the link. Please try again or contact support.",
  default: "There was a problem with your request. Please try again or contact support.",
};

export const MailSuccessMessages = {
  mailbox_deleted_success: `Mail Folder: "${MessageAnchors.mailbox_name}" was successfully deleted!`,
  mailbox_created_success: `Mail Folder: "${MessageAnchors.mailbox_name}" was successfully created!`,
  mailbox_renamed_success: `Mail Folder: "${MessageAnchors.mailbox_name}" was successfully renamed!`,
  messages_deleted_success: "Your messages were deleted successfully!",
  draft_discard_success: "Draft was successfully discarded!",
  draft_saved_success: "Draft was successfully saved!",
  messages_moved_success: "Your messages were moved successfully!"
};

export const MailMessages = {
  confirm_folder_delete: `Are you sure you want to delete folder "${MessageAnchors.mailbox_name}"?`,
  confirm_folder_title: "Delete Folder",
  unclaimed_recipient_notification: `${MessageAnchors.recipient_count} without a PreVeil account  will be invited to join.`,
  external_recipient_notification: `${MessageAnchors.recipient_count} have external email addresses.`,
  confirm_send_mail_without_data: `Are you sure you want to send this message without ${MessageAnchors.message_content}?`,
  message_no_subject: "(no subject)",
  message_no_body: "(no body)",
  confirm_save_draft_onclose: "Do you want to save this message to Drafts folder and close the compose window?"
};

export const ComposeMailSending = {
  sending: "Message sending",
  success: "Encrypted Message sent",
  failed: "Send failed",
  default: "Sending encrypted message..."
};

// ------------------------------------------------------------------------------------------------------------------------
// Admin  
// ------------------------------------------------------------------------------------------------------------------------
export const AdminMessages = {
  enable_community: {
    title: "Are you sure you want to enable your organization's trusted community?",
    body: "This will result in your user's ability to only share with others within your organization and addresses, including trusted list."
  },
  disable_community: {
    title: "Are you sure you want to disable your organization's trusted community?",
    body: "Your users will be able to send and receive mail and share documents to anyone on the web."
  },
  enable_gateway: {
    title: "Are you sure you want to enable the Email Gateway?",
    body: "The email gateway allows an organization to exchange email with external parties.<br><br>When it is enabled, the organization can communicate with external users within a controlled list of <b>addresses and domains</b>."
  },
  disable_gateway: {
    title: "Are you sure you want to disable the Email Gateway?",
    body: "The email gateway allows an organization to exchange email with external parties.<br><br>When it is disabled, the organization <b>cannot</b> communicate with external parties."
  },
  confirm_delete_address: {
    title: "Are you sure you want to delete the selected addresses?",
    body:
      "The external users will be not be able to send and receive mail to these deleted addresses when the trusted community is active."
  },
  confirm_delete_email_gateway: {
    title: "Are you sure you want to delete the selected addresses?",
    body:
      "The external users will not be able to send and receive mail to these deleted addresses when the email gateway is active."
  },
  confirm_delete_group: {
    title: "Are you sure you want to delete the selected group emails?",
    body: "The group emails will no longer be active."
  },
  confirm_delete_admin_users: {
    title: "Delete Confirmation",
    body: `<p>Are you sure you want to delete ${MessageAnchors.message_content}</p><p>It will be irreversibly removed.</p>`
  },
  confirm_get_started_data_export: {
    title: "Data Export Confirmation",
    body: `Are you sure you want to export your data?<br>You will need to get Approval from the <b>${MessageAnchors.message_content}</b> approval group before you're able to begin.`
  },
  confirm_cancel_export_request: {
    title: "Cancel Data Export Request",
    body: "Are you sure you want to cancel your data export request?"
  },
  confirm_stop_export_process: {
    title: "Stop Data Export Progress",
    body: "Are you sure you want to stop the current Data Export process?<br>You will lose all export progress."
  },
  cloud_lock_message: {
    enabled: "Cloud lock is turned <b>on</b> and content will <b>not be synced</b> to local devices. <br />Local files will be removed.",
    disabled: "Cloud lock is turned <b>off</b> and content <b>is synced</b> to local devices. <br />Users can set their own sync permissions.",
    tooltip_on: "The cloud lock feature restricts files to the cloud when turned on preventing users from syncing data for added security.",
    tooltip_off: "Users are allowed to sync files to their local devices."
  },
  sync_setting_change: {
    title: "Sync Settings Change",
    body: "Are you sure you want to <b>continue</b> to change your cloud only sync setting to on?<br />All the content on users' local devices will be removed."
  },
  cloud_lock_remove_user: {
    title: "Remove User from Sync Permission List",
    body: `Are you sure you want to remove the selected ${MessageAnchors.message_content} from the sync permission list?`
  },
  enabled_gateway_info: "When your email gateway is turned on you can communicate with external users by adding them to your controlled list of addresses and domains.",
  disabled_gateway_info: "When you email gateway is turned off your organization cannot communicate with external parties.",
  device_settings: {
    enabled: "Users are <b>allowed</b> to add PreVeil keys to multiple devices.",
    enabled_hint: "Turning this option off restricts users to having PreVeil on one device only.",
    disabled: "Users are <b>not allowed</b> to add PreVeil keys to multiple devices.",
    disabled_hint: "Turning this option on allows users to add their PreVeil key to multiple devices. ",
  },
};

export const AdminWarningMessages = { 
  admin_sync_cloud_lock: "Admin users need to be added to this list for syncing permissions.",
};

export const AdminErrorMessages = {
  error_fetching_org_info: "There was a problem getting the organization information. Please try again, or if the problem persists contact support.",
  error_invalid_address: "One or more of the email or domains you entered is not in valid format.",
  error_duplicate_trusted_address: "All addresses already in Trusted Community.",
  error_fetching_trusted_community: "There was an error fetching your Trusted Community. Please try again or contact support.",
  error_missing_user_id: "No current user id. Please try again or contact support.",
  error_missing_org: "No current organization id. Please try again or contact support.",
  error_adding_trusted_community: "Error adding to Trusted Community. Please try again or contact support.",
  error_removing_trusted_community: "Error removing from Trusted Community. Please try again or contact support.",
  error_toggle_trusted_community: `Error ${MessageAnchors.message_content} your Trusted Community. Please try again or contact support.`,
  error_unable_add_user: `User ${MessageAnchors.user_id} is not part of the organization and can't be added to this list.`,
  error_csv_wrong_file_type: "Wrong File Type, please use a csv file based on provided file template.",
  error_csv_upload_format: "Error parsing CSV file.",
  error_csv_wrong_heading: "Error, wrong file heading, please use the provided file template.",
  error_csv_empty_file: "Error, no Trusted Addresses or Domains found.",
  error_csv_empty_group_file: "Error, no addresses found in the file.",
  error_csv_empty_gateway_file: "Error, no addresses or domains found in the file.",
  error_fetching_requests: "Error fetching requests. Please try again or contact support.",
  error_fetching_approval_groups: "Error fetching approval groups. Please try again or contact support.",
  error_deleting_approval_group: "There was an error while deleting your approval group. Please try again or contact support.",
  error_creating_approval_group: "There was an error while creating your approval group. Please try again or contact support.",
  existing_approval_group: "There is an existing approval group with that name. Please try again.",
  error_assigning: "There was a problem assigning the approval group. Please try again.",
  error_admin_settings_switch_options: `Error ${MessageAnchors.message_content}. Please try again or contact support.`,
  error_admin_settings_data: "There was an error fetching your Admin settings. Please try again or contact support.",
  error_admin_uploading_device_certificate: "There was an error uploading your certificate. Please try again or contact support.",
  error_admin_deleting_device_certificate: "There was an error deleting your certificate. Please try again or contact support.",
  error_admin_parse_device_certificate: "There was an error parsing the details of your certificate. Please try again or contact support.",
  error_admin_settings_export_csv: "Error exporting Device List to CSV file. Please try again or contact support.",
  error_admin_users_export_csv: "Error exporting Users List to CSV file. Please try again or contact support.",
  error_fetching_gateway_users_data: "Error fetching Gateway User ID or Organization Info. Please try again or contact support.",
  error_toggle_email_gateway: `Error ${MessageAnchors.message_content} your Email Gateway. Please try again or contact support.`,
  error_removing_email_gateway: "Error removing from Email Gateway Alias. Please try again or contact support.",
  error_adding_email_gateway: "Error adding to email gateway aliases. Please try again or contact support.",
  error_fetching_email_gateway: "Error fetching Email Gateway. Please try again or contact support.",
  error_fetching_gateway_aliases: "Error fetching Gateway Addresses list. Please try again or contact support.",
  error_no_gateway_id: "Please, choose a Gateway User ID before adding external addresses",
  error_gateway_user_no_org: `${MessageAnchors.user_id} is not a member of the organization.`,
  error_gateway_assign_user: "Error assigning the Email Gateway User ID, please try again later or contact support.",
  error_gateway_connectivity_test: "Error testing your Email Gateway. Please try again later or contact support.",
  error_remove_admin_group_aliases: "Error trying removing selected groups. Please try again or contact support.",
  error_fetching_admin_groups: "Error fetching Admin Groups list. Please try again or contact support.",
  error_updating_admin_group: `Error trying to update ${MessageAnchors.user_id}. Please try again or contact support.`,
  error_request_delete_user: `Error requesting to delete ${MessageAnchors.message_content}`,
  error_request_delete_active_AG_member: "Cannot delete account tied to Approval Group Activity",
  error_invite_user_to_org: `Error inviting ${MessageAnchors.user_id} to Organization. Please try again or contact support.`,
  error_invite_request_csv_users: "Error inviting users from CSV file. Please try again or contact support.",
  error_resend_invite_users: `Error resending invites to ${MessageAnchors.message_content}`,
  error_set_recovery_group: `Error setting recovery group for ${MessageAnchors.message_content}`,
  error_update_user_account: `Error updating account: ${MessageAnchors.user_id}. Please try again or contact support.`,
  error_update_user_org_role: `Error updating user role for ${MessageAnchors.user_id}. Please try again or contact support.`,
  error_update_user_org_dept: `Error updating user department for ${MessageAnchors.user_id}. Please try again or contact support.`,
  error_finding_user_accounts_from_csv: "Error trying to get external users info.",
  error_start_data_export: "Error requesting data export. Please try again or contact support.",
  error_export_file_exists: "Error, output file path already exists. Please choose a different path.",
  error_export_permission_denied: "Error, you do not have write permission to that file path",
  error_export_path_not_found: "Error, entered file path does not exist",
  error_export_entered_path_no_found: `Error, entered file path: "${MessageAnchors.message_content}" does not exist`,
  error_cancel_export_request: "Error canceling the Data Export Request",
  error_data_export_illegal_file: "New folder has illegal characters, please update and try again.",
  error_no_subsume_or_admin_account: "Error, attempting to connect to websocket, no subsume or admin account found. Please try again or contact support.",
  error_delete_data_export_request_no_id: "Error, no Data Export Request ID found. Please try again or contact support.",
  error_delete_existing_data_export: "Error deleting Data Export Request. Please try again or contact support.",
  error_no_request_id_found: "Error, no Data Export Request ID found.",
  error_cancel_data_export: "Error canceling Data Export Request. Please try again or contact support.",
  error_creating_group_email: "Error creating group email. Please try again or contact support.",
  error_removing_user_from_cloud_lock_permission: `Error attempting to remove selected ${MessageAnchors.message_content} from the sync permission list.`,
  error_adding_user_to_cloud_lock_permission: `Error attempting to add selected ${MessageAnchors.message_content} to the sync permission list.`,
  error_invalid_certificate_extension: "Invalid certificate extension. Please select a .crt or a .pem file.",
  group_email_alias_error: `${MessageAnchors.user_id} already exists. Please use a different group email address.`,
  error_cloud_lock_setting_change: "Error changing your cloud lock settings.",
  error_fetching_sync_permission_list: "Error fetching sync permission list. Please try again or contact support.",
  error_fetching_cloud_lock_status: "Error fetching cloud lock status. Please try again or contact support.",
  default: "There was a problem with your request. Please try again or contact support.",
};

export const AdminSuccessMessages = {
  success_enable_trusted_community: "Successfully enabled your Trusted Community.",
  success_disable_trusted_community: "Successfully disabled your Trusted Community.",
  success_add_trusted_community_address: "Addresses were successfully added to Trusted Community.",
  success_delete_trusted_community_addresses: "Addresses were successfully deleted from Trusted Community.",
  success_deleting_approval_group: "Your Approval Group was successfully deleted.",
  success_creating_approval_group: "Your Approval Group was successfully created.",
  success_assigned_approval_group: "You have successfully assigned the approval group.",
  success_add_email_gateway_address: "Successfully added aliases to your Email Gateway",
  success_delete_email_gateway_addresses: "Addresses were successfully deleted from Email Gateway.",
  success_enable_email_gateway: "Successfully enabled your Email Gateway",
  success_disable_email_gateway: "Successfully disabled your Email Gateway",
  success_assign_email_gateway_user_id: "Successfully assigned your Email Gateway User ID",
  success_gateway_connectivity_test: "Successfully tested Email Gateway connectivity",
  success_create_admin_group: `Successfully created email group ${MessageAnchors.user_id}.`,
  success_delete_admin_group: `Successfully deleted ${MessageAnchors.message_content}`,
  success_update_admin_group: `Successfully updated email group ${MessageAnchors.user_id}.`,
  success_invite_user: `Successfully invited ${MessageAnchors.message_content} to Organization`,
  success_resend_invite_users: `Resent invites to ${MessageAnchors.message_content}`,
  success_request_delete_users: `Successfully deleted ${MessageAnchors.message_content}`,
  success_set_recovery_group: `Successfully set recovery group for ${MessageAnchors.message_content}`,
  success_update_user_account: `Successfully updated account: ${MessageAnchors.user_id}.`,
  success_cancel_export_request: "Successfully canceled the Data Export Request",
  success_completing_export: "Successfully completed the Data Export Request",
  success_stop_data_export_process: "Successfully stopped the current Data Export.",
  success_add_user_cloud_lock_permission: `Your selected ${MessageAnchors.message_content} successfully been added to the sync permission list.`,
  success_remove_user_cloud_lock_permission: `You have successfully removed your selected ${MessageAnchors.message_content} from the sync permission list.`,
  success_cloud_lock_setting_change: "Successfully changed your cloud lock settings.",
  success_uploaded_certificate: "Successfully uploaded the device certificate.",
  success_deleted_certificate: "Successfully deleted the device certificate."
};

export const ExpressSubsumeErrorMessages = {
  unsupported_request_type: "Unable to complete this request.",
  repeated_response: "You have already responded to this request.",
  error_accepting: "An error occured while approving this request. Please try again or contact support.",
  error_rejecting: "An error occured while rejecting this request. Please try again or contact support.",
  incomplete_subsume: "The account subsume operation has completed with an error. Please contact support.",
  error_fetching_org_info: "There was a problem getting the organization information. Please try again or contact support.",
  error_fetching_approvals: "There was a problem getting your list of approval requests. Please try again or contact support.",
  error_locating_approval: "Could not locate this approval request. Please try again or contact support.",
  error_fetching_requester_info: "There was a problem getting the user info for the approval requester. Please try again or contact support.",
  error_fetching_approval_group: "There was a problem getting the export approval group.",
  error_fetching_approvers: "There was a problem getting the export approval group approvers.",
  error_granting_key: "There was a problem granting the log key.",
  request_expired: "This request has expired.",
  default: "There was a problem with your request. Please try again or contact support."
};

export const ExpressSubsumeSuccessMessages = {
  success_rejecting: "Successfully rejected this request. You will not be subsumed into this organization.",
  success_approving: "Successfully approved this request. You have been subsumed into this organization."
};

export const EventHandlingErrorMessages = {
  fetching_events: "An error occured while fetching the list of events.",
  fetching_requester: "An error occured while fetching the requester of the event.",
  fetching_user: "An error occured while fetching the current user.",
  parsing_payload: "Unable to parse the event payload.",
  unverified: "Unable to verify the authenticity of this event.",
  wrong_user: "The current user and the intended user for this event do not match.",
  fetching_recovery_users: "An error occured while fetching the recovery group approvers.",
  key_shards: "Unable to generate key shards.",
  no_org: "Unable to determine the organization for the current user.",
  fetching_export_group: "An error occured while fetching the export approval group.",
  fetching_export_approvers: "An error occured while fetching the export group approvers.",
  fetching_org_info: "An error occured while fetching organization information.",
  rekey: "Unable to rekey the current user's user key.",
  handling: "An error occured while attempting to handle the event."
};

export const EventHandlingSuccessMessages = {
  approval_group: "Your recovery group has been successfully set.",
  submit_shards: "Your organization has just updated the data export approval group.",
  rekey: "A new recovery group has just successfully been set for you."
};

// ------------------------------------------------------------------------------------------------------------------------
// Drive  
// ------------------------------------------------------------------------------------------------------------------------
export const DriveErrorMessages = {
  no_app_mode_action: `[Internal Notice]: "${MessageAnchors.actionType}" action is not built for APP mode. `,
  no_web_mode_action: `[Internal Notice]: "${MessageAnchors.actionType}" action is not built for WEB mode. `,
  incorrect_entry_type: "The item type requested does not exist",
  no_default_collection_id: "There was a problem getting the default collection ID",
  error_opening_editor_file_upload: "There was an error opening the file browser. Please try again or contact support.",
  error_initializing_collection: "[Internal Notice]: There was a problem initializing the Collection",
  error_fetching_permissions: "[Internal Notice]: There was a problem fetching permissions",
  error_fetching_node_permissions: "[Internal Notice]: There was a problem fetching node permissions",
  error_fetching_acl_list: "[Internal Notice]:There was a problem fetching the acl list or account for fetching a directory",
  error_fetching_grant: "[Internal Notice]: There was a problem fetching the node grants while fetching a directory",
  error_fetching_acl_history: "[Internal Notice]: There was a problem fetching the acl key history",
  error_fetching_key_history: "[Internal Notice]: There was a problem fetching the permissions key history while fetching a directory",
  error_fetching_acl_tree: "[Internal Notice]: There was a problem fetching the acl tree of a collection",
  error_fetching_root_info: "[Internal Notice]: There was a problem fetching the permissions or account for root information",
  error_fetching_file: "[Internal Notice]: There was a problem fetching the file.",
  error_fetching_maintainer_directory: "[Internal Notice]: There was a problem fetching the maintainer directory.",
  error_sending_request: "[Internal Notice]: There was a problem sending a request",
  no_permissions_found: "[Internal Notice]: Permissions were not found for the given collection_id.",
  error_fetching_link: "[Internal Notice]:  There was a problem fetching the link information",
  error_fetching_inactive_link: "[Internal Notice]:  There was a problem fetching inactive links for Shared Page",
  error_fetching_active_link: "[Internal Notice]:  There was a problem fetching active links for Shared Page",
  error_fetching_directory: "[Internal Notice]: There was a problem fetching the data to build the current directory",
  error_fetching_snapshot: "[Internal Notice]: There was a problem fetching snapshot for selected directory",
  error_decrypting_entries: "[Internal Notice]: Failed to decrypt Directory Entries",
  error_fetching_fs_directory_paginated: "[Internal Notice]: Failed to fetch Filesync paginated directory",
  error_fetching_collection_information: "[Internal Notice]:  There was a problem fetching collection information",
  error_fetching_collection_protocol_version: "[Internal Notice]: There was a problem getting the collection attributes and protocol version",
  error_fetching_invitations_request: "[Internal Notice]: There was a problem with the request object for fetching invitations.",
  error_fetching_invitations: "[Internal Notice]: There was a problem fetching invitations. Please try again later.",
  error_accepting_invitations: "[Internal Notice]: There was a problem with the request object for accepting invitations.",
  error_downloading_block: "[Internal Notice]: There was a problem with the download block request.",
  invalid_block_type: "[Internal Notice]: Invalid Bock Type.",
  undefined_key: "[Internal Notice]: Block Key or User Key is undefined",
  block_incorrect_format: "[Internal Notice]: Pointer string not correctly formatted.",
  error_updating_node_permissions: "[Internal Notice]: There was a problem updating the node permissions",
  error_fetching_users: "[Internal Notice]: There was a problem fetching shared with users",
  error_building_node_gratees: "[Internal Notice]: There was a problem getting the local grantee the grant key",
  error_building_node_grant_permissions: "[Internal note]: buildPermission: Unable to add a new grant key for new grant permissions",
  error_sharing_v1_collection: "[Internal Notice]: Unable to build the share param object for v1 collections.",
  error_initializing_share: "[Internal Notice]: Error initializing share.",
  no_request_match_found: "[Internal Notice]: Could not match a websocket request to a response",
  error_sharing: "There was an error sharing this item. Please try again or contact support.",
  error_creating_folder: `Error creating folder "${MessageAnchors.file_name}"`,
  error_decrypting_acl_node_entry: `[Internal Notice]: Unable to decrypt name for entry ${MessageAnchors.node_id}`,
  error_downloading_file: `Error while downloading "${MessageAnchors.file_name}"`,
  error_downloading_progress: "Error downloading file.",
  error_downloading: "[Internal Notice]: Error downloading file through filesync.",
  invalid_document_type: `Invalid document type: "${MessageAnchors.file_name}"`,
  error_fetching_shared: "Error fetching shared folders.",
  error_adding_folder: `Error adding shared folder "${MessageAnchors.folder_name}", please try later`,
  conflict_while_adding_folder: `There was a name conflict while adding shared folder "${MessageAnchors.folder_name}"`,
  undefined_symm_key: "[Internal Notice]: Directory Symm key is undefined",
  error_responding_to_invitation: "[Internal Notice]: Error responding to invitation (got error status from HANDLE_SHARE call)",
  error_creating_link: "[Internal Notice]: Error creating link (got error status from CREATE call)",
  error_responding: "There was an error responding to the share invitation, please try again later.",
  error_handling_snapshot: "[Internal Notice]: There was a problem handling the fetch snapshot callback for sharing directories",
  error_making_shareable: "[Internal Notice]: There was a problem making a node shareable",
  error_bulk_updating_collection: "[Internal Notice]: There was a problem with bulk update call while populating a newly created collection or sharing a node",
  error_wrapping_block_key: "[Internal Notice - Snapshot Class]: There was a problem re-wrapping the snapshot Block key",
  error_getting_wrapped_key: "[Internal Notice - Snapshot Class]: Failed to get symm_key or new_wrapped_key",
  error_sharing_directories_with_links: "Unable to share this directory because it has shared sub-folders.",
  error_inviting_users: "There was an error inviting users. Please try again or contact support.",
  error_getting_file_blocks: "[Internal Notice]: Failed to get the File's blocks to update snapshots",
  error_granting_admin_logkey: "[Internal Notice]: Error granting the Log viewer key to admin group",
  error_granting_access_key: "[Internal Notice]: Error granting the Access key to a V2 collection on share",
  error_granting_v1_access: "[Internal Notice]: Error granting the Access to a V1 collection on share",
  conflict: `An item with name "${MessageAnchors.folder_name}" already exists in My PreVeil. Please rename the conflicting item and try again.`,
  accepted_but_error: `Invitation successfully accepted but there was an error adding "${MessageAnchors.folder_name}" to My PreVeil. You can try again in the shared tab.`,
  error_moving_item: "[Internal Notice]: There was an error while moving an item (got an error status from the UPDATE call)",
  error_fetching_item_during_move: "[Internal Notice]: There was an error while fetching one of them items to move.",
  conflict_while_moving: `An item with name "${MessageAnchors.move_name}" already exists at "${MessageAnchors.folder_name}"`,
  error_moving_some_items: `Error moving ${MessageAnchors.item_count} of ${MessageAnchors.total_count} items to "${MessageAnchors.folder_name}"`,
  error_moving_all_items: `Error moving ${MessageAnchors.item_count} items to "${MessageAnchors.folder_name}"`,
  error_moving_one_item: `Error moving "${MessageAnchors.move_name}" to "${MessageAnchors.folder_name}`,
  cannot_move_to_acl_node: "[Internal Notice]: Cannot move item to acl node",
  error_moving_general: "There was an error moving one item.",
  upload_insufficient_permissions: "Insufficient permissions.",
  error_no_target_directory: "Target directory could not be found.",
  invalid_key: "Invalid user key.",
  invalid_directory_key: "Invalid directory key.",
  error_tracking_upload: "Could not locate an active upload process for this file.",
  error_uploading_block: "There was an error uploading this file. Please try again or contact support.",
  error_creating_file: "Failed to create file.",
  error_size_exceeds_limit: "File exceeds 5GB limit.",
  no_upload_permission: "You need Edit access in order to upload to this directory.",
  unable_to_share_v1_subfolders: "This folders was created using an old version of PreVeil and does not support the sharing of sub-folder",
  file_not_supported: "PreVeil does not support this file type for viewing.",
  error_selective_sync: "Sync job failed. Please try again.",
  error_decrypting_name: "[Internal Notice] There was an error decrypting a collection name of an invite.",
  // Websocket errors
  websocket_catch_all_error: "[Internal Notice]: There was an error in a process that bubbled to the websocket saga catch block",
  error_socket_not_ready: "[Internal Notice]: sendRequest: drive_socket is not connected",
  error_notif_socket_not_ready: "[Internal Notice]: sendNotificationRequest: notification_socket is not connected",
  error_fs_notification_socket_not_ready: "[Internal Notice]: Filesync WS Notification: notification_socket is not connected",
  error_fs_notification_socket: "[Internal Notice]:  There was an error with the Filesync notifications. Please try again or contact support.",
  error_subscribing_notifications: "[Internal Notice]: Failed to SUBSCRIBE_ALL notifications.",
  error_ack_notifications: "[Internal Notice]: Failed to ACK notifications",
  error_fs_local_notification: "[Internal Notice]: Filesync Websocket returned an error.",
  not_response_type: `[Internal Notice]: FSMessage Response type is "${MessageAnchors.actionType}" and message did not return a Response type`,
  no_action_defined: "[Internal Notice]: There was no action callback defined for this call",
  error_drive_socket: "There was an error with the drive connection. Please try again or contact support.",
  error_notification_socket: "There was an error with the drive notifier. Please try again or contact support.",
  error_upload_socket: "There was an error with the driver uploader. Please try again or contact support.",
  fs_error_promoting_directory: "[Filesync Internal Notice]: Filesync Failed to promote a directory.",
  fs_error_upgrading_collection: "[Filesync Internal Notice]: Filesync Failed to upgrade a collection.",
  fs_error_sharing_v1: "[Filesync Internal Notice]: Filesync Failed to share a V1 collection.",
  fs_error_sharing_v2: "[Filesync Internal Notice]: Filesync Failed to share a V2 collection.",
  fs_error_promote_precheck: "[Filesync Internal Notice]: Filesync failed during promote precheck call.",
  error_sharing_v2_users: "[Internal Notice]: Error sharing V2 collections: no unclaimed or claimed users included. Likely, pending invites.",
  error_reverting_file: "There was an error reverting the selected version of this file. Please try again or contact support.",
  error_downloading_file_version: "There was an error downloading the selected file version. Please try again or contact support.",
  error_fetching_file_history: "There was an error fetching the version history of this file. Please try again or contact support.",
  error_opening_folder_path: "There was an error opening the drive locally. Please try again or contact support.",
  fs_error_promote_precheck_user: "Unable to share folder because another process is currently in progress. Please try again later.",
  node_updated_or_removed: "There was an unexpected change or a problem with this item. Please refresh to try again or contact support.",
  error_finding_item: "This item couldn't be found. If you received this item via a shared link, request a new direct link for access.",
  error_finding_direct_link: "This item was not found. Please, request a new direct link.",
  error_finding_share_target: "The item you are trying to share could not be found.  Please try again or contact support.",
  error_fetching_link_entity: "[Internal Notice]: There was a problem finding the link with a collection_id",
  error_duplicating_file: "There was an error duplicating your file. Please try again or contact support.",
  undefined_wrapped_key: "[Internal Notice]: Wrapped key is undefined",
  undefined_current_directory: "[Internal Notice]: Current directory is undefined",
  error_checking_snapshot_sequence: "[Internal Notice]: Error fetching Snapshot to check sequence",
  error_initializing_rename: "[Internal Notice]: Error initializing rename. Missing parent id, permissions or grants.",
  error_app_rename: "[Internal Notice]: Error renaming through FS. Missing parent id.",
  error_fetching_parent_directory: "[Internal Notice]: Error fetching parent directory.",
  default: "There was a problem with your request. Please try again or contact support."
};

export const RenameValidationErrorMessages = {
  invalid_name_beginning: `${MessageAnchors.message_content} names can't start with a "." character, please remove or use a different name`,
  invalid_name: `Sorry, "${MessageAnchors.file_name}" is a reserved system name, please use a different name.`,
  invalid_character: `${MessageAnchors.message_content} names can't include the following characters: \\ / : * ? < > | or end in a period.`,
  existing_name: `Item "${MessageAnchors.file_name}" already exists, please use different name`,
  change_extension: `Are you sure you want to change the extension from ".${MessageAnchors.prev_extension}" to ".${MessageAnchors.new_extension}"?`,
  remove_extension: `Are you sure you want to remove the extension ".${MessageAnchors.prev_extension}"?`,
  add_extension: `Are you sure you want to add the extension ".${MessageAnchors.new_extension}"?`,
  confirm_change: "If you make this change, your document may open in a different application.",
  error_renaming: `Error renaming "${MessageAnchors.file_name}". Please try again or contact support.`
};

export const DriveSuccessMessages = {
  save_node_permissions_success: "Successfully updated permissions",
  share_folder_success: `An invitation has been sent to share <b>"${MessageAnchors.folder_name}"</b> folder.`,
  file_download_success: `"${MessageAnchors.file_name}" successfully downloaded`,
  create_success: `New folder created: "${MessageAnchors.file_name}"`,
  success_adding_folder: `Shared folder, "${MessageAnchors.folder_name}" successfully added.`,
  accepted_but_conflict: `Invitation successfully accepted but "${MessageAnchors.folder_name}" already exists in My PreVeil. This folder will be listed in the Shared Tab.`,
  success_moving_many: `Successfully moved ${MessageAnchors.item_count} items to "${MessageAnchors.folder_name}"`,
  success_moving_one: `"${MessageAnchors.move_name}" successfully moved to "${MessageAnchors.folder_name}"`,
  success_downloading_app:
    "<p>Your file has been downloaded to the <b>PreVeil Downloads</b> folder in My PreVeil.</p><p>If your Preveil Downloads folder has <b>'Sync to this device'</b> set to Off, the file will <b>not<b> be found on the local file system.</p>",
  success_reverting_file: "Your file has been successfully reverted.",
  success_downloading: `Successfully Downloaded "${MessageAnchors.file_name}"`,
  success_lock: `The file was successfully ${MessageAnchors.message_content}.`,
  success_sync_unsync: `Successfully ${MessageAnchors.actionType} ${MessageAnchors.folder_name}.`,
  success_selective_sync: "Sync job succeeded.",
  success_cancelled_sync: "Successfully cancelled secured syncing.",
  success_rename: `"${MessageAnchors.prev_name}" successfully renamed to "${MessageAnchors.new_name}"`,
  success_duplicating_file: `Successfully duplicated file. You can find the duplicate in the same folder by the name of "${MessageAnchors.new_name}"`,
  success_accepting: `Shared folder “${MessageAnchors.folder_name}" accepted and added to your Shared page.`,
  success_rejecting: `Shared folder “${MessageAnchors.folder_name}" succesfully rejected.`,
};

// Description: Use these for cloud edit error handling - these are set in Filesync on start call
export const DriveCloudEditFileSyncMessages = { // 
  install_handle_exe: "<p>To edit a file in <b>PreVeil</b> you will need to install <b>handle.exe</b>.</p><p><b>handle.exe</b> is a utility that allows you to open files and other resources on your computer.</p><p>Install <b>handle.exe</b> and accept the terms and conditions for use.</p>",
  opening_app_message: "Please wait, PreVeil is opening the default application for this file type.",
  filesync_no_app_error: "no available apps to open the file\n",
  filesync_no_default_app_error: "No default application to open this file. Please specify an application to use.",
  filesync_unsupported_error: "This file has an unsupported extension and can therefore not be edited. We apologize for the inconvenience."
};

export const DriveMessages = {
  file_lock_info: "File Lock is a feature that allows PreVeil users to collaborate on the same shared Drive file without overwriting each others changes.</p> <p>A latest file version without change conflicts is maintained when this feature is used.</p> <p>User locks a file that they are editing so that no other user can make changes while locked. Save, close, and unlock the file to allow other users to collaborate.",
  empty_folder: "You have no items in this folder",
  no_entry_selected: "There are no details for the selected item",
  not_supported: "Type not supported in Document Viewer",
  problem_with_fetching_collection_info: "There was a problem getting information on the selected item",
  unable_to_add_self: "You can't add your own account.",
  already_shared_with_user: `This folder is already shared with ${MessageAnchors.user_name}.`,
  already_selected_user: `${MessageAnchors.user_name} have alredy been selected.`,
  confirm_force_unlock: `<p>You are about to force unlock this file. This will remove the lock placed by <b>${MessageAnchors.message_content}</b>.</p><p>Are you sure you want to proceed?</p>`,
  uploading_in_progress: "You are currently uploading files into this folder therefore some actions are disabled.",
  file_in_queue: "This file is already in the download queue",
  only_files_can_be_downloaded: "Only files can be downloaded. The selected folders will not be downloaded.",
  cloud_lock_on: "Administration has turned cloud lock on, your local files are secure in the cloud.",
};

// ------------------------------------------------------------------------------------------------------------------------
// Drive - Trash
// ------------------------------------------------------------------------------------------------------------------------
// Description: Use these anchors to replace data dynamically in text
export const TrashMessageAnchors = {
  name: "##NAME##",
  item_name: "##ITEM_NAME",
  item_count: "##ITEM_COUNT##",
  total_count: "##TOTAL_COUNT##",
};

export const TrashErrorMessages = {
  error_emptying_trash: "There was an error Emptying your Trash. Please try again or contact support.",
  error_deleting_item: "[Internal Notice]: Error deleting item (got error status from FSMethod.DELETE call)",
  error_deleting_some_items: `Error deleting ${TrashMessageAnchors.item_count} of ${TrashMessageAnchors.total_count} items`,
  error_deleting_all_items: `Error deleting ${TrashMessageAnchors.item_count} items.`,
  error_deleting_one_item: `Error deleting "${TrashMessageAnchors.name}".`,
  error_fetching_trash: "Error Fetching Trash. Please try again later or contact PreVeil support."
};

export const TrashRestoreErrorMessages = {
  error_restoring_one: `Error restoring "${TrashMessageAnchors.name}"`,
  error_restoring_all: `Error restoring ${TrashMessageAnchors.item_count} item.`,
  error_restoring_some: `Error restoring ${TrashMessageAnchors.item_count} of ${TrashMessageAnchors.total_count} item.`,
  shared_parent_title_single: `Error while restoring "${TrashMessageAnchors.name}"`,
  shared_parent_message_single: "<p>The parent folder of this item has been shared after you deleted this item and therefore the item cannot be restored to its original location.</p><p>You can download the item and reupload it to the desired folder.</p><p>For further questions, please contact PreVeil support.</p>",
  error_restoring_general_title: `Error while restoring "${TrashMessageAnchors.item_count}" items`,
  shared_parent_message_multiple: "<p>The parent folders of these items have been shared after you deleted these items and therefore the items cannot be restored to their original location.</p><p>You can download the items and reupload them to the desired folder.</p><p>For further questions, please contact PreVeil support.</p>",
  acl_conflict_title_single: `Conflict while restoring "${TrashMessageAnchors.name}"`,
  acl_conflict_message_single: "<p>There was a conflict while restoring this folder.</p><p>Please rename the folder that has the same name as the item you are trying to restore and try again.</p><p>If you do not have access to that folder or do not have the ability to rename that folder, please contact PreVeil support.</p>",
  acl_conflict_title_multiple: `Conflict while restoring "${TrashMessageAnchors.item_count}" items`,
  acl_conflict_message_multiple: "<p>There were some conflicts while restoring these items.</p><p>Please rename the folders that have the same name as the items you are trying to restore and try again.</p><p>If you do not have access to that folder or do not have the ability to rename that folder, please contact PreVeil support.</p>",
  error_restoring_two_title: `Error while restoring "${TrashMessageAnchors.name}"`,
  error_restoring_two_message: `<p>There was a conflict while restoring ${TrashMessageAnchors.name}.</p><p>Please rename the item that has the same name as the item you are trying to restore and try again.</p>
    <p>Additionally, ${TrashMessageAnchors.item_name} has a parent folder that has been shared and therefore it cannot be restored to its original location.</p><p>You can download the item and reupload it to the desired folder.</p><p>For further questions, please contact PreVeil support.</p>`,
  one_conflict_message: `<p>There was a conflict while restoring ${TrashMessageAnchors.name}.</p><p>Please rename the item that has the same name as the item you are trying to restore and try again.</p>
    <p>Additionally, ${TrashMessageAnchors.item_count} items have parent folders that have been shared and therefore these items cannot be restored to its original location.</p><p>You can download the items and reupload them again to the desired folder.</p><p>For further questions, please contact PreVeil support.</p>`,
  one_shared_parent_message: `<p>There was a conflict while restoring ${TrashMessageAnchors.item_count} items.</p><p>Please rename the folders that have the same name as the items you are trying to restore and try again.</p>
    <p>Additionally, ${TrashMessageAnchors.name} has a parent folder that has been shared and therefore it cannot be restored to its original location.</p><p>You can download the item and reupload it to the desired folder.</p><p>For further questions, please contact PreVeil support.</p>`,
  error_restoring_general_message: `<p>There was a conflict while restoring ${TrashMessageAnchors.item_count} items.</p><p>Please rename the folders that have the same name as the items you are trying to restore and try again.</p>
    <p>Additionally, ${TrashMessageAnchors.total_count} items have parent folders that have been shared and therefore these items cannot be restored to its original location.</p><p>You can download the items and reupload them again to the desired folder.</p><p>For further questions, please contact PreVeil support.</p>`
};

export const TrashSuccessMessages = {
  success_emptying_trash: "Successfully Emptied Trash",
  success_deleting_one: `"${TrashMessageAnchors.name}" was successfully deleted.`,
  success_hiding_one: `"${TrashMessageAnchors.name}" was successfully hidden and moved to Shared Tab.`,
  success_deleting_many: `Successfully Deleted ${TrashMessageAnchors.item_count} Items.`,
  success_restoring_one: `"${TrashMessageAnchors.name}" successfully restored.`,
  success_restoring_many: `Successfully Restored ${TrashMessageAnchors.item_count} Items.`,
};

export const TrashConfirmationMessages = {
  delete_link_title: `Hide Shared Folder: "${TrashMessageAnchors.name}"`,
  delete_link: "<p>Are you sure you want to hide this shared folder from your drive content?</p>" +
    "<p class='disclaimer'><b>Note:</b> It will be moved to the <b>Shared</b> page of Drive. From there you can see it or move it back.</p>",
  delete_links_title: "Hide Shared Folders",
  delete_links: "<p>Are you sure you want to hide " + `${TrashMessageAnchors.item_count}` + " shared folder from My Preveil in Drive?</p>" +
    "<p class='disclaimer'><b>Note:</b> Your folders will be moved to the <b>Shared</b> page. You can replace them into My PreVeil by selecting show from Shared.</p>",
  delete_mixed_items_title: "Remove " + `${TrashMessageAnchors.item_count}` + " Items",
  delete_mixed_items: "<p>Are you sure you want to remove <b>" + `${TrashMessageAnchors.item_count}` + "</b> items from My PreVeil Drive?</p>" +
    "<p class='disclaimer'><b>Note:</b> The shared folders will be moved to the <b>Shared</b> page and the regular folders will be moved to <b>Trash</b>.</p>" +
    "<p class='disclaimer mb-1'>You can replace deleted items by restoring them from Trash.</p>" +
    "<p class='disclaimer'>You can replace the shared folders by selecting show in the Shared page.</p>",
  empty_trash_message: "<p>All items in trash will be permanently deleted and <b>cannot be recovered.</b></p><p>Are you sure you want to empty everything in trash?</p>",
  empty_trash_title: "Empty all items in Trash?",
  confirm_delete_message: `<p>Are you sure you want to delete <b>${TrashMessageAnchors.name}</b> and all of its content?</p><p class='disclaimer'><b>Note:</b> You will be able to restore items from the Trash page.</p>`,
  confirm_permanently_delete_message: `<p>Are you sure you want to permanently delete <b>${TrashMessageAnchors.name}</b>?</p><p>This action is <b>irreversible.</b></p>`,
  confirm_restore: `<p>Restored items will be accessible under My PreVeil.</p><p>Are you sure you want to restore <b>${TrashMessageAnchors.name}</b>?</p>`
};

export const DeleteAccountMessages = {
  confirm_delete_message: "<p>Are you sure you would like to permanently <b>remove</b> this account?</p><small class='disclaimer'><b>Note:</b> This action <b>cannot</b> be reversed.</small>",
  confirm_delete_title: "Delete Account?",
  error_deleting_message: "An error occured while attempting to delete your account. Please try again or contact support.",
  org_user_warning: "Please contact your organization administrator to delete your account."
};
