import React, { createContext, useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType, AuthContextType, UserProfile } from "@preveil-api";
import { DefaultRoutes, PublicRoutes, LocalAccountStorage, Account, useAppDispatch, account_types, AppConfiguration } from "src/common";
import { accountActions, uiActions } from "src/store";

const AuthContext = createContext<AuthContextType>({
  setRedirectPath: () => null,
  setProfile: () => null,
  loginComplete: () => null
});

// Export the provider as we need to wrap the entire app with it
type AllProps = {
  children?: React.ReactNode;
}

export function AuthContextProvider(props: AllProps) {
  const { children } = props;
  const [profile, setProfile] = useState<UserProfile | undefined>();
  const [redirectPath, setRedirectPath] = useState<string>(DefaultRoutes.mail_default);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Description: Use loginComplete Callback for all Forms that logs users in 
  // Sets LocalStorage and Session Storage and redirect information
  function loginComplete(current_account: Account, account_type: AccountType = account_types.nouser, redirect_url?: string, disable_navigate: boolean = false) {
    let url: string = PublicRoutes.get_started_route;
    if (!!current_account) {
      const profile = Account.getAccountProfile(current_account);
      (!!current_account.org_info) ?
        dispatch(accountActions.getOrganizationInfo({ account_ids: Account.getAccountIdentifiers(current_account), id: current_account.org_info?.org_id, org_info: current_account.org_info })) :
        AppConfiguration.buildForApp() && dispatch(accountActions.setGlobalLocalSync(true)); // Set this to true for non org users
      setProfile(profile);
      const storage = new LocalAccountStorage(current_account, account_type);
      storage.setLocalStorageSessions();
      url = redirect_url || redirectPath || DefaultRoutes.mail_default;
    } else {
      url = redirect_url || PublicRoutes.get_started_route;
      setRedirectPath(url);
    }
    // Allow Login component to handle navigation if there are core errors
    if (!disable_navigate) {
      dispatch(uiActions.handleSetRedirectUrl()); // Reset Redirect in state
      navigate(url);
    }
  }

  // Only get re-renders when logging in. Make the provider update only when it should, only re-renders if the profile, loading or error states change.
  // Whenever the `value` passed into a provider changes, the whole tree under the provider re-renders.
  const memoedValue = useMemo(
    () => ({
      profile,
      redirectPath,
      setRedirectPath,
      setProfile,
      loginComplete
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, redirectPath]
  );

  // We only want to render the underlying app after we get current profile
  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  );
}

// Description: Export useAuthContext hook
export default function useAuthContext(): AuthContextType {
  return useContext(AuthContext);
}
