import React, { useEffect } from "react";
import {
    Account, useAppDispatch, useAppSelector, useDriveSocket, usePathList, useDefaultCollectionInfo, useDriveSubscribe, useFilesyncSocket,
    collectionApi, GetCloudOnlyModeStateApiResponse
} from "src/common";
import { RootState } from "src/store/configureStore";
import { WebsocketStatus, appActions, accountActions } from "src/store";

// Description: App Drive Initialize sets up the app for app use through websocket
// NOTE: we might merge this with the WebDriveInit component if we dont add anything specific to app
function AppDriveInitializeComponent(props: { current_account: Account; }) {
    const { current_account } = props;
    const org_id = useAppSelector((state: RootState) => state.account.organization?.org_id);
    const new_global_sync_change = useAppSelector((state: RootState) => state.app.new_global_sync_change);
    const ws_status = useAppSelector((state: RootState) => state.websocket.drive_ws_status);
    const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
    const collection_id = useAppSelector((state: RootState) => state.drive.current_directory?.collection_id);

    const dispatch = useAppDispatch();
    useDriveSocket();
    useFilesyncSocket(current_account);
    useDefaultCollectionInfo(current_account, default_permissions, ws_status === WebsocketStatus.connected);
    useDriveSubscribe(current_account, collection_id, default_permissions);
    usePathList(current_account, collection_id);
    const [getGlobalSyncState] = collectionApi.endpoints.getCloudOnlyUserLockState.useLazyQuery();

    useEffect(() => {
        (!!org_id && new_global_sync_change) && handleGetGlobalSyncState(org_id);
    }, [new_global_sync_change]);

    // Description: Global Sync Permissions changed
    function handleGetGlobalSyncState(entityId: string) {
        getGlobalSyncState({
            account_ids: Account.getAccountIdentifiers(current_account),
            body: {
                userId: current_account.user_id,
                entityId
            }
        }).unwrap()
            .then((response: GetCloudOnlyModeStateApiResponse) => {
                // NOTE: Handle Notification, set new sync permissions, and set the flag to false 
                dispatch(accountActions.setGlobalLocalSync(response.sync)); // SET LOCAL SYNC STATE
                dispatch(appActions.setNewGlobalSyncChange(false)); // SET NOTIFICATION FLAG THIS OFF
            })
            .catch(() => {
                dispatch(appActions.setNewGlobalSyncChange(false)); // SET NOTIFICATION FLAG THIS OFF 
            });
    }
    return null;
}

export default React.memo(AppDriveInitializeComponent);