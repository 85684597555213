import React, { useState } from "react";
import { Card, Form, Button, InputGroup, Alert } from "react-bootstrap";
import { Formik } from "formik";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountUIActionTypes, FormValidationLimits, AccountErrorMessages, SupportRoutes } from "src/common";
import { Icon } from "src/components";
import * as yup from "yup";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

type FormProps = {
  password: string;
  confirmPassword: string;
}

function PasswordFormComponent(props: AllProps) {
  const { handleAction } = props;
  const [show_password, setShowPassword] = useState<boolean>(false);
  const [show_confirm_password, setShowConfirmPassword] = useState<boolean>(false);
  const schema = yup.object().shape({
    password: yup.string()
      .required(AccountErrorMessages.password_required_error)
      .min(
        FormValidationLimits.PASSWORD_MIN_LENGTH,
        AccountErrorMessages.password_helper
      ),
    confirmPassword: yup.string()
      .required(AccountErrorMessages.password_required_error)
      .oneOf([yup.ref("password"), null], "Passwords must match")
  });

  // Description: Handle form submit
  // function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
  function handleSubmit(formValues: FormProps) {
    const params = formValues.password;
    handleAction({ actionType: CreateAccountUIActionTypes.SubmitPassword, params });
  }

  return <Card.Body>
    <h3 className="border-bottom">Please create your new PreVeil Express password</h3>
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        password: "",
        confirmPassword: ""
      }} >
      {({ dirty, values, errors, touched, handleChange, handleSubmit, handleBlur, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="password_tb" className="fs-6" >Password</Form.Label>
            <InputGroup className={`password-form-control ${!!errors.password && touched.password ? "invalid" : ""}`} hasValidation={true}>
              <Icon className="ficon-lock" />
              <Form.Control
                id="password_tb"
                type={!!show_password ? "text" : "password"}
                autoFocus={true}
                aria-describedby="passwordInput"
                placeholder="Enter Password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={!!errors.password && touched.password} />
              <Button
                variant="transparent"
                className="btn-icon"
                size="sm"
                tabIndex={-1}
                onClick={() => setShowPassword(!show_password)}
                aria-label="show password"
              >
                <span>
                  <Icon className={!!show_password ? "ficon-eye" : "ficon-eye-off"} />
                </span>
              </Button>
            </InputGroup>
            {
              !!errors.password && touched.password ?
                <Form.Control.Feedback type="invalid" className={`${!!errors.password && touched.password ? "d-block" : ""}`}>
                  {errors.password}
                </Form.Control.Feedback> :
                <Form.Text className="text-muted d-block" as="div">
                  {AccountErrorMessages.password_helper}
                </Form.Text>
            }
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="confirm_password_tb" className="fs-6" >Confirm Password</Form.Label>
            <InputGroup className={`password-form-control ${!!errors.confirmPassword && touched.confirmPassword ? "invalid" : ""}`} hasValidation={true}>
              <Icon className="ficon-lock" />
              <Form.Control
                id="confirm_password_tb"
                type={!!show_confirm_password ? "text" : "password"}
                aria-describedby="confirmPasswordInput"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword && touched.confirmPassword} />
              <Button
                variant="transparent"
                className="btn-icon"
                size="sm"
                tabIndex={-1}
                onClick={() => setShowConfirmPassword(!show_confirm_password)}
                aria-label="show confirm password">
                <span>
                  <Icon className={!!show_confirm_password ? "ficon-eye" : "ficon-eye-off"} />
                </span>
              </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid" className={`${!!errors.confirmPassword && touched.confirmPassword ? "d-block" : ""}`}>
              {errors.confirmPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <p className="mt-3">For your security, PreVeil <b className="text-primary">does not save</b> your password.</p>
          <Alert variant="warning p-2">Use your browser's password manager to securely save your password
            <Button variant="link" size="sm" className="px-0" target="_blank" href={SupportRoutes.browser_password_management}>Show me how</Button>
          </Alert>
          <div className="btn-panel">
            <Button type="submit" disabled={!dirty || !isValid} >Continue</Button>
            <Button variant="outline-secondary" type="button"
              onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Cancel</Button>
          </div>
        </Form>
      )}
    </Formik>
  </Card.Body>;
}
export default React.memo(PasswordFormComponent);
