/*
* Description: Collection Filsync API endpoints for websocket calls
* Author:      PreVeil, LLC
*/
import { Account, FSRoleType, ACLRoleType, DriveRequest, PermissionSetType, GrantSetType } from "src/common";
import { GrantSet } from "src/common/drive/grants.class";
import { FSMethod, FSRequest, Node, FSRole, FSType, FSWrappedKey, SealedContent } from "src/common/keys/protos/collections_pb";

// Description: Set the initial Default collection id (used for express new accounts)
const setDefaultCollectionId = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: Uint8Array,
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.SET_DEFAULT_COLLECTION_ID);
  request.setCollectionId(collection_id);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Get All Collections' Default Permissions
const getPermissions = async (current_account: Account, collection_id?: string): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.PERMISSIONS);
  !!collection_id && request.setCollectionId(collection_id);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Get the Default Collection ID
const getDefaultCollectionId = async (current_account: Account): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.DEFAULT_COLLECTION_ID);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Get All Collections' Default Permissions
const getCollectionAttributes = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.GET_COLLECTION_ATTRIBUTES);
  request.setCollectionId(collection_id);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Get Permissions Key History
const getKeyHistory = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  role: FSRoleType = FSRole.READER): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.KEY_HISTORY);
  request.setCollectionId(collection_id);
  request.setRole(role);
  return await DriveRequest.initializeRequest(request, current_account, permissions);
}

const getNodePermissions = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  id: string): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FETCH_NODE_PERMISSIONS);
  request.setCollectionId(collection_id);
  const fetch = new FSRequest.Fetch();
  fetch.setId(id);
  request.setFetch(fetch);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}


// ----------------------------------------------------------------------------------------
// Return FSRequests
// ----------------------------------------------------------------------------------------
// Description: Get the ROOT information for Drive default collection
const getRootInfoRequest = async (collection_id: string): Promise<FSRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.ROOT_INFO);
  request.setCollectionId(collection_id);
  return request
}

// Description: Get ACL Tree for a collection
const getACLTreeRequest = async (collection_id: string, dir_id?: string): Promise<FSRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.ACL_TREE);
  request.setCollectionId(collection_id);
  const acl_tree = new FSRequest.ACLTree();
  !!dir_id && acl_tree.setDirId(dir_id);
  request.setAclTree(acl_tree);
  return request;
}

// Description: Get A directory's information (without need of entries): ACL List (Node.Grant[]), version, wrapped key...
// NOTE: setLimit to 1 to reduce impact as we only need the acl_list (0 is not allowed)
const getDirectoryInformationRequest = async (
  collection_id: string,
  id: string,
  include_deleted = false
): Promise<FSRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FETCH_DIR);
  request.setCollectionId(collection_id);
  const fetch = new FSRequest.Fetch();
  fetch.setId(id);
  fetch.setLimit(1);
  fetch.setIncludeDeleted(include_deleted);
  request.setFetch(fetch);
  fetch.setIncludeAcl(true);
  return request;
}

// Description: Get ACL Key History
const getAclKeyHistoryRequest = async (
  collection_id: string,
  id: string,
  role: ACLRoleType = Node.ACLRole.READER
): Promise<FSRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.ACL_KEY_HISTORY);
  request.setCollectionId(collection_id);
  const acl_key_history = new FSRequest.ACLKeyHistory();
  acl_key_history.setAclNodeId(id);
  acl_key_history.setAclRole(role);
  request.setAclKeyHistory(acl_key_history);
  request.setMethod(FSMethod.ACL_KEY_HISTORY);
  return request;
}

// Description: get a list of items in a directory
// Returs FSRequest
// NOTE: fetch.setIncludeDeleted(false); - Defaults to false
const fetchDirectoryRequest = async (
  collection_id: string,
  id: string,
  limit: number = 0, // Pass limit if needed (for offsets)
  include_path: boolean = true, // defaults to false in server
  include_totals: boolean = true, // Always include totals unless not needed
  include_acl: boolean = true,
  include_deleted: boolean = false,
  offset: number = 0
): Promise<FSRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FETCH_DIR);
  request.setCollectionId(collection_id);
  const fetch = new FSRequest.Fetch();
  fetch.setId(id);
  fetch.setIncludePath(include_path); // Defaults to false
  fetch.setIncludeSelfInPath(true);
  fetch.setIncludeTotal(include_totals);
  fetch.setIncludeDeleted(include_deleted);
  limit && fetch.setLimit(limit);
  !!offset && fetch.setOffset(offset)
  request.setFetch(fetch);
  fetch.setIncludeAcl(include_acl); // Defaults to false
  return request;
};

// Description: get a list of items in shared directory / link
const fetchLinkRequest = async (
  collection_id: string,
  id: string
): Promise<FSRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FETCH_LINK);
  request.setCollectionId(collection_id);
  const fetch = new FSRequest.Fetch();
  fetch.setId(id);
  request.setFetch(fetch);
  return request;
}


// ----------------------------------------------------------------------------------------
// Return DriveRequest
// ----------------------------------------------------------------------------------------
// Description: Grants access to users for share or for adding users to an already existing acl node (Legacy: addUserstoAcl)
// NOTE: Use this for granting access to users OR to groups for grantLogKeyToAdminGroup (Grants the log key to the admin group - promoteDirectory)
const grantAccess = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  roles: FSRequest.Grant.Role[],
  grant_users: FSRequest.Grant.User[] | null,// Legacy: sharing_list
  groups_list?: FSRequest.Grant.Group[]  // Legacy: groups
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.GRANT);
  request.setCollectionId(collection_id);
  const grant = new FSRequest.Grant();
  !!grant_users && grant.setUsersList(grant_users);
  !!groups_list && grant.setGroupsList(groups_list);
  grant.setRolesList(roles);
  request.setGrant(grant);
  return await DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: get link from a collection ID and the Linked Collection ID
const findLinkRequest = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  linked_collection_id: string
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FIND_LINKS);
  request.setCollectionId(collection_id);
  const findlinks = new FSRequest.FindLinks();
  findlinks.setLinkedCollectionId(linked_collection_id);
  request.setFindLinks(findlinks);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Returns a list of shared collection links and their paths inside a default collection 
const fetchActiveLinks = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  seq: number
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.LIST_LINKS);
  request.setCollectionId(collection_id);
  request.setSeq(seq);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Returns a list of unlinked shared collection and their paths inside a default collection 
const fetchInactiveLinks = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.LIST_UNLINKED);
  request.setCollectionId(collection_id);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Get a file for downloadFile, restoreRequests and moveItem
const fetchFile = (
  current_account: Account,
  collection_id: string,
  id: string,
  permissions: PermissionSetType,
  include_deleted: boolean = false
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FETCH_FILE);
  request.setCollectionId(collection_id);
  const fetch = new FSRequest.Fetch();
  fetch.setId(id);
  fetch.setIncludeDeleted(include_deleted);
  request.setFetch(fetch);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Fetch Snapshot - Returns the entire state of the collection in a single response message.
const fetchSnapshot = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  id: string,
  seq: number,
  subseq: number,
  omit_blocks: boolean = false,
  include_last_update_fields: boolean = false,
  limit?: number // Defaults to server limits currently 5000
): Promise<DriveRequest> => {
  const snapshot = new FSRequest.Snapshot();
  !!id && snapshot.setDirId(id); // NOTE: If the string is empty do not add the id to the snapshot request
  snapshot.setSubseq(subseq);
  snapshot.setOmitBlocks(omit_blocks)
  !!limit && snapshot.setLimit(limit);
  snapshot.setIncludeLastUpdateFields(include_last_update_fields);
  const request = new FSRequest();
  request.setMethod(FSMethod.SNAPSHOT);
  request.setCollectionId(collection_id);
  request.setSnapshot(snapshot);
  request.setSeq(seq);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Initialize a new V1 collection FSRequest Object
// NOTES: is_complete defaults to True - used when creating a shared collection = false if sharing a collection
//        notes whether this collection has been updated with all expected files
const initCollection = (
  current_account: Account,
  collection_id: Uint8Array,
  id: string | Uint8Array,
  encrypted_name: Uint8Array,
  version: string | Uint8Array,
  wrapped_root_dir_key: Uint8Array,
  keys: FSRequest.Init.Key[],
  is_complete: boolean = true
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.INIT);
  request.setCollectionId(collection_id);
  const init = new FSRequest.Init();
  init.setRootDirId(id);
  init.setRootDirName(encrypted_name);
  init.setRootDirVersion(version);
  init.setWrappedRootDirKey(wrapped_root_dir_key);
  init.setKeysList(keys);
  init.setIsComplete(is_complete);
  request.setInit(init);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Initialize a new V2 collection FSRequest Object
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#version-2-collections-with-acl-nodes
// NOTES: For V2 collections, these top level keys are the collection keys, and should include OWNER, ACCESS, and LOG_VIEWER.
const initV2Collection = (
  current_account: Account,
  collection_id: Uint8Array,
  id: string | Uint8Array,
  name: Uint8Array, // encrypted with the Reader Key 
  encrypted_name: Uint8Array,// encrypted with the Access Key 
  maintainer_scoped_name: SealedContent,
  version: string | Uint8Array,
  wrapped_root_dir_key: Uint8Array,
  keys: FSRequest.Init.Key[],
  node_permissions: Node.Permission[],
  node_grants: Node.Grant[]
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.INIT);
  request.setCollectionId(collection_id);
  const init = new FSRequest.Init();
  init.setRootDirId(id);
  init.setRootDirVersion(version);
  init.setWrappedRootDirKey(wrapped_root_dir_key);
  init.setName(name);
  init.setRootDirName(encrypted_name);
  init.setMaintainerScopedName(maintainer_scoped_name);
  init.setKeysList(keys);
  init.setRootDirPermissionsList(node_permissions);
  init.setRootDirGrantsList(node_grants);
  init.setProtocolVersion(2);
  init.setIsComplete(false);
  request.setInit(init);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Create a Link Item
const createLink = (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  parent_id: string,
  parent_version: string,
  id: Uint8Array,
  version: Uint8Array,
  name: Uint8Array,
  linked_collection_id: string // linkedCollectionID
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.CREATE);
  request.setCollectionId(collection_id);
  const create = new FSRequest.Create();
  create.setParentId(parent_id);
  create.setParentVer(parent_version);
  create.setId(id);
  create.setVersion(version);
  create.setName(name);
  create.setType(FSType.LINK);
  create.setLinkedCollectionId(linked_collection_id);
  request.setCreate(create);
  return DriveRequest.initializeRequest(request, current_account, permissions);
};

// Description: Create a File Item
const createFile = (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  parent_id: string,
  parent_version: string,
  id: Uint8Array,
  version: Uint8Array,
  name: Uint8Array,
  wrapped_dir_key: FSWrappedKey,
  block_ids: Array<string | Uint8Array>,
  grants?: GrantSetType
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.CREATE);
  request.setCollectionId(collection_id);
  const create = new FSRequest.Create();
  create.setParentId(parent_id);
  create.setParentVer(parent_version);
  create.setId(id);
  create.setVersion(version);
  create.setName(name);
  create.setType(FSType.FILE);
  create.setWrappedDirKey(wrapped_dir_key);
  create.setBlockIdsList(block_ids);
  request.setCreate(create);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);
};

const createDir = (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  parent_id: string,
  parent_version: string,
  id: string,
  version: Uint8Array,
  name: Uint8Array,
  wrapped_dir_key: FSWrappedKey,
  grants?: GrantSetType
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.CREATE);
  request.setCollectionId(collection_id);
  const create = new FSRequest.Create();
  create.setParentId(parent_id);
  create.setParentVer(parent_version);
  create.setId(id);
  create.setVersion(version);
  create.setName(name);
  create.setType(FSType.DIR);
  create.setWrappedDirKey(wrapped_dir_key);
  request.setCreate(create);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);
};

// Description: Handles renaming entries
const renameEntry = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  id: string,
  old_version: string,
  new_version: Uint8Array,
  new_parent_version: string,
  new_name: Uint8Array,
  maintainer_scoped_name?: SealedContent,
  grants?: GrantSetType | GrantSetType[]
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.UPDATE);
  request.setCollectionId(collection_id);
  const update = new FSRequest.Update();
  update.setId(id);
  update.setOldVersion(old_version);
  update.setNewVersion(new_version);
  update.setNewParentVer(new_parent_version);
  const change = new FSRequest.Update.Change();
  change.setOp(FSRequest.Update.Op.RENAME);
  change.setNewName(new_name);
  !!maintainer_scoped_name && change.setMaintainerScopedName(maintainer_scoped_name);
  update.addChanges(change);
  request.setUpdate(update);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);
};

// Description: Move an item to a new directory
const moveItem = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  id: string,
  old_version: string,
  new_parent_id: string, //  new_directory_id: string,
  new_parent_version: string,
  new_name: Uint8Array,
  new_version: Uint8Array,
  new_directory_key: FSWrappedKey,
  grants?: GrantSetType[], // { grants: Node.Grant[], node_id: string }[] = []
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.UPDATE);
  request.setCollectionId(collection_id);
  const update = new FSRequest.Update();
  update.setId(id);
  update.setOldVersion(old_version);
  update.setNewVersion(new_version);
  update.setNewParentVer(new_parent_version);
  const change = new FSRequest.Update.Change();
  change.setOp(FSRequest.Update.Op.MOVE);
  change.setNewParentId(new_parent_id);
  change.setNewName(new_name);
  change.setNewDirKey(new_directory_key);
  update.addChanges(change);
  request.setUpdate(update);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);
};

// Description: Upload block used for _chunkFileAndUpload
const uploadBlock = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  block_id: string,
  fs_wrapped_key: FSWrappedKey,
  data: Uint8Array, // Legacy: enc_block
  block_size: number,
  hash: Uint8Array, // Legacy: enc_chunk_hash
  grants?: GrantSetType
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.UPLOAD_BLOCK);
  request.setCollectionId(collection_id);
  const upload = new FSRequest.Upload();
  upload.setBlockId(block_id);
  upload.setWrappedKey(fs_wrapped_key);
  upload.setData(data);
  upload.setBlockSize(block_size);
  upload.setHash(hash);
  request.setUpload(upload);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);
}

// Description: Legacy Download block
const downloadBlock = (
  current_account: Account,
  collection_id: string,
  block_id: string,
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setCollectionId(collection_id);
  request.setLegacyDownload(block_id);
  request.setMethod(FSMethod.DOWNLOAD_BLOCK);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Download blocks from collection server
const downloadBlocks = (
  current_account: Account,
  collection_id: string,
  block_ids: string[],
  access_type: FSRequest.DownloadBlocks.BlockAccessTypeMap[keyof FSRequest.DownloadBlocks.BlockAccessTypeMap],
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setCollectionId(collection_id);
  request.setMethod(FSMethod.DOWNLOAD_BLOCKS);
  const downloadBlocks = new FSRequest.DownloadBlocks();
  downloadBlocks.setBlockIdsList(block_ids);
  downloadBlocks.setAccessType(access_type);
  request.setDownload(downloadBlocks);
  return DriveRequest.initializeRequest(request, current_account);
}


// Description: Download block directly  ***** WORKING
// NOTE: need to confirm how to use this method: 
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#download_block_direct
const downloadBlockDirect = (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  block_ids: FSRequest.DownloadBlocks // { blockIdsList: string[]; }
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setCollectionId(collection_id);
  request.setDownload(block_ids);
  request.setMethod(FSMethod.DOWNLOAD_BLOCK_DIRECT);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Download block directly  ***** WORKING
// NOTE: need to confirm how to use this method: 
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#download_blocks_direct
const downloadBlocksDirect = (
  current_account: Account,
  collection_id: string,
  block_ids: string[],
  accessType: FSRequest.DownloadBlocks.BlockAccessTypeMap[keyof FSRequest.DownloadBlocks.BlockAccessTypeMap],
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setCollectionId(collection_id);
  request.setMethod(FSMethod.DOWNLOAD_BLOCKS_DIRECT);
  const downloadBlocks = new FSRequest.DownloadBlocks();
  downloadBlocks.setBlockIdsList(block_ids);
  downloadBlocks.setAccessType(accessType);
  request.setDownload(downloadBlocks);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Rekey Roles 
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#rekey
const rekeyCollection = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  rekeyRoles: FSRequest.Rekey.RekeyRole[]
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.REKEY);
  request.setCollectionId(collection_id);
  const rekey = new FSRequest.Rekey();
  rekey.setRekeyRolesList(rekeyRoles);
  request.setRekey(rekey);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Retrieve all pending requests to share a collection with the current user.
const getSharedWithMe = async (
  current_account: Account
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FETCH_SHARES);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Accept or reject a request to share a collection with the current user.
const shareRespond = async (
  current_account: Account,
  collection_id: string,
  id: string,
  accept: boolean
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.HANDLE_SHARE);
  request.setCollectionId(collection_id);
  request.setId(id);
  request.setAccept(accept);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Makes a directory shareable in 3 steps:
// 1. INIT a new collection with is_complete = false. For ease of implementation, can set the root_dir_id equal to the shared folder's dir_id. 
// 2. BULK_UPDATE folders and files from the folder being shared to the new collection.
// 3. Use MAKE_SHAREABLE to turn the shared dir into a link to the new collection. The server checks that the provided seq is at least as great as the dir's max rev_id. The server will also set is_complete to true at this point.
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#make_shareable
const makeShareable = async (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  id: string,
  version: string,
  link_id: Uint8Array,
  link_version: Uint8Array,
  linked_collection_id: string,
  seq: number
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.MAKE_SHAREABLE);
  request.setCollectionId(collection_id);
  const makeshareable = new FSRequest.MakeShareable();
  makeshareable.setDirId(id);
  makeshareable.setDirVersion(version);
  makeshareable.setLinkId(link_id);
  makeshareable.setLinkVersion(link_version);
  makeshareable.setLinkedCollectionId(linked_collection_id);
  makeshareable.setSeq(seq);
  request.setMakeShareable(makeshareable);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Stub for a request 
// NOTE: used in upgrade collection, get collection info, and List User Roles
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#list_users
const getListUsersRequest = async (
  collection_id: string,
): Promise<FSRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.LIST_USERS);
  request.setCollectionId(collection_id);
  return request;
}

// Description: Sets collection attributes such as a new name
const setCollectionAttributes = async (
  current_account: Account,
  collection_id: string,
  name: SealedContent,
  permissions: PermissionSetType,
  grants?: GrantSetType
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.SET_COLLECTION_ATTRIBUTES);
  request.setCollectionId(collection_id);
  const collection_attributes = new FSRequest.SetCollectionAttributes();
  collection_attributes.setName(name);
  request.setCollectionAttributes(collection_attributes);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);;
}

// Description: Encapsulates a set of CRUD operations on a collection into a single (atomic) operation. 
// Lists are unordered, but causally prior updates (e.g., creating something's parent) must occur no later than in the same batch.
// This path should always directly create/update to the latest version of a file, and will not accept multiple operations on the same directory/file in a single batch. 
// Parent versions are enforced, as in the single operation paths.
const bulkUpdate = async (
  current_account: Account,
  collection_id: string | Uint8Array,
  new_entries: FSRequest.Create[],
  updated_entries: FSRequest.Update[],
  deleted_entries: FSRequest.Delete[],
  new_blocks: FSRequest.BulkUpdate.Block[],
  restored_entries: FSRequest.Restore[],
  permissions: PermissionSetType,
  grants?: GrantSetType[],
): Promise<DriveRequest | null> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.BULK_UPDATE);
  request.setCollectionId(collection_id);
  const bulk_update = new FSRequest.BulkUpdate();
  bulk_update.setNewEntriesList(new_entries);
  (!!new_blocks && new_blocks.length) && bulk_update.setNewBlocksList(new_blocks); // if hasFile? => legacy
  bulk_update.setUpdatedEntriesList(updated_entries);
  bulk_update.setDeletedEntriesList(deleted_entries);
  bulk_update.setRestoredEntriesList(restored_entries); // If restoring items in bulk
  request.setBulkUpdate(bulk_update);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);
}

// Description: Upgrade Collections from collection_protocol_version = 1 to collection_protocol_version = 2
const upgradeCollection = async (
  current_account: Account,
  permissions: PermissionSetType, // new_permission_set
  collection_id: string,
  grants_list: Node.Grant[],
  permissions_list: Node.Permission[],
  access_grant: FSRequest.UpgradeCollection.AccessGrant,
  name: SealedContent,
  maintainer_scoped_name: SealedContent,
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.UPGRADE_COLLECTION);
  request.setCollectionId(collection_id);
  const upgrade_collection = new FSRequest.UpgradeCollection();
  upgrade_collection.setRootDirGrantsList(grants_list);
  upgrade_collection.setRootDirPermissionsList(permissions_list);
  upgrade_collection.setToVersion(2);
  upgrade_collection.setName(name);
  upgrade_collection.setAccessGrant(access_grant);
  upgrade_collection.setMaintainerScopedName(maintainer_scoped_name);
  request.setUpgrade(upgrade_collection);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Clears the trash folder by marking all nodes in trash as expunged. Takes user_info as an arg
const emptyTrash = async (
  current_account: Account,
  collection_id: string,
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.EMPTY_TRASH);
  return DriveRequest.initializeRequest(request, current_account);
}

// Description: Clears the trash folder by marking all nodes in trash as expunged. Takes user_info as an arg
const fetchDeletedEntries = async (
  current_account: Account
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.FETCH_DELETED);
  return DriveRequest.initializeRequest(request, current_account);
}

const deleteItem = async (
  current_account: Account,
  collection_id: string,
  id: string,
  version: string,
  expunge: boolean,
  permissions: PermissionSetType,
  grants?: GrantSetType
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setCollectionId(collection_id);
  request.setMethod(FSMethod.DELETE);
  const delete_request = new FSRequest.Delete();
  delete_request.setExpunge(expunge);
  delete_request.setId(id);
  delete_request.setVersion(version);
  request.setDelete(delete_request);
  return DriveRequest.initializeRequest(request, current_account, permissions, grants);
};

// Description: Subscribe to a topic: 
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync_notifications.md#notification-topics
// https://github.com/PreVeil/core/blob/dev/collection_server/docs/filesync.md#subscribe
const subscribeTopic = (
  current_account: Account,
  permissions: PermissionSetType,
  collection_id: string,
  seq: number = 0,
  include_snapshot: boolean = false
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.SUBSCRIBE);
  request.setCollectionId(collection_id);
  request.setSeq(seq);
  request.setIncludeSnapshot(include_snapshot);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: Subscribe to all accessible collections: 
const subscribeAll = (
  current_account: Account,
  permissions: PermissionSetType,
  seq: number = 0,
  include_snapshot: boolean = false
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.SUBSCRIBE_ALL);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}

// Description: ACK Notifications: 
const AcknowledgeNotification = (
  current_account: Account,
  permissions: PermissionSetType,
  topic: string,
  seq: number = 0,
): Promise<DriveRequest> => {
  const request = new FSRequest();
  request.setMethod(FSMethod.ACK);
  const ack = new FSRequest.Ack();
  ack.setTopic(topic)
  ack.setSeq(seq);
  request.setAck(ack);
  return DriveRequest.initializeRequest(request, current_account, permissions);
}


export const CollectionFilesyncAPI = {
  fetchDirectoryRequest,
  getRootInfoRequest,
  fetchLinkRequest,
  getAclKeyHistoryRequest,
  getDirectoryInformationRequest,
  getACLTreeRequest,
  getListUsersRequest,

  // Return DriveRequest
  setDefaultCollectionId,
  getPermissions,
  getDefaultCollectionId,
  getCollectionAttributes,
  getKeyHistory,
  getNodePermissions,
  grantAccess,
  findLinkRequest,
  fetchActiveLinks,
  fetchInactiveLinks,
  fetchFile,
  fetchSnapshot,
  initCollection,
  initV2Collection,
  createLink,
  createFile,
  createDir,
  moveItem,
  uploadBlock,
  downloadBlock,
  downloadBlocks,
  downloadBlockDirect,
  downloadBlocksDirect,
  rekeyCollection,
  getSharedWithMe,
  shareRespond,
  makeShareable,
  bulkUpdate,
  upgradeCollection,
  emptyTrash,
  fetchDeletedEntries,
  deleteItem,
  subscribeTopic, // NOTE: This might not be needed at the end and can be removed
  subscribeAll,
  AcknowledgeNotification,
  setCollectionAttributes,
  renameEntry
};
