import React from "react";
import { ActionHandlerFunction, OrgInfo } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Icon } from "src/components";
import { Button, Row, Col } from "react-bootstrap";

type AdminGroupApprover = {
  user_id: string;
  display_name: string | null;
  department?: string;
  user_role?: string;
  org_info?: {
    dept_name: string;
  } | OrgInfo | null;
};

type AllProps = {
  member: AdminGroupApprover;
  handleActions?: ActionHandlerFunction;
  is_delete?: boolean;
};

function AdminRow({ member, handleActions, is_delete }: AllProps) {
  const { display_name, user_id, org_info, department } = member;
  const formatted_user_id = user_id.toLowerCase();
  return (
    <Row className="align-items-center">
      <Col xs={7}>
        <p className="semi-bold">{display_name}</p>
        <p>{formatted_user_id}</p>
      </Col>
      <Col xs={4} className="ps-1 text-capitalize">
        <p className="m-0">{org_info?.dept_name || department }</p>
      </Col>
      {is_delete && (
        <Col xs={1}>
          <Button 
            variant="icon"
            size="sm"
            title="Delete User Group"
            data-tooltip-id="pv-tooltip"
            data-tooltip-content="Delete User Group"
            onClick={() =>
              handleActions &&
              handleActions({ actionType: AdminUIActionTypes.Delete, params: [user_id] })
            }
          >
            <Icon className="ficon-x" />
          </Button>
        </Col>
      )}
    </Row>
  );
}

export default React.memo(AdminRow);