import { ActivityLogInfo, ActivityLogMetadata, UserProfile } from "@preveil-api";
import _ from "lodash";
import {
  dayjs,
  Entity,
  DriveLogTypeMapping,
  DriveLogEntryType,
  NodePermissions,
  DefaultCollectionName,
  AdminApprovalGroupRoleMapping,
  DriveTypeMapping,
  DriveTypeMappingType,
  NodePermissionType,
  ACLRoleType,
  FSRoleType
} from "src/common";
import { FSRole, FSRoleMap, Node } from "../keys/protos/collections_pb";

export const ExportOption = {
  email: "Email",
  drive: "Drive",
  logs: "Logs",
  acl_report: "ACL Report",
};

export class ActivityLog extends Entity {
  public formatted_timestamp: string;
  public actor: UserProfile;
  public mapped_action: string = "";
  public relevant_users: UserProfile[] = [];
  public sub_details: string = "";
  public link?: { text: string; href: string; query: any };
  public tooltip: string = "";

  constructor(
    uid: string,
    public timestamp: string,
    public collection_id: string,
    public topic: string,
    public action: string,
    public device: string,
    public user: UserProfile,
    public metadata: ActivityLogMetadata,
    public global_idx: number,
    public user_id: string,
    public is_admin: boolean = false,
    public do_not_show: boolean = false,
  ) {
    super(uid);
    this.actor = this.user;
    this._map_action();
    this.formatted_timestamp = dayjs.utc(this.timestamp).local().format("MMM D, YYYY hh:mm A");
  }

  private _map_action(): void {
    // used in a few places
    let name: string;
    let type: string;
    let user_names: string;
    let given_permissions: NodePermissions;
    let acl_roles: ACLRoleType[];
    let fs_roles: FSRoleType[];
    let domains: string[] | undefined;
    let action_string: string;
    let users_list: string[] | undefined = [];
    let access_type: string | undefined;
    switch (this.action) {
      case "access_file":
        type = DriveTypeMapping[this.metadata.added_data.type.toUpperCase() as DriveTypeMappingType];
        name = this.metadata.added_data.filename;
        access_type = this.metadata.info.access_type;
        if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (!type) {
          this.tooltip = "Name and path not available";
          this.mapped_action = "accessed a <span class=\"log-field\">file</span> or <span class=\"log-field\">folder</span>";
        } else {
          this.mapped_action = this.mapAccessFileMessage(type.toLowerCase(), name, access_type);
        }
        break;
      case "delete_email":
        this.mapped_action = "deleted an <span class=\"log-field\">email</span>";
        break;
      case "put_org_setting":
        try {
          const changes: string[] = [];
          Object.entries(this.metadata.info).forEach(([key, value], index) => {
            let change: string;
            if (key === "sync_collections_by_default") {
              change = `Drive Sync Default Setting set to <span class="log-field">${value}</span>`;
            } else {
              change = `<span class="log-field">${key}</span> setting is <span class="log-field">${value}</span>`;
            }
            changes.push(change);
          });
          this.mapped_action = changes.join(";<span>&nbsp;</span>");
        } catch (error) {
          console.error(error);
          this.mapped_action = "changed default settings";
        }
        break;
      case "delete_email_from_trash":
        this.mapped_action = "deleted an email from trash";
        break;
      case "delete_collection_node_from_trash":
        this.mapped_action = "deleted the <file or directory> <name> from trash";

        type = this.metadata.nodes[0].type;
        if (type === "") {
          this.tooltip = "Name and path not available";
          this.mapped_action = "deleted a <span class=\"log-field\">file</span> or <span class=\"log-field\">folder</span> from trash";
          break;
        }

        name = this.metadata.added_data.filename;
        if (name === "") {
          this.tooltip = "Name and path not available";
          this.mapped_action = `deleted <span class="log-field">${type}</span> from trash`;
          break;
        }

        type = type === "file" ? "file" : "folder";
        this.mapped_action = `deleted ${type} <span class="log-field">${name}</span> from trash`;
        break;
      case "empty_items_from_trash":
        this.mapped_action = "emptied items from trash";
        break;
      case "empty_shared_items_from_trash":
        this.mapped_action = "emptied shared items from trash";
        break; 
      case "sent_email":
        this.mapped_action = "sent an <span class='fw-600'>email</span> to: ";
        this.relevant_users = this.metadata.users.map(
          (user: { display_name: string; user_id: string }) => {
            return { name: user.display_name, address: user.user_id };
          },
        );
        break;
      case "create_collection":
      case "create_collection_node":
        type = this.metadata.nodes[0].type;
        if (type === "") {
          this.mapped_action =
            "created a <span class='fw-600'>file</span> or <span class='fw-600'>folder</span>";
          break;
        }
        name = this.metadata.added_data.filename;
        if (name === "") {
          this.tooltip = "Name and path not available";
          this.mapped_action = `created a <span class="fw-600">${type}</span>`;
          break;
        }
        type = type === "file" ? "file" : "folder";
        if (!!this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (name === DefaultCollectionName) {
          this.mapped_action = " initialized <span class='fw-600'>PreVeil Drive</span>";
          break;
        }
        this.mapped_action = ` created the ${type} <span class="fw-600">${name}</span>`;
        break;
      case "delete_collection_node":
        type = this.metadata.nodes[0].type;
        if (type === "") {
          this.tooltip = "Name and path not available";
          this.mapped_action =
            " deleted a <span class='fw-600'>file</span> or <span class='fw-600'>folder</span>";
          break;
        }
        name = this.metadata.added_data.filename;
        if (name === "") {
          this.tooltip = "Name and path not available";
          this.mapped_action = ` deleted a <span class="fw-600">${type}</span>`;
          break;
        }
        type = type === "file" ? "file" : "folder";
        if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        this.mapped_action = ` deleted the ${type} <span class="fw-600">${name}</span>`;
        break;
      case "view_file":
        type = DriveTypeMapping[this.metadata.added_data.type as DriveTypeMappingType];
        name = this.metadata.added_data.filename;
        if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (!type) {
          this.tooltip = "Name and path not available";
          this.mapped_action =
            " accessed a <span class='fw-600'>file</span> or <span class='fw-600'>folder</span>";
        } else {
          this.mapped_action = ` accessed the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
        }
        break;
      case "share_expired":
        type = DriveTypeMapping[this.metadata.added_data.type as DriveTypeMappingType];
        name = this.metadata.added_data.filename;
        if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (!type && !!this.metadata.list_info.unshared_users) {
          this.mapped_action = ` removed access to expired shared folder <span class="fw-600">${name}</span> for <span class="fw-600">${this.metadata.list_info.unshared_users.join(
            ", ",
          )}</span>`;
        } else {
          if (!!this.metadata.list_info.unshared_users) {
            this.mapped_action = ` removed access to expired shared folder <span class="fw-600">${this.metadata.list_info.unshared_users.join(
              ", ",
            )}</span>`;
          }
        }
        break;
      case "share_collection":
        name = this.metadata.added_data.filename ? this.metadata.added_data.filename : "a";
        if (!!this.metadata.list_info.roles_given && !!this.metadata.list_info.sharees) {
          fs_roles = this.metadata.list_info.roles_given.map(
            (role) => FSRole[role.toUpperCase() as keyof FSRoleMap],
          );
          const permissions_object = {
            user_id: "",
            expiration: "",
            view_only: fs_roles.includes(FSRole.VIEWER),
            pending: false,
            is_owner: false,
            acl: [], // empty array until filesync sends acl roles for v2 collections
            roles: fs_roles,
          };
          given_permissions = new NodePermissions(permissions_object);
          this.mapped_action = ` gave access to shared folder <span class="fw-600">${name}</span> to `;
          this.sub_details = `<span class="fw-600">${this.metadata.list_info.sharees.join(
            ", ",
          )}</span>`;
          if (given_permissions.type === NodePermissionType.view_only && !permissions_object.view_only) {
            try {
              if (this.metadata.list_info.roles_given.includes("sharer")) {
                this.mapped_action = ` changed permissions for <span class="fw-600">${name}</span> <span class="fw-600">folder</span> from <span class="fw-600">Edit</span> to <span class="fw-600">Edit & Share</span> for`;
                this.sub_details = `<span class="fw-600">${this.metadata.list_info.sharees.join(
                  ", ",
                )}</span>`;
              }
            } catch (error) {
              console.error(error);
              this.mapped_action = ` gave permissions for <span class="fw-600">${name}</span> <span class="fw-600">folder</span> to `;
              this.sub_details = `<span>&nbsp;</span><span class="fw-600">${this.metadata.list_info.sharees.join(
                ", ",
              )}</span>`;
            }
          } else {
            // Use try/catch in case the object hierarchy gets changed
            try {
              this.mapped_action = ` gave the following permissions for <span class="fw-600">${name}</span> <span class="fw-600">folder</span> to <span class="fw-600">${this.metadata.list_info.sharees.join(
                ", ",
              )}</span>: `;
              this.sub_details = `<span>&nbsp;</span><span class="fw-600">${this.metadata.list_info.roles_given.join(
                ", ",
              )}</span>`;
            } catch (error) {
              console.error(error);
              this.mapped_action = ` gave permissions for <span class="fw-600">${name}</span> <span class="fw-600">folder</span> to <span class="fw-600">${this.metadata.list_info.sharees.join(
                ", ",
              )}</span>`;
            }
          }
        }
        break;
      case "unshare_collection":
        name = this.metadata.added_data.filename ? this.metadata.added_data.filename : "a";
        try {
          const changes: string[] = [];
          Object.entries(this.metadata.list_info).forEach(([key, value], index) => {
            fs_roles = value.map((role) => FSRole[role.toUpperCase() as keyof FSRoleMap]);
            let change: string;
            const perm_object = {
              user_id: "",
              expiration: "",
              view_only: fs_roles.includes(FSRole.VIEWER),
              pending: false,
              is_owner: false,
              acl: [], // empty array until filesync updates the improvement by backend to send acl roles for v1 collections
              roles: fs_roles,
            };
            const revoked_permissions = new NodePermissions(perm_object);
            const undefined_permissions = revoked_permissions.type === NodePermissionType.view_only && !perm_object.view_only;
            if (!undefined_permissions) {
              change = `<span class="${index > 0 ? "with_cap" : ""
                }">unshared</span> <span class="fw-600">${name}</span> <span class="fw-600">folder</span> with <span class="fw-600">${key}</span> from <span class="fw-600">${revoked_permissions.label
                }</span> permissions`;
            } else if (_.isEqual(value, ["sharer", "owner"].sort())) {
              // Edit Share to Edit
              change = `<span class="${index > 0 ? "with_cap" : ""
                }">changed</span> permissions for <span class="fw-600">${name}</span> <span class="fw-600">folder</span> with <span class="fw-600">${key}</span> from <span class="fw-600">Edit & Share</span> to <span class="fw-600">Edit</span>`;
            } else if (
              _.isEqual(
                value.sort(),
                ["owner", "writer", "acl_viewer", "sharer", "log_viewer"].sort(),
              )
            ) {
              // Edit Share to Read Only
              change = `<span class="${index > 0 ? "with_cap" : ""
                }">changed</span> permissions for <span class="fw-600">${name}</span> <span class="fw-600">folder</span> with <span class="fw-600">${key}</span> from <span class="fw-600">Edit & Share</span> to <span class="fw-600">Read Only</span>`;
            } else {
              // Edit to Read Only
              change = `<span class="${index > 0 ? "with_cap" : ""
                }">changed</span> permissions for <span class="fw-600">${name}</span> <span class="fw-600">folder</span> with <span class="fw-600">${key}</span> from <span class="fw-600">Edit</span> to <span class="fw-600">Read Only</span>`;
            }
            changes.push(change);
          });
          this.mapped_action = changes.join(";<span>&nbsp;</span>");
        } catch (error) {
          console.error(error);
          this.mapped_action = `changed sharing permissions for <span class="fw-600">${name}</span> <span class="fw-600">shared folder</span>`;
        }
        break;
      case "acl_change":
        name = this.metadata.added_data.filename ? this.metadata.added_data.filename : "a";
        const changes = this.metadata.acl_changes;
        let roles: string[] = [];
        changes.forEach((u: { user_id: string; roles: string[] }) => {
          !!users_list && users_list.push(u.user_id);
          roles = u.roles;
        });
        acl_roles = roles.map((role) => Node.ACLRole[role.toUpperCase() as keyof Node.ACLRoleMap]);
        fs_roles = roles.map((role) => FSRole[role.toUpperCase() as keyof FSRoleMap]);
        given_permissions = new NodePermissions({
          user_id: "",
          expiration: "",
          view_only: fs_roles.includes(FSRole.VIEWER),
          pending: false,
          is_owner: false,
          acl: acl_roles,
          roles: fs_roles,
        });
        this.mapped_action = `shared sub-folder <span class="fw-600">${name}</span> and granted <span class="fw-600">${given_permissions.label}</span> permissions with `;
        this.sub_details = `<span class="fw-600">${users_list.join(", ")}</span>`;
        break;
      case "rename_collection":
        this.mapped_action = `renamed the collection <span class="fw-600">${this.metadata.added_data.filename}</span>`;
        break;
      case "update_collection":
        const operation = this.metadata.updates[0].op;
        type =
          this.metadata.version > 0
            ? DriveLogTypeMapping[this.metadata.added_data.type as DriveLogEntryType]
            : DriveTypeMapping[this.metadata.added_data.type as DriveTypeMappingType];
        name = this.metadata.added_data.filename;
        if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (!type) {
          this.tooltip = "Name and path not available";
        }
        if (operation === "ACL") {
          this.mapped_action = `made the subfolder <span class="fw-600">${name}</span> shareable`;
        } else if (operation === "RENAME") {
          if (!type) {
            this.mapped_action =
              "renamed a <span class='fw-600'>file</span> or <span class='fw-600'>folder</span>";
          } else if (
            this.metadata.info.number_of_updates &&
            this.metadata.info.number_of_updates > 0
          ) {
            this.mapped_action = `renamed the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
            this.sub_details = `<span>&nbsp;</span><span class="fw-600">${this.metadata.info.number_of_updates}</span> times`;
          } else {
            this.mapped_action = `renamed the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
          }
        } else if (operation === "ALTER") {
          if (!type) {
            this.mapped_action =
              "updated a <span class='fw-600'>file</span> or <span class='fw-600'>folder</span>";
          } else if (
            this.metadata.info.number_of_updates &&
            this.metadata.info.number_of_updates > 0
          ) {
            this.mapped_action = `updated the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
            this.sub_details = `<span>&nbsp;</span><span class="fw-600">${this.metadata.info.number_of_updates}</span> times`;
          } else {
            this.mapped_action = `updated the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
          }
        } else if (operation === "MOVE") {
          if (!type) {
            this.mapped_action =
              "moved a <span class='fw-600'>file</span> or <span class='fw-600'>folder</span>";
          } else if (
            this.metadata.info.number_of_updates &&
            this.metadata.info.number_of_updates > 0
          ) {
            this.mapped_action = `moved the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
            this.sub_details = `<span>&nbsp;</span><span class="fw-600">${this.metadata.info.number_of_updates}</span> times`;
          } else {
            this.mapped_action = `moved the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
          }
        } else {
          if (!type) {
            this.mapped_action =
              "edited a <span class='fw-600>file</span> or <span class='fw-600'>folder</span>";
          } else if (
            this.metadata.info.number_of_updates &&
            this.metadata.info.number_of_updates > 0
          ) {
            this.mapped_action = `edited the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
            this.sub_details = `<span>&nbsp;</span><span class="fw-600">${this.metadata.info.number_of_updates}</span> times`;
          } else {
            this.mapped_action = `edited the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
          }
        }
        break;
      case "create_organization":
        this.mapped_action = `created the organization <span class="fw-600">${this.metadata.info.display_name}</span> with a <span class="fw-600 with_cap">${this.metadata.info.plan}</span> plan`;
        break;
      case "invite_users_to_org":
        this.mapped_action = `invited <span class="fw-600">users</span> to <span class="fw-600">${this.metadata.info.entity_name}</span>: `;
        this.relevant_users = this.metadata.users.map(
          (user: { display_name: string; user_id: string }) => {
            return { name: user.display_name, address: user.user_id };
          },
        );
        break;
      case "delete_user":
        this.relevant_users = this.metadata.users.map(
          (user: { display_name: string; user_id: string }) => {
            return { name: user.display_name, address: user.user_id };
          },
        );
        this.mapped_action = "deleted <span class='fw-600'>users</span>: ";
        break;
      case "update_account":
        this.mapped_action = "updated <span class='fw-600'>user</span>";
        this.relevant_users = [
          {
            name: this.metadata.users[0].display_name,
            address: this.metadata.users[0].user_id,
          },
        ];
        let update_string = "<span>&nbsp;</span>";
        if (!this.metadata.info) {
          break;
        }
        if (this.metadata.info.new_department) {
          update_string += `department to <span class="fw-600">${this.metadata.info.new_department}</span> from <span class="fw-600">${this.metadata.info.previous_department}</span>`;
        }
        if (this.metadata.info.new_department && this.metadata.info.new_role) {
          update_string += " and ";
        }
        if (this.metadata.info.new_role) {
          update_string += `role to <span class="fw-600 with_cap">${this.metadata.info.new_role}</span> from <span class="fw-600 with_cap">${this.metadata.info.previous_role}</span>`;
        }
        this.sub_details = update_string;
        break;
      case "create_approval_group":
        if (!!this.metadata.approval_group[0].approvers) {
          this.mapped_action = `created the Approval Group <span class="fw-600">${this.metadata.approval_group[0].name}</span> with <i>${this.metadata.approval_group[0].approvers.length}</i> approvers`;
        }
        break;
      case "apply_approval_group":
        user_names = this.metadata.users
          .map((user: { display_name: string }) => user.display_name)
          .join(", ");
        this.mapped_action = `applied the Approval Group <span class="fw-600">${this.metadata.info.approval_group_name}</span> to <i>${user_names}</i>`;
        break;
      case "rekey_and_set_approval_group":
        this.mapped_action = `${this.user_id.toLowerCase() === this.uid.toLowerCase() ? " were" : " was"} assigned an approval group`;
        break;
      case "claim_account":
        this.mapped_action = this.is_admin ? "claimed their account" : "claimed your account";
        break;
      case "invite_to_preveil":
        this.mapped_action = `invited <span class="fw-600">${this.metadata.info.invitee_email}</span> to PreVeil`;
        break;
      case "invitee_joined_preveil":
        this.mapped_action = "invited";
        this.relevant_users = [
          { name: this.metadata.users[0].display_name, address: this.metadata.users[0].user_id },
        ];
        this.sub_details = "to PreVeil and they have joined.";
        break;
      case "create_group":
        this.mapped_action = `created the group <span class="fw-600">${this.metadata.info.display_name}</span>`;
        break;
      case "share_group_key":
        this.mapped_action = "shared their group key with ";
        this.relevant_users = [
          { name: this.metadata.users[0].display_name, address: this.metadata.users[0].user_id },
        ];
        break;
      case "begin_account_recovery":
        this.mapped_action = this.is_admin
          ? "started recovering their account"
          : "Started recovering your account";
        break;
      case "retrieve_users_shard":
        this.mapped_action = "approved recovery for";
        this.relevant_users = [
          { name: this.metadata.users[0].display_name, address: this.metadata.users[0].user_id },
        ];
        break;
      case "rg_member_retrieved_shard":
        if (this.is_admin) {
          this.mapped_action = "was approved recovery by";
        } else {
          this.sub_details = "approved recovering your account";
        }
        this.relevant_users = [
          { name: this.metadata.users[0].display_name, address: this.metadata.users[0].user_id },
        ];
        break;
      case "set_approval_group":
        if (!!this.metadata.approval_group[0].approvers) {
          user_names = this.metadata.approval_group[0].approvers
            .map((user: { user_id: string }) => user.user_id)
            .join(", ");
          this.mapped_action = `have your recovery group set with the following approvers: <span class="fw-600">${user_names}</span>`;
        }
        break;
      case "added_to_approval_group":
        this.relevant_users = [this.actor];
        this.actor = { name: this.metadata.users[0].display_name, address: this.metadata.users[0].user_id };
        this.mapped_action = "has been assigned to an approval group with ";
        this.sub_details = " as one of the approvers";
        break;
      case "share_response":
        name = this.metadata.added_data.filename;

        this.mapped_action = `responded to a share invitation for <span class="fw-600">${name}</span> directory.`;
        if (this.metadata.info) {
          this.mapped_action = `${this.metadata.info.accept === "true" ? "accepted" : "rejected"
            } invitation from <span class="fw-600">${this.metadata.info.sharer_user_id}</span>`;
        }
        break;
      case "rename_file":
        name = this.metadata.added_data.filename;
        if (name === "") {
          this.tooltip = "Name and path not available";
        } else if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (this.metadata.nodes && this.metadata.nodes.length > 1) {
          this.mapped_action = `renamed <span class="fw-600">${this.metadata.nodes.length} files</span>`;
        } else {
          if (name === "") {
            this.mapped_action = "renamed a <span class='fw-600'>file</span>";
          } else {
            this.mapped_action = `renamed the file <span class="fw-600">${name}</span>`;
          }
        }
        break;
      case "move_file":
        name = this.metadata.added_data.filename;
        if (name === "") {
          this.tooltip = "Name and path not available";
        } else if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (this.metadata.nodes && this.metadata.nodes.length > 1) {
          this.mapped_action = `moved <span class="fw-600">${this.metadata.nodes.length} files</span>`;
        } else {
          this.mapped_action = "moved a <span class='fw-600'>file</span>";
        }
        break;
      case "rename_directory":
        name = this.metadata.added_data.filename;
        if (name === "") {
          this.tooltip = "Name and path not available";
        } else if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (this.metadata.nodes && this.metadata.nodes.length > 1) {
          this.mapped_action = `renamed <span class="fw-600">${this.metadata.nodes.length} folders</span>`;
        } else {
          if (name === "") {
            this.mapped_action = "renamed a <span class='fw-600'>folder</span>";
          } else {
            this.mapped_action = `renamed the folder <span class="fw-600">${name}</span>`;
          }
        }
        break;
      case "move_directory":
        name = this.metadata.added_data.filename;
        if (name === "") {
          this.tooltip = "Name and path not available";
        } else if (this.metadata.added_data.path && this.metadata.added_data.path.length > 0) {
          this.tooltip = this._mapPath(this.metadata.added_data.path);
        }
        if (this.metadata.nodes && this.metadata.nodes.length > 1) {
          this.mapped_action = `moved <span class="fw-600">${this.metadata.nodes.length} folders</span>`;
        } else {
          if (name === "") {
            this.mapped_action = "moved a <span class='fw-600'>folder</span>`";
          } else {
            this.mapped_action = `moved the folder <span class="fw-600">${name}</span>`;
          }
        }
        break;
      case "respond_to_share_invitation":
        name = this.metadata.added_data.filename;
        this.mapped_action = "responded to a share invitation";
        if (this.metadata.list_info.roles) {
          fs_roles = this.metadata.list_info.roles.map(
            (role) => FSRole[role.toUpperCase() as keyof FSRoleMap],
          );
          given_permissions = new NodePermissions({
            user_id: "",
            expiration: "",
            view_only: fs_roles.includes(FSRole.VIEWER),
            pending: false,
            is_owner: false,
            acl: [], // empty array until filesync updates the improvement by backend to send acl roles for v1 collections
            roles: fs_roles,
          });
          this.mapped_action = `
              <span class="fw-600">${this.metadata.info.accept === "true" ? "accepted" : "rejected"
            }</span> <span class="fw-600">${name}</span> <span class="fw-600">shared folder</span>
              invitation from <span class="fw-600">${this.metadata.info.sharer_user_id}</span>
              with <span class="fw-600">${this.metadata.info.view_only === "false" && given_permissions.type !== NodePermissionType.view_only
              ? given_permissions.label
              : "Access"
            }</span> permissions`;
        }
        break;
      case "register_device":
        const device_metadata = this.metadata.devices[0];
        this.mapped_action = `
            <span class="fw-600">registered</span>
            the device <span class="fw-600">${device_metadata.device_name}</span>`;
        this.tooltip = device_metadata.platform;
        break;
      case "lock_device":
        const locked_device_metadata = this.metadata.devices[0];
        const locking_device_metadata = this.metadata.devices[1];
        this.mapped_action = `
            <span class="fw-600">locked</span>
            the device <span class="fw-600">${locked_device_metadata.device_name}</span>`;
        this.tooltip = locked_device_metadata.platform;
        if (locking_device_metadata) {
          this.sub_details = `from <span class="fw-600">${locking_device_metadata.device_name}</span>`;
        }
        break;
      case "unlock_device":
        const unlocked_device_metadata = this.metadata.devices[0];
        const unlocking_device_metadata = this.metadata.devices[1];
        this.mapped_action = `
            <span class="fw-600">unlocked</span>
            the device <span class="fw-600">${unlocked_device_metadata.device_name}</span>`;
        this.tooltip = unlocked_device_metadata.platform;
        if (unlocking_device_metadata) {
          this.sub_details = `from <span class="fw-600">${unlocking_device_metadata.device_name}</span>`;
        }
        break;
      case "delete_device":
        this.mapped_action = "<span class='fw-600'>deleted a device</span>";
        for (const device of this.metadata.devices) {
          if (device.is_active === false) {
            this.mapped_action = `<span class="fw-600">deleted</span> the device <span class="fw-600">${device.device_name}</span>`;
            break;
          }
        }
        break;
      case "delete_pending_approval":
        this.mapped_action = " deleted a pending approval";
        break;
      case "delete_approval_request":
        this.mapped_action = " deleted an approval request";
        break;
      case "approval_requested":
        this.mapped_action = " requested an approval";
        break;
      case "create_approval_request":
        action_string = "created an approval request";
        const ag_req = this.metadata.ag_request[0];
        this.mapped_action =
          ag_req.type === "export"
            ? action_string.concat(this._parse_ag_request_payload(ag_req.payload))
            : action_string;
        break;
      case "responded_to_request":
        this.mapped_action = `<span class="fw-600">${this.metadata.info.response}</span> a user request`;
        break;
      case "approver_responded_to_request":
        this.mapped_action = `<span class="fw-600">${this.metadata.info.response}</span> an admin approval request`;
        break;
      case "set_org_approval_group_role":
        this.mapped_action = `assigned an approval group to the <span class="fw-600">${AdminApprovalGroupRoleMapping[
          this.metadata.info.role as "admin_approval_group" | "export_approval_group"
        ].name
          }</span> activity`;
        break;
      case "add_to_whitelist":
        domains = this.metadata.list_info.domains;
        users_list = this.metadata.list_info.users;
        action_string = "added the following to the Trusted Community: ";
        !!users_list && users_list.forEach((user, index) => {
          action_string = action_string.concat(`<span class="fw-600">${user}</span>`);
          if (!!users_list && index < users_list.length - 1) {
            action_string = action_string.concat(", ");
          }
        });
        if (!!domains && !!users_list && domains.length > 0 && users_list.length > 0) {
          action_string = action_string.concat(", ");
        }
        if (!!domains) {
          domains.forEach((domain, index) => {
            action_string = action_string.concat(`<span class="fw-600">${domain}</span>`);
            if (!!domains && index < domains.length - 1) {
              action_string = action_string.concat(", ");
            }
          });
        }
        this.mapped_action = action_string;
        break;
      case "remove_from_whitelist":
        domains = this.metadata.list_info.domains;
        users_list = this.metadata.list_info.users;
        action_string = "removed the following from the Trusted Community: ";
        !!users_list && users_list.forEach((user, index) => {
          action_string = action_string.concat(`<span class="fw-600">${user}</span>`);
          if (!!users_list && index < users_list.length - 1) {
            action_string = action_string.concat(", ");
          }
        });
        if (!!domains && !!users_list && domains.length > 0 && users_list.length > 0) {
          action_string = action_string.concat(", ");
        }
        !!domains && domains.forEach((domain, index) => {
          action_string = action_string.concat(`<span class="fw-600">${domain}</span>`);
          if (!!domains && index < domains.length - 1) {
            action_string = action_string.concat(", ");
          }
        });
        this.mapped_action = action_string;
        break;
      case "enable_whitelist":
        this.mapped_action = `<span class="fw-600">${this.metadata.info.set_active === "true" ? "enabled" : "disabled "
          }</span> the <span class="fw-600">Trusted Community</span>`;
        break;
      case "enable_selective_sync": // only log Sync On events
        if (this.metadata.nodes && this.metadata.nodes.length > 0) {
          if (this.metadata.added_data.filename !== "") {
            this.mapped_action = `set to <span class='fw-600'>Sync On</span> the folder <span class="fw-600">${this.metadata.added_data.filename}</span>`;
          } else { // for collections that the admins don't have access to
            this.mapped_action = "synced a <span class='fw-600'>directory</span>";
          }
        } // synced less nodes than before, ignore this event
        break;
      case "toggle_add_devices":
        this.mapped_action = `${this.metadata.info.is_allowed === "true"
          ? "<span class='fw-600'>enabled</span> the entire organization to add devices"
          : "<span class='fw-600'>disabled</span> the entire organization from adding devices"
          }`;
        break;
      case "override_user_add_device":
        this.mapped_action = `${this.metadata.info.is_allowed === "true"
          ? "<span class='fw-600'>enabled</span> the following user to add devices: "
          : "<span class='fw-600'>disabled</span> the following user from adding devices: "
          }
        </span>`;
        if (this.metadata.users.length > 0) {
          this.relevant_users = this.metadata.users.map(
            (user: { display_name: string; user_id: string }) => {
              return { name: user.display_name, address: user.user_id };
            },
          );
        } else {
          // Edge case, though unlikely
          this.mapped_action = this.mapped_action.concat("Unspecified");
        }
        break;
      case "begin_export":
        action_string = "started a data export process";
        const ag_request = this.metadata.ag_request[0];
        this.mapped_action = action_string.concat(
          this._parse_ag_request_payload(ag_request.payload),
        );
        break;
      case "global_sync_change":
        this.mapped_action = `<span class="fw-600">${this.metadata.info.sync === true ? "disabled" : "enabled "
          }</span> the <span class="fw-600">Cloud Lock</span>`;
        break;
      case "global_sync_exceptions_change":
        let user_ids = "";
        if (this.metadata.list_info.user_ids) {
          user_ids = this.metadata.list_info.user_ids.join(", ");
        }
        this.mapped_action = `<span class="fw-600">${this.metadata.info.sync === true ? "added" : "removed "
          }</span> <span class="fw-600">${user_ids}</span> <span class="fw-600">${this.metadata.info.sync === true ? "to" : "from "
        }</span> the <span class="fw-600">Cloud Lock Exceptions List</span>`;
        break;
      default:
        this.mapped_action = this.action;
    }
  }

  private _parse_ag_request_payload(payload_data: string): string {
    const payload = JSON.parse(payload_data);
    const log_type: string[] = [];
    let str = "";
    if (payload.data.include_emails) {
      log_type.push(`<span class="fw-600">${ExportOption.email}</span>`);
    }
    if (payload.data.include_files) {
      log_type.push(`<span class="fw-600">${ExportOption.drive}</span>`);
    }
    if (payload.data.include_logs) {
      log_type.push(`<span class="fw-600">${ExportOption.logs}</span>`);
    }
    if (payload.data.include_org_acl_report) {
      log_type.push(`<span class="fw-600">${ExportOption.acl_report}</span>`);
    }
    str += " for exporting ";
    str = str.concat(log_type.join(" & "));
    str += " for ";

    const users: Array<{ user_id: string; key_version: number }> = payload.data.users;
    users.forEach((user, index) => {
      str = str.concat(`<span class="fw-600">${user.user_id}</span>`);
      if (index < users.length - 1) {
        str = str.concat(", ");
      }
    });
    return str;
  }
  private _mapPath(path: Array<{ path: string }>): string {
    return (
      path
        .map((segment) => (segment.path === DefaultCollectionName ? "My PreVeil" : segment.path))
        .join(" / ") + " /"
    );
  }

  public mapAccessFileMessage(type: string, name: string, access_type?: string): string {
    if (!access_type) {
      return `accessed the <span class="lower-case">${type}</span> <span class="log-field">${name}</span>`;
    }
    switch (access_type) {
      case "view":
        return `viewed the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
      case "download_to_preveil":
        return `downloaded the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span> to PreVeil Downloads`;
      case "download_to_browser":
        return `downloaded the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span> to the local file system`;
      case "cloud_edit":
        return `cloud-edited the <span class="lower-case">${type}</span> <span class="fw-600d">${name}</span>`;
      case "sync":
        return `synced the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
      default:
        return `accessed the <span class="lower-case">${type}</span> <span class="fw-600">${name}</span>`;
    }
  }

  public getActivityLogInfo(): ActivityLogInfo {
    return {
      uid: this.uid,
      formatted_timestamp: this.formatted_timestamp,
      mapped_action: this.mapped_action,
      relevant_users: this.relevant_users,
      sub_details: this.sub_details,
      tooltip: this.tooltip,
      collection_id: this.collection_id,
      topic: this.topic,
      action: this.action,
      device: this.device,
      global_idx: this.global_idx,
      link: this.link,
      actor: this.actor,
    };
  }
}
