import React from "react";
import { ActionHandlerFunction, UserProfile } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Icon } from "src/components";
import { Button, Row, Col } from "react-bootstrap";

type AllProps = {
  user: UserProfile;
  handleActions: ActionHandlerFunction;
};

function CloudLockUserInfo(props: AllProps) {
  const { user, handleActions } = props;
  const { name, address: user_id, role } = user;
  const formatted_user_id = user_id.toLowerCase();
  return (
    <Row className="align-items-center">
      <Col xs={7}>
        <p className="semi-bold">{name}</p>
        <p>{formatted_user_id}</p>
      </Col>
      <Col xs={4} className="ps-1 text-capitalize">
        <p className="m-0">{role}</p>
      </Col>
      <Col xs={1}>
        <Button
          variant="icon"
          size="sm"
          title="Delete User Group"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Delete User Group"
          onClick={() =>
            handleActions &&
            handleActions({ actionType: AdminUIActionTypes.Delete, params: [user_id] })
          }
        >
          <Icon className="ficon-x" />
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(CloudLockUserInfo);