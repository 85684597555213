import React, { useState, useRef, useEffect } from "react";
import { ActionHandlerFunction, CollectionServerUser, IActionHandlerTyped, UserListData, UserProfile, IActionHandler } from "@preveil-api";
import { UserList, Icon } from "src/components";
import { Account, AdminUIActionTypes, AdminWarningMessages, UserListActionTypes } from "src/common";
import { UsersExceptionDetailsCard } from "..";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";

type AllProps = {
  current_users_exception_list: string[];
  current_account: Account;
  show_modal: boolean;
  org_users: CollectionServerUser[];
  handleAction: ActionHandlerFunction;
}

function AddUsersExceptionListModal(props: AllProps) {
  const { current_users_exception_list, current_account, show_modal, org_users, handleAction } = props;

  // Local State
  const [ saved_users_exception_list, setCurrentUsersSyncPermissions ] = useState<CollectionServerUser[]>([]);
  const [ current_selected_users_list, setCurrentSelectedUsersList ] = useState<UserProfile[]>([]);
  const user_list_ref = useRef<{ handlePageActions: ActionHandlerFunction }>();

  const is_button_disabled = current_selected_users_list.length === 0;

  useEffect(() => {
    if (org_users.length > 0) {
      const existing_users_with_sync_permissions = org_users
        .filter((user) => !current_users_exception_list.includes(user.user_id));
      setCurrentUsersSyncPermissions(existing_users_with_sync_permissions);
    }
  }, [current_users_exception_list, org_users]);

  function handleShowModal() {
    handleAction({ actionType: AdminUIActionTypes.ShowModal });
    reset();
  }

  // Description: This function is used to update the current selected users list
  function handleUpdateUsers(actionObj: IActionHandlerTyped<UserListData>) {
    const params = actionObj.params;
    const { current_contacts } = params;
    setCurrentSelectedUsersList(current_contacts);
  }

  // Description: This function is used to remove a user from the current selected users list
  function handleRemoveUser(user: UserProfile) {
    if (!!user_list_ref.current) {
      user_list_ref.current.handlePageActions({
        actionType: UserListActionTypes.RemoveProfile,
        params: user,
      });
    }
  }

  // Description: This function is used to reset the current selected users list in the modal only
  function reset() {
    if (!!user_list_ref.current) { 
      user_list_ref.current.handlePageActions({ actionType: UserListActionTypes.Reset });
    }
    setCurrentSelectedUsersList([]);
  }

  // Description: Object containing the functions to handle the delete and save actions
  const AddUsersExceptionListRequests = {
    handleDelete: (param: string[]) => {
      const [user_id] = param;
      const user_to_remove = current_selected_users_list.filter((user) => user.address === user_id)[0];
      handleRemoveUser(user_to_remove);
    },
    handleSave: () => {
      const users_to_add = current_selected_users_list.map((user) => user.address);
      handleAction({ actionType: AdminUIActionTypes.AddUserIdToSyncPermissionList, params: users_to_add });
    }
  };

  function saveUsersToSyncPermissionList() { 
    const users_to_add_to_permission_list = current_selected_users_list.map((user) => user.address);
    handleAction({ actionType: AdminUIActionTypes.AddUserIdToSyncPermissionList, params: users_to_add_to_permission_list });
    reset();
  }
  
  // Description: Handle actions at the page level.
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((AddUsersExceptionListRequests as any)[callback] instanceof Function) {
      (AddUsersExceptionListRequests as any)[callback](actionObj.params);
    }
  }

  return (
    <Modal
      size="lg"
      show={show_modal}
      onHide={handleShowModal}
      aria-labelledby="users exception list"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Sync Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="enter_users">
            <Tab eventKey="enter_users" title="Add Users">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-1">Targeted users can be granted sync permissions below:</p>
                <Icon
                  tooltip={AdminWarningMessages.admin_sync_cloud_lock}
                  className="icon-warning"
                />
              </div>
              <UserList
                ref={user_list_ref}
                current_account={current_account}
                handleAction={handleUpdateUsers}
                hide_badges={true}
                org_users={saved_users_exception_list}
                user_ids={current_selected_users_list.map((user) => user.address)}
                placeholder="Start typing to add a user"
                show_external_users={false}
              />
              { current_selected_users_list.length > 0 && (
                <UsersExceptionDetailsCard
                  selected_users_sync_permission={current_selected_users_list}
                  handleAction={handlePageActions}
                />
              )}
              <div className="btn-panel mt-4">
                <Button disabled={is_button_disabled} type="submit" onClick={saveUsersToSyncPermissionList}>Save</Button>
                <Button variant="no-outline-primary" onClick={reset} disabled={is_button_disabled}>Reset</Button>
                <Button variant="no-outline-primary" onClick={handleShowModal}>Cancel</Button>
              </div>
            </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
  );
}

export default React.memo(AddUsersExceptionListModal);