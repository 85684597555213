import React, { useState, useEffect, FocusEvent } from "react";
import { Card } from "react-bootstrap";
import { IActionHandler, OrgInfo, CollectionServerUser } from "@preveil-api";
import {
  AdminMessages, CheckboxStates, Message, useAppDispatch, useAppSelector, CheckboxStatesTypes, MessageHandlerDisplayType, MessageToastTypes, MessageAnchors,
  useGetOrgInfo, Account, collectionApi, usePutToggleCloudOnlyModeMutation, usePostUsersOrgsByEntityIdAddGlobalSyncExceptionMutation,
  useDeleteUsersOrgsByEntityIdGlobalSyncExceptionsMutation, useGetUsersOrgsByEntityIdGlobalSyncExceptionsQuery, AdminSuccessMessages,
  AdminErrorMessages
} from "src/common";
import { PageHeader, AlertWithSwitch, Loading } from "src/components";
import { AddUsersExceptionListModal, CloudLockSettingsDeactivatedView, CloudLockSettingsActivatedView } from ".";
import { RootState } from "src/store/configureStore";
import { uiActions, accountActions } from "src/store";
import _ from "lodash";

type AllProps = {
  account: Account;
  org_info: OrgInfo;
}

function CloudLockSettings(props: AllProps) {
  const dispatch = useAppDispatch();
  const { account, org_info } = props;
  const new_global_sync_change = useAppSelector((state: RootState) => state.app.new_global_sync_change);
  const global_local_sync = useAppSelector((state: RootState) => state.account.global_local_sync);

  // Local State
  const [org_users, setOrgUsers] = useState<CollectionServerUser[]>([]);
  const [cloud_lock_status, setCloudLockStatus] = useState<boolean>(false);
  const [show_modal, setShowModal] = useState<boolean>(false);
  const [filter_param, setFilterParam] = useState<string>("");
  const [selected_users_exception_list, setSelectedUsersExceptionList] = useState<string[]>([]);
  const [is_loading, setIsLoading] = useState<boolean>(true);

  // Hooks
  const { data: org_data, error, refresh } = useGetOrgInfo(account, org_info);
  const [getCloudOnlyOrgLockState] = collectionApi.endpoints.getCloudOnlyOrgLockState.useLazyQuery();
  const [putToggleCloudOnlyMode] = usePutToggleCloudOnlyModeMutation();
  const [postUsersOrgsByEntityIdAddGlobalSyncException] = usePostUsersOrgsByEntityIdAddGlobalSyncExceptionMutation();
  const [deleteUsersOrgsByEntityIdGlobalSyncExceptions] = useDeleteUsersOrgsByEntityIdGlobalSyncExceptionsMutation();
  const { data: saved_users_sync_permission, error: saved_users_sync_error, isFetching: is_fetching_users_list, isLoading: is_loading_data, refetch } = useGetUsersOrgsByEntityIdGlobalSyncExceptionsQuery(
    {
      account_ids: Account.getAccountIdentifiers(account),
      body: {
        entityId: org_info.org_id
      }
    }
  );
  
  const exception_users_list = saved_users_sync_permission && saved_users_sync_permission.users.length >= 1 ? saved_users_sync_permission.users : [];

  useEffect(() => {
    refetch();
    return refresh({
      history: false,
      status: "pending",
      hideExpired: true,
      limit: 200
    });
  }, []);

  useEffect(() => {
    // Description: On First load of the component we want to get the cloud lock state of the organization
    // If the global_local_sync is false then we want to set the cloud lock switch toggle to TRUE
    // next notifications/changes will be handled by the new_global_sync_change flag.
    if (global_local_sync === false && !cloud_lock_status) {
      setCloudLockStatus(true);
    }

    if (saved_users_sync_error) {
      const params = {
        message: AdminErrorMessages.error_fetching_sync_permission_list,
        stack: saved_users_sync_error
      };
      dispatchPageError(params);
    }
    !is_loading_data && setIsLoading(false);
  }, [global_local_sync, saved_users_sync_error, is_loading_data]);

  useEffect(() => {
    if (new_global_sync_change) {
      fetchCloudOnlyOrgLockState();
    }
  }, [new_global_sync_change]);

  useEffect(() => {
    if (!_.isEmpty(org_data) && org_data.org_users) {
      const { org_users: users } = org_data;
      const org_users = users.filter((user: CollectionServerUser) => {
        // In org_info.users array a deleted user is marked as claimed and deleted
        // so checking just in case at some point org_users also have deleted users.
        return user.claimed && !user.deleted;
      });
      setOrgUsers(org_users);
    }
    if (error) {
      const params = {
        message: AdminErrorMessages.error_fetching_org_info,
        stack: error
      };
      dispatchPageError(params);
    }
  }, [org_data, error]);

  function filterExceptionListUsers(param: string) {
    return exception_users_list.filter((user_id: string) => {
      return user_id.toLowerCase().includes(param.toLowerCase());
    });
  }

  // Description: dispatch any error found in the page.
  function dispatchPageError(params: { message: string, stack?: any }) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  }

  // Description: Toggle the cloud lock mode on/off
  async function toggleCloudModeLockRequest() {
    const sync = cloud_lock_status; // We already flipped the status at first load so we can just pass it as it is.
    const account_ids = Account.getAccountIdentifiers(account);
    const entityId = org_info.org_id;
    const params = {
      account_ids,
      body: {
        entityId,
        sync
      }
    };
    await putToggleCloudOnlyMode(params)
      .unwrap()
      .then(() => {
        const message = AdminSuccessMessages.success_cloud_lock_setting_change;
        // We don't get any response at this point as to what is the state of the cloud lock (response.sync),
        // so we just flipped the current state again.
        setCloudLockStatus(!sync);
        setIsLoading(false);
        dispatch(uiActions.handleSetMessage(new Message(message)));
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_cloud_lock_setting_change,
          stack: error
        };
        setIsLoading(false);
        dispatchPageError(params);
      });
  }

  // Description: Add users to the global sync exception list
  async function addUsersToCloudLockExceptionList(users: string[]) {
    if (users.length > 0) {
      const messageTemplate = users.length === 1 ? "user" : "users";
      const account_ids = Account.getAccountIdentifiers(account);
      const entityId = org_info.org_id;
      const params = {
        account_ids,
        body: {
          entityId,
          users
        }
      };
      await postUsersOrgsByEntityIdAddGlobalSyncException(params)
        .unwrap()
        .then(() => {
          const message = AdminSuccessMessages.success_add_user_cloud_lock_permission.replace(MessageAnchors.message_content, messageTemplate);
          dispatch(uiActions.handleSetMessage(new Message(message)));
        })
        .catch((error) => {
          const message = AdminErrorMessages.error_adding_user_to_cloud_lock_permission.replace(MessageAnchors.message_content, messageTemplate);
          const params = {
            message,
            stack: error
          };
          dispatchPageError(params);
        });
    }
  }

  // Description: Remove users from the global sync exception list
  async function removeUsersFromCloudLockExceptionList(users: string[]) {
    if (users.length > 0) {
      const messageTemplate = users.length === 1 ? "user" : "users";
      const account_ids = Account.getAccountIdentifiers(account);
      const entityId = org_info.org_id;
      const params = {
        account_ids,
        body: {
          entityId,
          users
        }
      };
      await deleteUsersOrgsByEntityIdGlobalSyncExceptions(params)
        .unwrap()
        .then(() => {
          const message = AdminSuccessMessages.success_remove_user_cloud_lock_permission.replace(MessageAnchors.message_content, messageTemplate);
          setSelectedUsersExceptionList([]);
          dispatch(uiActions.handleSetMessage(new Message(message)));
        })
        .catch((error) => {
          const message = AdminErrorMessages.error_removing_user_from_cloud_lock_permission.replace(MessageAnchors.message_content, messageTemplate);
          const params = {
            message,
            stack: error
          };
          dispatchPageError(params);
        });
    }
  }

  // Description: Fetch the cloud lock state of the organization
  async function fetchCloudOnlyOrgLockState() {
    const org_id = org_info.org_id;
    getCloudOnlyOrgLockState({
      account_ids: Account.getAccountIdentifiers(account),
      body: {
        entityId: org_id
      }
    })
      .unwrap()
      .then((response) => {
        const { sync } = response;
        setCloudLockStatus(!sync); // SET CLOUD LOCK STATUS
        dispatch(accountActions.setGlobalLocalSync(sync)); // SET LOCAL SYNC STATE
      }); // Catch here
  }

  // Description: Handle the toggle of the cloud lock mode
  function handleToggleCloudModeLock() {
    if (!cloud_lock_status) {
      // Note: we want to confirm with the admin if they want to enable cloud lock
      confirmCloudLockSetting();
    } else {
      // No need to confirm when toggling off the cloud lock.
      toggleCloudModeLockRequest();
    }
  }

  // Description: Handle the selection of a single user
  function singleSelectUser(params: { e: FocusEvent<HTMLInputElement>, id: string }) {
    const { e, id } = params;
    const { checked } = e.target;
    if (checked) {
      setSelectedUsersExceptionList([...selected_users_exception_list, id]);
    } else if (!checked) {
      setSelectedUsersExceptionList(selected_users_exception_list.filter((user_id) => user_id !== id));
    }
  }

  // Description: Handle the selection of multiple users
  function multiSelectAddress(e: FocusEvent<HTMLInputElement>) {
    const { checked } = e.target;
    if (checked) {
      setSelectedUsersExceptionList([...filtered_users_exception_list]);
    } else if (!checked) {
      setSelectedUsersExceptionList([]);
    }
  }

  // Description: Confirm the cloud lock setting change, before making the request
  // This is to prevent accidental changes to the cloud lock setting.
  function confirmCloudLockSetting() {
    const confirmation_dialog = new Message(
      AdminMessages.sync_setting_change.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.sync_setting_change.title,
      {
        label: "Yes",
        data: true,
        action: () => toggleCloudModeLockRequest()
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  // Description: Confirm the deletion of users from the sync permission list
  function confirmDeleteUsers(users: string[]) {
    const is_multiple_users = users.length > 1;
    const message_body = AdminMessages.cloud_lock_remove_user.body.replace(MessageAnchors.message_content, is_multiple_users ? "users" : "user");
    const confirmation_dialog = new Message(
      message_body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.cloud_lock_remove_user.title,
      {
        label: "Yes",
        data: users,
        action: (users: string[]) => removeUsersFromCloudLockExceptionList(users)
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  const CloudLockSettingRequests = {
    handleShowModal: () => {
      setShowModal(!show_modal);
    },
    handleGetRefreshRequest: () => {
      // Action from refresh button on toolbar
      refetch();
    },
    handleReset: () => {
      setFilterParam("");
    },
    handleSingleSelect: (params: { e: FocusEvent<HTMLInputElement>, id: string }) => {
      singleSelectUser(params);
    },
    handleMultiSelect: (e: FocusEvent<HTMLInputElement>) => {
      multiSelectAddress(e);
    },
    handleSearchParam: (param: string) => {
      setFilterParam(param);
    },
    handleAddUserIdToSyncPermissionList: (users: string[]) => {
      setShowModal(false);
      addUsersToCloudLockExceptionList(users);
    },
    handleDeleteUserIdFromSyncPermissionList: (params: { users: string[] }) => {
      const { users } = params;
      confirmDeleteUsers(users);
    },
    handleDelete: () => {
      // Handle delete action from toolbar (delete all selected users)
      confirmDeleteUsers(selected_users_exception_list);
    }
  };

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((CloudLockSettingRequests as any)[callback] instanceof Function) {
      (CloudLockSettingRequests as any)[callback](actionObj.params);
    }
  }

  let select_all_checkbox_state = CheckboxStates.empty as CheckboxStatesTypes;
  if (exception_users_list.length === selected_users_exception_list.length && selected_users_exception_list.length > 0) {
    select_all_checkbox_state = CheckboxStates.checked;
  } else if (selected_users_exception_list.length < exception_users_list.length && selected_users_exception_list.length !== 0) {
    select_all_checkbox_state = CheckboxStates.indeterminate;
  }

  function RenderUi(is_lock_active: boolean, is_loading: boolean, is_fetching_users_list: boolean) {
    if (is_loading) {
      return (
        <div className="cover-content list-group">
          <div className="admin-panel-center">
            <Loading className="in-place" />
          </div>
        </div>
      );
    }
    if (is_lock_active) {
      return (
       <CloudLockSettingsActivatedView
        org_users={org_users}
        is_loading={is_fetching_users_list}
        cloud_lock_status={cloud_lock_status}
        handlePageActions={handlePageActions}
        filter_param={filter_param}
        checkbox_state={select_all_checkbox_state}
        exception_users_list={exception_users_list}
        filtered_users_exception_list={filtered_users_exception_list}
        selected_users_exception_list={selected_users_exception_list}
       />
      );
    } else if (!is_lock_active) {
      return <CloudLockSettingsDeactivatedView />;
    }
  }

  const cloud_lock_message = cloud_lock_status ? AdminMessages.cloud_lock_message.enabled : AdminMessages.cloud_lock_message.disabled;
  const filtered_users_exception_list = _.isEmpty(filter_param) ? exception_users_list : filterExceptionListUsers(filter_param);
  const popover_message = cloud_lock_status ? AdminMessages.cloud_lock_message.tooltip_on : AdminMessages.cloud_lock_message.tooltip_off;
  const disabled = is_loading;
  return (
    <div className="admin-wrapper content-wrapper">
      <PageHeader>
        <h1>Cloud Lock Settings</h1>
      </PageHeader>

      <Card className="card-section">

        <AlertWithSwitch
          popover={true}
          disabled={disabled}
          toggleState={cloud_lock_status}
          handleChange={handleToggleCloudModeLock}
          popover_message={popover_message}
        >
          <span dangerouslySetInnerHTML={{ __html: cloud_lock_message }} />
        </AlertWithSwitch>

        <AddUsersExceptionListModal
          current_users_exception_list={exception_users_list}
          current_account={account}
          show_modal={show_modal}
          handleAction={handlePageActions}
          org_users={org_users}
        />

        {RenderUi(cloud_lock_status, is_loading, is_fetching_users_list)}
      </Card>
    </div>
  );
}

export default React.memo(CloudLockSettings);