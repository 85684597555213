import { useCallback } from "react";
import { useAppDispatch, LocalAccountStorage } from "src/common";
import { accountActions, mailActions, driveActions, websocketActions, uiActions, settingsActions, adminActions } from "src/store";
import { useAuthContext } from "src/components";

export function useLogout() {
    const dispatch = useAppDispatch();
    const { setProfile } = useAuthContext();

    // Description: Completely logout user from state, sessions and cookies
    const handleStateLogout = useCallback(() => {
        dispatch(accountActions.logout());
        dispatch(mailActions.destroyMail());
        dispatch(driveActions.destroyDrive());
        dispatch(websocketActions.destroyWebsocket());
        dispatch(uiActions.destroyUIManager());
        dispatch(settingsActions.destroySettings());
        dispatch(adminActions.destroyAdmin());
        setProfile();
        LocalAccountStorage.destroyLocalStorageSessions();
        LocalAccountStorage.removeTemporaryCookies("express_recovery_not_setup", "false");
    }, []);

    return {
        handleStateLogout
    };
}