import React, { FocusEvent } from "react";
import { ActionHandlerFunction, CollectionServerUser } from "@preveil-api";
import { AdminUIActionTypes, CheckboxStatesTypes } from "src/common";
import { Row, Col } from "react-bootstrap";
import { Checkbox } from "src/components";
import { UsersExceptionListView } from ".";

type AllProps = {
  all_selected_state: CheckboxStatesTypes;
  filtered_users_exception_list: string[];
  total_users_exception_list: string[];
  selected_users_exception_list: string[];
  org_users: CollectionServerUser[];
  handleActions: ActionHandlerFunction;
}

function UsersExceptionListPanel(props: AllProps) {
  const { all_selected_state, filtered_users_exception_list, total_users_exception_list, selected_users_exception_list, org_users, handleActions } = props;
  return (
    <div className="cover-content list-group content-dynamic">
      {total_users_exception_list.length >= 1 && (
        <>
          <header>
            <Row className="users-columns-header">
              <Col sm={4} className="d-flex align-items-center">
                <Checkbox
                  className="sr-only"
                  onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.MultiSelect, params: e })}
                  value="all"
                  selected={all_selected_state}
                  label="Select all"
                  />
                <h5>Name</h5>
              </Col>
              <Col sm={4} className="ps-1">
                <h5 className="ms-0">Email</h5>
              </Col>
              <Col sm={4}>
                <h5 className="ms-0">Role</h5>
              </Col>
            </Row>
          </header>
          <UsersExceptionListView
            filtered_users_exception_list={filtered_users_exception_list}
            selected_users_exception_list={selected_users_exception_list}
            org_users={org_users}
            handleAction={handleActions}
            />
        </>
      )}
    </div>   
  );
}

export default React.memo(UsersExceptionListPanel);