import { useState, useEffect, useCallback, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, CollectionFilesyncAPI, DriveRequest, useSendRequestMutation, useAppSelector, DriveErrorMessages, useAppDispatch, MessageHandlerDisplayType,
  Message, getEnumKey, ErrorStackItem, useGetPermissions, useGetGrants, NodeIdentifier, PermissionSetType, GrantSetType, DirectoryInfo,
  getDirSymmKey, GrantSet, COLLECTION_PROTOCOL_VERSIONS
} from "src/common";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { RootState } from "src/store/configureStore";
import { uiActions, DriveCallbackAsyncFunction } from "src/store";

// Description: Fetch information about a directory. Can replace the getDirectoryInformation in hooks
export function useGetDirectoryInfo(current_account: Account) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [node_identifier, setNodeIdentifier] = useState<NodeIdentifier>();
  const [collection_protocol_version, setCollectionProtocolVersion] = useState<COLLECTION_PROTOCOL_VERSIONS>(COLLECTION_PROTOCOL_VERSIONS.V2);
  const [directory_info, setDirectoryInfo] = useState<DirectoryInfo | undefined>();
  const [error, setError] = useState<boolean>(false);
  const { permissions, getPermissions } = useGetPermissions(current_account);
  const { grants, error: grant_error, getGrants } = useGetGrants(current_account);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: Allow active permissions and default_permissions to be accessed when loaded at top level
  const default_permissions_ref = useRef<PermissionSetType[]>(default_permissions);
  useEffect(() => {
    default_permissions_ref.current = default_permissions;
  }, [default_permissions]);

  // Description: Rename if permissions and grants are set for V2 collections
  useEffect(() => {
    // NOTE: If V1 only need permissions if V2 wait for grants to be populated
    if (!!permissions && !!node_identifier) {
      (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ||
        (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 && !!grants)) &&
        getDirectoryInformation(node_identifier, permissions, grants);
    }
  }, [permissions, grants]);

  // Description: useEffect hook for when grants (from the useGetGrants hook) is initialized.
  useEffect(() => {
    grant_error && handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_grant });
  }, [grant_error]);

  // Description: Fetch directory key information and version
  async function getDirectoryInformation(node_identifier: NodeIdentifier, _permissions: PermissionSetType, _grants?: GrantSetType) {
    const request = await CollectionFilesyncAPI.getDirectoryInformationRequest(node_identifier.collection_id, node_identifier.id);
    const drive_request = !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const dir = message.getDir();
        if (!!dir) {
          const wrapped_dir_key = dir.getWrappedDirKey();
          const key_version = dir.getWrappedDirKey()?.getKeyVersion() || 0;
          const grant = !!_grants ? GrantSet.getGrantbyRole(_grants, key_version) : undefined;
          const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
          const symmetric_key = !!wrapped_dir_key ? await getDirSymmKey(wrapped_dir_key, current_account, _permissions, reader_user_key) : undefined;
          (!!wrapped_dir_key && !!symmetric_key) ?
            setDirectoryInfo({
              symmetric_key,
              wrapped_dir_key,
              version: dir.getVersion_asB64(),
              grants: _grants
            }) :
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_maintainer_directory }, message);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_maintainer_directory }, message);
        }

      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_maintainer_directory }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_maintainer_directory);
  }

  // -----------------------------
  // HOOK General
  // -----------------------------
  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>,
    stack_error: string = DriveErrorMessages.error_sending_request, user_message: string = DriveErrorMessages.default) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(user_message, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, MessageHandlerDisplayType.toastr);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useGetDirectoryInfo Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback to get directory information
  const fetchDirectoryInfo = useCallback((collection_id: string, id: string, maintainer_id?: string, collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS = COLLECTION_PROTOCOL_VERSIONS.V2) => {
    setNodeIdentifier({ collection_id, id, maintainer_id });
    setCollectionProtocolVersion(collection_protocol_version);
    getPermissions(collection_id, default_permissions_ref.current);
    (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 && !!maintainer_id) &&
      getGrants(maintainer_id, collection_id, default_permissions_ref.current, default_grants);
  }, []);

  // Description: reset hook 
  const reset = useCallback(() => {
    setError(false);
    setDirectoryInfo(undefined);
  }, []);

  return {
    directory_info,
    error,
    fetchDirectoryInfo,
    reset
  };
}