import React, { useEffect, useState } from "react";
import { IActionHandler, MessageDisplayType } from "@preveil-api";
import { FSRole, FSRoleMap, Node } from "src/common/keys/protos/collections_pb";
import {
  Account, NodePermissions, GlobalErrorMessages, Message, MessageAnchors, MessageHandlerDisplayType, useAppDispatch, useGetInvitations,
  dayjs, useGetSharedWithMeMutation, GetSharedWithMeApiResponse, DriveInvitationInfo, useShareRespond, useShareRespondMutation, ProcessingType,
  DriveErrorMessages, DriveSuccessMessages, useGetPermissions, MessageToastTypes, useAppSelector
} from "src/common";
import { PageHeader } from "src/components";
import { driveActions, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import { InvitationsToolbar, InvitationsListView } from ".";

type AllProps = {
  current_account: Account;
  is_web: boolean;
}

// Description: Drive Invitations component: Lists out the folder invites that a user has and handles fetching, relinking, rejecting
function DriveInvitationsComponent(props: AllProps) {
  const { current_account, is_web } = props;
  const global_local_sync = useAppSelector((state: RootState) => state.account.global_local_sync);
  const accepted_invites = useAppSelector((state: RootState) => state.drive.accepted_invites);
  const [shared_with_me, setSharedWithMe] = useState<DriveInvitationInfo[]>([]);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [responding, setResponding] = useState<boolean>(false);
  const { invitations, getInvitations, resetInvites, error } = useGetInvitations(current_account);
  const { respondToShareInvitation, success_responded, error_responding } = useShareRespond(current_account);
  const [getInvitationsFileSync] = useGetSharedWithMeMutation();
  const { getDefaultPermissions } = useGetPermissions(current_account);
  const [shareRespondApp] = useShareRespondMutation();
  const dispatch = useAppDispatch();

  // Description: on load - get invitations based on app configuration
  useEffect(() => {
    refresh();
    return () => {
      is_web && getDefaultPermissions(); // TO DO: need to do this for app once my preveil is done
      dispatch(uiActions.handleMessageDismiss());
    };
  }, []);

  // Description: call the set invitations function when the hook returns the invitations.
  useEffect(() => {
    if (!!invitations) {
      const _invites = !!accepted_invites ? invitations.concat(accepted_invites) : invitations;
      setSharedWithMe(_invites);
      setIsLoading(false);
      resetInvites();
    }
    if (!!error) {
      dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_fetching_invitations), error));
      resetInvites();
    }
  }, [invitations, error]);

  // Description: On change of the  Global Cloud Sync Lock by Admin settings, adjust current NodePermissions
  useEffect(() => {
    // NOTE: Only trigger refresh when current_directory and collection are loaded
    refresh();
  }, [global_local_sync]);

  // Description: On success of web hook to respond to invite
  useEffect(() => {
    if (!!success_responded) {
      const { accept, share_id, link_id, name } = success_responded;
      !accept ? handleRejectedInvite(share_id, name) : handleAcceptedInvite(share_id, name, accept, link_id);
    }
    if (!!error_responding) {
      setResponding(false);
      const invite = shared_with_me.find(invite => invite.share_id === error_responding.id);
      if (!!invite) {
        if (error_responding.while_relinking) {
          invite.processing = ProcessingType.not_processing;
          setSharedWithMe(shared_with_me.filter((folder) => folder.share_id !== invite.share_id));
          if (error_responding.conflict) {
            dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.accepted_but_conflict.replace(MessageAnchors.folder_name, invite.name), undefined, MessageToastTypes.warning)));
          } else {
            dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.accepted_but_error.replace(MessageAnchors.folder_name, invite.name))));
          }
        } else {
          dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_responding)));
        }
      }
    }
  }, [success_responded, error_responding]);

  // Description: Refreshes the Invitations page - calls different functions depending on app or web
  function refresh() {
    setIsLoading(true);
    is_web ? getInvitations() : _getInvitationsforApp();
  }

  // Description: Handles when an invite is succesfully accepted - adds it the store and sets the link_id for the navigation link.
  function handleAcceptedInvite(share_id: string, name: string, accept: boolean, link_id?: string) {
    const invite = shared_with_me.find(invite => invite.share_id === share_id);
    dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_accepting.replace(MessageAnchors.folder_name, name))));
    if (!!invite && accept && !!link_id) {
      invite.processing = ProcessingType.accepted;
      invite.link_id = link_id;
      const _accepted = !!accepted_invites ? accepted_invites?.concat(invite) : [invite];
      dispatch(driveActions.setAcceptedInvites(_accepted));
    }
  }

  // Description: Handles when an invite is succesfully rejected - removes it from the local state.
  function handleRejectedInvite(share_id: string, name: string) {
    setSharedWithMe(shared_with_me.filter((folder) => folder.share_id !== share_id));
    dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_rejecting.replace(MessageAnchors.folder_name, name))));
  }

  // Description: Get drive invitations for app (filesync)
  function _getInvitationsforApp() {
    getInvitationsFileSync({
      user_id: current_account.user_id,
    })
      .unwrap()
      .then((response: GetSharedWithMeApiResponse) => {
        setInvitations(response);
        setIsLoading(false);
      })
      .catch((error) => {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(DriveErrorMessages.error_fetching_invitations),
            error,
          ),
        );
      });
  }

  // Description: Respond to Invitation for app
  function respondToShareInvitationApp(coll_id: string, share_id: string, name: string, accept: boolean, sync: boolean) {
    shareRespondApp({
      user_id: current_account.user_id,
      coll_id, share_id, name, accept, sync
    })
      .unwrap()
      .then((response: string) => {
        !accept ? handleRejectedInvite(share_id, name) : handleAcceptedInvite(share_id, name, accept, response);
      })
      .catch((error: any) => {
        setResponding(false);
        const invite = shared_with_me.find(invite => invite.share_id === share_id);
        if (!!invite) {
          invite.processing = ProcessingType.not_processing;
          if (error.data.includes("CONFLICT")) {
            setSharedWithMe(shared_with_me.filter((folder) => folder.share_id !== invite.share_id));
            dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.accepted_but_conflict.replace(MessageAnchors.folder_name, invite.name), undefined, MessageToastTypes.warning)));
          } else {
            dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_responding)));
          }
        }
      });
  }

  // Description: Create File permissions and format expiration date.
  function setInvitations(invitations: GetSharedWithMeApiResponse): void {
    const invite_list: DriveInvitationInfo[] = invitations.map((collection) => {
      let expiration;
      if (collection.expiration) {
        const utcExpiration = new Date(collection.expiration);
        expiration = dayjs(utcExpiration).utc().local().format("MM/DD/YYYY HH:mm");
      } else {
        expiration = "--";
      }
      const roles = collection.permissions.map(role => FSRole[role.toUpperCase() as keyof FSRoleMap]);
      const acl_roles = collection.acl_roles.map(role => Node.ACLRole[role.toUpperCase() as keyof Node.ACLRoleMap]);
      const grantee = {
        user_id: current_account.user_id,
        expiration,
        view_only: collection.permissions.includes("viewer") || collection.has_acl_view_only,
        pending: false,
        is_owner: false,
        acl: acl_roles,
        roles
      };
      // NOTE: ONLY Set global_local_sync for local user
      const permissions = new NodePermissions(grantee, false, false, global_local_sync);
      return {
        collection_id: collection.collid,
        share_id: collection.shareid,
        name: collection.name,
        sharer_user_id: collection.sharerid,
        expiration,
        permissions_label: permissions.label,
        global_local_sync: permissions.global_local_sync,
        sync_by_default: collection.sync_by_default,
        processing: ProcessingType.not_processing
      };
    });
    const _invites = !!accepted_invites ? invite_list.concat(accepted_invites) : invite_list;
    setSharedWithMe(_invites);
    setIsLoading(false);
  }

  const DriveInvitationsRequests = {
    handleRefresh: () => {
      refresh();
    },
    handleRespondToShareInvitation: (params: { invitation: DriveInvitationInfo, accept: boolean, sync: boolean }) => {
      const { invitation, accept, sync } = params;
      invitation.processing = accept ? ProcessingType.accepting : ProcessingType.rejecting;
      setResponding(true);
      if (!!is_web) {
        respondToShareInvitation(invitation.collection_id, invitation.share_id, accept, invitation.name);
      } else {
        respondToShareInvitationApp(invitation.collection_id, invitation.share_id, invitation.name, accept, sync);
      }
    },
    // Description: Handle all page / components top level errors
    handlePageErrorMessage: (params: {
      message: string;
      stack?: any;
      display_type?: MessageDisplayType;
    }) => {
      const display_type = !!params.display_type
        ? params.display_type
        : MessageHandlerDisplayType.logger;
      dispatch(
        uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack),
      );
    },
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((DriveInvitationsRequests as any)[callback] instanceof Function) {
      (DriveInvitationsRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      DriveInvitationsRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return (
    <>
      <PageHeader>
        <h1 className="mb-3">Invitations</h1>
      </PageHeader>
      <InvitationsToolbar
        is_loading={is_loading}
        handleAction={handlePageActions}
        total={shared_with_me.length}
      ></InvitationsToolbar>
      <div className="cover-content">
        {!!current_account && (
          <InvitationsListView
            current_account={current_account}
            handleAction={handlePageActions}
            invitations={shared_with_me}
            is_loading={is_loading}
            responding={responding}
          />
        )}
      </div>
    </>
  );
}

export default React.memo(DriveInvitationsComponent);