import React from "react";
import { ActionHandlerFunction, CollectionServerUser } from "@preveil-api";
import { NotMatchesFoundView } from "src/components";
import { CheckboxStates } from "src/common";
import { UserExceptionListRow } from ".";
import _ from "lodash";

type AllProps = {
  filtered_users_exception_list: string[];
  selected_users_exception_list: string[];
  org_users: CollectionServerUser[];
  handleAction: ActionHandlerFunction;
}

function UsersExceptionListView(props: AllProps) {
  const { filtered_users_exception_list, selected_users_exception_list, org_users, handleAction } = props;
  if (_.isEmpty(filtered_users_exception_list)) {
    return <NotMatchesFoundView />;
  }

  const users = org_users.filter((user) => filtered_users_exception_list.includes(user.user_id.toLowerCase()));
  return (
    <div className="row-container">
      {_.map(users, (user: CollectionServerUser) => {
        const { user_id, display_name, entity_metadata } = user;
        const { role } = entity_metadata;
        const key = _.uniqueId(user_id);
        const is_selected = selected_users_exception_list.includes(user_id) ? CheckboxStates.checked : CheckboxStates.empty;
        return (
          <UserExceptionListRow
            key={key}
            user_id={user_id}
            role={role}
            display_name={display_name}
            handleAction={handleAction}
            is_selected={is_selected}
          />
        );
      })}
    </div>
  );
}

export default React.memo(UsersExceptionListView);