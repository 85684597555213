import React from "react";
import { ActionHandlerFunction, UserProfile } from "@preveil-api";
import { CloudLockUserInfo } from ".";
import { Col, Row } from "react-bootstrap";

type AllProps = {
  selected_users_sync_permission: UserProfile[];
  handleAction: ActionHandlerFunction;
}

function UsersExceptionDetailsCard(props: AllProps) {
  const { selected_users_sync_permission, handleAction } = props;
  return (
    <div className="groups-card mt-3">
      <header>
        <Row className="header-card-row">
          <Col xs={7}>
            <p className="m-0">User</p>
          </Col>
          <Col xs={5} className="ps-2">
            <p className="m-0">Role</p>
          </Col>
        </Row>
      </header>

      {selected_users_sync_permission.map((member: UserProfile) => {
        const { address } = member;  
        return (
          <CloudLockUserInfo
            key={address}
            user={member}
            handleActions={handleAction}
          />
        );
      })}
    </div>
  );
}

export default React.memo(UsersExceptionDetailsCard);