/*
*   File:           action-types.ts
*   Description:    Collection of constants for storing UI Actions to pass to IActionHandler
*   Author:         PreVeil, LLC
*/
import keyMirror from "keymirror";

// ---------------------------------------------------------------
// UI
// ---------------------------------------------------------------
export const UIManagerActionTypes = keyMirror({
  CounterDisplayExpired: null,
  Select: null,
  Paging: null,
  SimplePaging: null,
  TreeNodeClick: null,
  TreeNodeDelete: null,
  TreeNodeRename: null,
  TreeNodeAddChild: null,
  TreeNodeActiveState: null,
  FetchTreeNodeChildren: null,
  SyncCheckboxChange: null,
  CloseModal: null,
  SetServiceLoading: null,
  SetServiceData: null,
  SetServiceError: null,
  DateSelector: null,
  OnDrag: null,
  OnDrop: null,
  OnDragOver: null,
  OnDragLeave: null,
  OnDragEnd: null,
  PageError: null
});

// ---------------------------------------------------------------
// AUTH AND LOGIN
// ---------------------------------------------------------------
// Express / Web Login and Create Account Action Types
export const LoginWebUIActionTypes = keyMirror({
  SubmitEmail: null,
  SubmitPassword: null,
  SubmitSMSVerificationCode: null,
  SubmitTOTPVerificationCode: null,
  ResetLogin: null,
  ForgotPassword: null,
  SubmitNewPassword: null,
  PageError: null
});


// Create Accounts
export const CreateAccountUIActionTypes = keyMirror({
  SubmitEmail: null,
  SubmitVerificationCode: null,
  SubmitVerificationCodeError: null,
  SubmitUserInformation: null,
  SubmitPassword: null,
  SubmitPhone: null,
  SubmitSMSVerificationCode: null,
  SubmitTOTPVerificationCode: null,
  ChangeStep: null,
  ResetForms: null,
  PageError: null,
  SubmitCreateOrganization: null
});

// Change Authentication 
export const ChangeAuthenticationUIActionTypes = keyMirror({
  SubmitPassword: null,
  SubmitPhone: null,
  SubmitSMSVerificationCode: null,
  SubmitTOTPVerificationCode: null,
  ChangeStep: null,
  ResetForms: null,
  PageError: null
});

// Recover Accounts and Approval group
export const RecoverAccountUIActionTypes = keyMirror({
  SubmitRecoverCode: null,
  SubmitPassword: null,
  ResetForms: null,
  PageError: null,

  // Approval group
  GetShard: null,
  ApprovalGroupLocalInfo: null,
  SubmitAcknowledgeInformation: null,
  ReconstructPrivateKey: null,  // SubmitRecovery: null,
  Destroy: null
});

// ---------------------------------------------------------------
//  General Public Page Actions: These are actions passed from child 
// to parent components - Also includes Add Device in Settings
// ---------------------------------------------------------------
// Websocket Settings Actions and other account Action Types
export const AccountUIActionTypes = keyMirror({
  SubmitUserId: null,
  InitWebsocketConnection: null,
  TransferKeyRequest: null,
  ResetMessage: null,
  Disconnect: null,
  Destroy: null,
  PageErrorMessage: null,
  PageError: null,
  PageSuccess: null
});


// ---------------------------------------------------------------
// Admin
// ---------------------------------------------------------------
export const AdminUIActionTypes = keyMirror({
  AddAddressRequest: null,
  AddUserIdToSyncPermissionList: null,
  Assign: null,
  Cancel: null,
  CancelSingleSubsumeInvite: null,
  ChangeGatewayUserId: null,
  Confirm: null,
  Copy: null,
  Create: null,
  CsvData: null,
  Destroy: null,
  Delete: null,
  DeleteAll: null,
  DeleteDeviceCertificate: null,
  DeleteUserIdFromSyncPermissionList: null,
  EditUser: null,
  ExportCSV: null,
  Finish: null,
  GetRefreshRequest: null,
  HideOffCanvas: null,
  MultiSelect: null,
  PageError: null,
  PageErrorMessage: null,
  Refresh: null,
  RequestDataExport: null,
  ResendInvite: null,
  Reset: null,
  ResetSingleSubsumeInvite: null,
  SearchParam: null,
  Select: null,
  SelectUser: null,
  SetAction: null,
  SetFilterByUser: null,
  SelectDataExportContent: null,
  SetRecoveryGroup: null,
  SetTopic: null,
  SetSort: null,
  SetShow: null,
  ShowModal: null,
  ShowSideNavMenu: null,
  SingleSelect: null,
  Start: null,
  StartDataExport: null,
  StopDataExportRunning: null,
  SubmitAlias: null,
  SubmitAddresses: null,
  SubmitCsvData: null,
  SubmitUserData: null,
  SubmitUserInvite: null,
  SubmitSubsumeUserInvite: null,
  TestGatewayConnectivity: null,
  ToggleCollapse: null,
  Update: null,
  UpdateUser: null,
  UserSearchParam: null,
  UploadDeviceCertificate: null
});

// ---------------------------------------------------------------
// Settings
// ---------------------------------------------------------------
export const UserListActionTypes = keyMirror({
  SetUserProfile: null,
  Reset: null,
  RemoveProfile: null,
  Cancel: null
});

// ---------------------------------------------------------------
// Settings
// ---------------------------------------------------------------
export const SettingsUIActionTypes = keyMirror({
  Destroy: null,
  PageErrorMessage: null,
  Select: null,
  Delete: null,
  Refresh: null,
  RespondToRequest: null,
  UpdateFilterStatus: null,
  GetRequestResponses: null,
  SetTab: null,
  SetSort: null,
  UpdateContacts: null,
  UpdateContactsExcludeUserWarning: null,
  SetUserProfile: null
});

// ---------------------------------------------------------------
// Mail
// ---------------------------------------------------------------
export const MailUIActionTypes = keyMirror({
  Notify: null,
  Select: null,
  Star: null,
  UpdateSeenFlag: null,
  MoveModal: null,
  ResetExternalLink: null,
  Move: null,
  Delete: null,
  Reply: null,
  ReplyAll: null,
  Forward: null,
  Print: null,
  Refresh: null,
  Paging: null,
  SubmitMailboxName: null,
  SetThreadsSuccess: null,
  ResetCurrentThread: null,
  SetMessages: null,
  AddComposeMessage: null,
  Destroy: null,
  PageErrorMessage: null,
  ResetSubsumeModal: null
});

// Compose Mail
export enum COMPOSE_STATES {
  LOADING,
  COMPOSING,
  SENDING,
  SENDING_FAILED,
  ERROR,
  SUCCESS,
}

// Compose Mail Draft State
export enum DRAFT_STATES {
  INITIALIZING,
  READY,
  SAVING,
  SAVED,
  ERROR,
  CLOSING
}

// ---------------------------------------------------------------
// Drive
// ---------------------------------------------------------------
export const DriveUIActionTypes = keyMirror({
  Select: null,
  SelectFiles: null,
  Upload: null,
  CreateFolder: null,
  MoveModal: null,
  Move: null,
  Delete: null,
  Remove: null,
  Print: null,
  Refresh: null,
  Details: null,
  DownloadAndView: null,
  Share: null,
  SharedComplete: null,
  CopyLink: null,
  Sync: null,
  Lock: null,
  Unlock: null,
  Rename: null,
  EditFile: null,
  ResetAction: null,
  Restore: null,
  RespondToShareInvitation: null,
  RejectInvitation: null,
  AcceptInvitation: null,
  Paging: null,
  Destroy: null,
  ToggleSidePanel: null,
  GetCollectionInfo: null,
  PageErrorMessage: null,
  DragOver: null,
  OnDrag: null,
  OnDrop: null,
  OnDragLeave: null,
  OnDragEnd: null,
  SetSort: null,
  SetSortChildren: null,
  SetFilter: null,
  AddFolder: null,
  SelectiveSyncModal: null,
  SelectiveSyncNotification: null,
  RenameNotification: null,
  EmptyTrash: null,
  RestoreItems: null,
  Download: null,
  Filter: null,
  ResetName: null,
  ResetFilters: null,
  Reset: null,
  DuplicateFile: null,
  LoadMore: null,
  DownloadFiles: null
});

export enum DRIVE_MANAGER_STATE {
  NOT_INITIALIZED,
  DEFAULT_COLLECTION_ID_SUCCESS,
  READY,
  ERROR,
  NEW_EXPRESS_ACCOUNT,
  NEW_APP_ACCOUNT,
  DEFAULT_COLLECTION_INITIALIZED
};

// ---------------------------------------------------------------
// Upload
// ---------------------------------------------------------------
export const UploadUIActionTypes = keyMirror({
  Cancel: null,
  Rename: null,
  SubmitRename: null,
  UpdateRename: null
});

