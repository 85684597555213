import React, { useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { GlobalConstants, ChangeAuthenticationUIActionTypes } from "src/common";
import { VerificationCode } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function CurrentTOTPCodeVerificationFormComponent(props: AllProps) {
  const { handleAction } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  let secret = "";
  // Description: Handle form submit
  function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    const params = secret;
    handleAction({ actionType: ChangeAuthenticationUIActionTypes.SubmitTOTPVerificationCode, params });
  }

  // Description: Validate and set secret code on finished
  function handleSetSecretCode(code: string) {
    secret = code;
    const button = buttonRef.current;
    const isValid = code.length === GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX;
    if (!!button) {
      button.disabled = !isValid;
      isValid && setTimeout(() => button.focus());
    }
  }

  return <>
    <h3>Verify Your Current Authentication Method</h3>
    <p>Open your Time-based One-Time Password (TOTP) authenticator application.</p>
    <p>Please wait for the code to refresh on the application and then enter the new verification code below.</p>
    <Form onSubmit={handleSubmit} noValidate>
      <Form.Group className="mb-3">
        <Form.Label>TOTP Verification Code</Form.Label>
        <VerificationCode count={GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX} handleAction={handleSetSecretCode} />
      </Form.Group>
      <div className="btn-panel">
        <Button type="submit" ref={buttonRef} disabled={true}>Continue</Button>
        <Button variant="outline-secondary" type="button"
          onClick={() => handleAction({ actionType: ChangeAuthenticationUIActionTypes.ResetForms })}>Cancel</Button>
      </div>
    </Form>
  </>;
}

export default React.memo(CurrentTOTPCodeVerificationFormComponent);
