import React, { ReactElement } from "react";
import { Alert, Form } from "react-bootstrap";
import { InfoPopover } from "src/components";

type AllProps = {
  children: ReactElement
  popover: boolean
  popover_message?: string
  toggleState?: boolean
  disabled?: boolean
  handleChange?: () => void
}

function AlertWithSwitchComponent(props: AllProps) {
  const { children, popover, popover_message, toggleState, handleChange, disabled  } = props;
  const switch_state = toggleState ? "active" : "inactive";
  return (
    <Alert variant="info" className="admin-alert admin-switch">
      <div className="switch-preveil-container">
        <p className="switch-text-legend">
          {children}
          {popover ? <InfoPopover message={popover_message} /> : null}
        </p>
        <div className="switch-preveil align-self-center">
          <Form.Check
            className={`${disabled ? "switch-preveil-disabled" : ""} ${switch_state}`}
            disabled={disabled}
            checked={toggleState}
            type="switch"
            id="preveil-switch"
            onChange={handleChange}
          />
        </div>
      </div>
    </Alert>
  );
}

export default React.memo(AlertWithSwitchComponent);
