import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { OrgInfo } from "@preveil-api";
import { uiActions } from "src/store";
import {
  Account, AdminErrorMessages, collectionApi, Message, MessageAnchors, MessageHandlerDisplayType, useAppDispatch, useAdminSetInviteEmailTypeMutation,
  usePatchUsersOrgsByEntityIdShowMuaPromptMutation, usePutUsersOrgsByEntityIdKeyvalMutation, useAppSelector, useGetUsersOrgsByEntityIdQuery
} from "src/common";
import { AlertWithSwitch, CoverTemplate, PageHeader, Loading } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  current_account: Account;
  org_info: OrgInfo;
};

function AdminSettingsComponent({ current_account, org_info }: AllProps) {
  const dispatch = useAppDispatch();
  const global_local_sync = useAppSelector((state: RootState) => state.account.global_local_sync);
  // RTK Hooks
  const [getUsersOrgsByEntityIdKeyvalAndKey] = collectionApi.endpoints.getUsersOrgsByEntityIdKeyvalAndKey.useLazyQuery();
  const [adminSetInviteEmailType] = useAdminSetInviteEmailTypeMutation();
  const [patchUsersOrgsByEntityIdShowMuaPrompt] = usePatchUsersOrgsByEntityIdShowMuaPromptMutation();
  const [putUsersOrgsByEntityIdKeyval] = usePutUsersOrgsByEntityIdKeyvalMutation();
  const { data: org_info_data, error, isSuccess } = useGetUsersOrgsByEntityIdQuery({
    account_ids: Account.getAccountIdentifiers(current_account),
    body: {
      entity_id: org_info.org_id
    }
  });

  // State
  const [user_onboarding, setUserOnboarding] = useState<boolean>(false);
  const [add_to_mail_client, setAddToMailClient] = useState<boolean>(false);
  const [drive_sync_settings, setDriveSyncSettings] = useState<boolean>(false);
  const [is_drive_sync_disabled, setDriveSyncDisabled] = useState<boolean>(false);
  const [is_loading, setIsLoading] = useState<boolean>(true); // we want to show loading component until the fetch calls are done.
  

  useEffect(() => {
    if (global_local_sync === false) {
      // If local sync is disabled then we disable the Drive Sync Default Settings toggle and turn it off.
      // Cloud Only/Lock will manage the org sync settings.
      setDriveSyncDisabled(true);
      drive_sync_settings && setDriveSyncSettings(false); // if drive sync setting toggle was previously enabled then turn it off.
      setIsLoading(false);
    } else if (global_local_sync) {
      // If local sync is enabled then we fetch the sync settings from the server (could be on or off).
      getAdminSettingsState();
    }

    if (isSuccess && org_info_data) {
      const { no_download_email, show_mua_prompt } = org_info_data;
      setUserOnboarding(!no_download_email);
      setAddToMailClient(show_mua_prompt);
    }

    if (error) {
      const params = {
        message: AdminErrorMessages.error_admin_settings_data,
        stack: error
      };
      dispatchPageError(params);
    }
  }, [global_local_sync, org_info_data, error]);

  function dispatchPageError(params: { message: string, stack?: any }) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  }

  // Description: Handles the fetch request to load the state for each switch/toggle
  // And the render state for the Loading component.
  async function getAdminSettingsState() {
    await getUsersOrgsByEntityIdKeyvalAndKey({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        entityId: org_info.org_id,
        key: "sync_collections_by_default"
      }
    })
    .unwrap()
    .then((response) => {
      const { value } = response;
      setDriveSyncSettings(value);
      is_drive_sync_disabled && setDriveSyncDisabled(false); // If disabled then enable the toggle. (if cloud lock org changes were made)
      setIsLoading(false);
    }).catch((error) => {
      const params = {
        message: AdminErrorMessages.error_admin_settings_data,
        stack: error
      };
      dispatchPageError(params);
    });
  }

  // Description: Handles Request for User Onboarding.
  async function toggleUserOnboardingRequest() {
    const { user_id } = current_account;
    // Only Crypto Endpoint the others are coming from Collection API.
    await adminSetInviteEmailType({
      userId: user_id,
      orgId: org_info.org_id,
      inviteEmailState: user_onboarding,
    })
      .unwrap()
      .then(() => {
        setUserOnboarding(!user_onboarding);
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, "changing User Onboarding options"),
          stack: error
        };
        dispatchPageError(params);
      });
  };

  // Description: Handles Request for Add to Mail Client.
  async function toggleAddToMailClientPrompt() {
    const show_prompt = !add_to_mail_client;
    await patchUsersOrgsByEntityIdShowMuaPrompt({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        entityId: org_info.org_id,
        show_prompt
      }
    })
      .unwrap()
      .then(() => {
        setAddToMailClient(show_prompt);
      })
      .catch((error) => {
        const failedToggleState = show_prompt ? "disabling Add to Mail Client prompt" : "enabling Add to Mail Client prompt";
        const params = {
          message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, failedToggleState),
          stack: error
        };
        dispatchPageError(params);
      });
  };

  // Description: Handles Request for Drive Sync Default Settings.
  async function toggleDriveSyncDefaultSettings() {
    const value = (!drive_sync_settings).toString();
    const key = "sync_collections_by_default";
    await putUsersOrgsByEntityIdKeyval({
      account_ids: Account.getAccountIdentifiers(current_account),
      body: {
        entityId: org_info.org_id,
        key,
        value
      }
    })
      .unwrap()
      .then(() => {
        setDriveSyncSettings(!drive_sync_settings);
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, "changing Drive Sync Default Settings"),
          stack: error
        };
        dispatchPageError(params);
      });
  };
  // setUserOnboarding(!no_download_email);
  // setAddToMailClient(show_mua_prompt);
  return <CoverTemplate className="admin-settings-content">
    <PageHeader>
      <h1>Admin Settings</h1>
    </PageHeader>
    {
      is_loading ?
        <Loading /> :
        <Card className="card-section">
          <h5 className="content-header">User Onboarding</h5>

          <AlertWithSwitch
            popover={true}
            toggleState={user_onboarding}
            handleChange={toggleUserOnboardingRequest}
            popover_message="Disabling this option will remove the prompts asking members to install PreVeil. Installation link should be disabled when PreVeil is deploying through Active Directory or other remote deployment tools."
          >
            <span>
              Onboarding emails <strong>{`${!user_onboarding ? "won't" : "will"}`}</strong>{" "}
              include installation link.
            </span>
          </AlertWithSwitch>

          <h5 className="content-header">Mail Client Prompt</h5>
          <AlertWithSwitch
            popover={true}
            toggleState={add_to_mail_client}
            handleChange={toggleAddToMailClientPrompt}
            popover_message="Disabling this option will remove the Mail Client prompt upon claiming an account."
          >
            <span>
              New users <strong>{`${add_to_mail_client ? "will" : "will not"}`}</strong> be prompted to install email client integration.
            </span>
          </AlertWithSwitch>
          <h5 className="content-header">Drive Sync Default Setting</h5>
          <AlertWithSwitch
            disabled={is_drive_sync_disabled}
            popover={true}
            toggleState={drive_sync_settings}
            handleChange={toggleDriveSyncDefaultSettings}
            popover_message="When PreVeil Drive folders are shared with others, this setting defines the default behavior for file sync. Sync means shared folders will sync to user devices’ Drive folders and file explorer. No-sync means shared folders will remain in the PreVeil cloud and are visible in the PreVeil browser app only."
          >
            <span>
              All users <strong>{`${drive_sync_settings ? "will automatically sync" : "will not automatically sync"}`}</strong> all shared files to their
              device.
            </span>
          </AlertWithSwitch>
        </Card>
    }
  </CoverTemplate>;
}

export default React.memo(AdminSettingsComponent);
