/*
* Description: NodePermissions will replace FilePermissions (Legacy)
*/
import {
  Grantee, NodePermissionSet, NodePermissionTypes, NodePermissionType, NodePermissionLabels, normalizeQueryUserId,
  COLLECTION_PROTOCOL_VERSIONS, Grant
} from "src/common";
import { FSMessage, FSRole, Node } from "src/common/keys/protos/collections_pb";
import _ from "lodash";

export class NodePermissions implements NodePermissionSet {
  label!: string;
  type: NodePermissionTypes;
  user_id!: string;
  expiration!: string;
  view_only!: boolean;
  pending!: boolean;
  is_owner!: boolean;
  read: boolean = false;
  write: boolean = false;
  share: boolean = false;
  sync: boolean = false;
  // NOTE: Force disable share for DIR items that are children of V1 collections that are not default_collections
  constructor(
    grantee: Grantee,
    disable_share: boolean = false,
    public locked: boolean = false,
    public global_local_sync?: boolean) {
    this.type = this.getNodePermissionType(grantee);
    const data = !!this.type && Object.prototype.hasOwnProperty.call(NodePermissionData, this.type) ?
      NodePermissionData[this.type] : NodePermissionData.invalid;
    Object.assign(this, grantee, data);

    if (disable_share) {
      this.share = false;
    }
  };

  get node_permission_set(): NodePermissionSet {
    return {
      user_id: this.user_id,
      label: this.label,
      type: this.type,
      expiration: this.expiration,
      view_only: this.view_only,
      pending: this.pending,
      is_owner: this.is_owner,
      read: this.read,
      write: this.write,
      share: this.share,
      sync: this.sync,
      locked: this.locked,
      global_local_sync: this.global_local_sync
    };
  }

  // Description: Get the UI permission type: edit_and_share, edit, read_only, view_only, unshare
  // LEGACY: _createFromPermissionsList
  private getNodePermissionType(grantee: Grantee): NodePermissionTypes {
    const acl = grantee.acl;
    const roles = grantee.roles || [];
    const is_reader = acl.includes(Node.ACLRole.READER) || roles.includes(FSRole.READER);
    const is_writer = acl.includes(Node.ACLRole.WRITER) || roles.includes(FSRole.WRITER);
    const is_acl_viewer = acl.includes(Node.ACLRole.ACL_READER) || roles.includes(FSRole.ACL_VIEWER);
    const is_sharer = acl.includes(Node.ACLRole.ACL_WRITER) || roles.includes(FSRole.SHARER);
    return grantee.view_only ? NodePermissionType.view_only :
      is_reader && is_writer && is_acl_viewer && is_sharer ? NodePermissionType.edit_and_share :
        is_reader && is_writer && is_acl_viewer ? NodePermissionType.edit :
          is_reader ? NodePermissionType.read_only : NodePermissionType.view_only;
  }

  // Description: Convert FSMessage.User flat to Grantee
  static parseUserList(message_user: FSMessage.User): Grantee {
    const acl = message_user.getRolesList();
    return {
      user_id: message_user.getUserId() || "",
      expiration: message_user.getExpirationTime() || "",
      view_only: false,
      pending: message_user.getPending() || false,
      is_owner: acl.includes(0),
      acl: message_user.getAclRolesList(),
      roles: message_user.getRolesList()
    };
  }

  // Description: Convert FSMessage.GranteeFlat to Grantee
  static parseGranteeFlat(grantee: FSMessage.GranteeFlat, collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS): Grantee | null {
    const user_id = normalizeQueryUserId(grantee.getUserId());
    const acl = grantee.getAclRolesList();
    const roles = grantee.getRolesList();
    const isGranteeValid = collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 && acl.length > 0 ||
      collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 && roles.length > 0;
    return isGranteeValid && user_id ? {
      user_id,
      expiration: grantee.getExpirationTime() || "",
      view_only: grantee.getViewOnly() || false,
      pending: grantee.getPending() || false,
      is_owner: grantee.getIsOwner() || false,
      acl,
      roles
    } : null;
  }

  // Description: Update NodePermissionSet to new type and / or new expiration
  static getNewNodePermissions(node_permission: NodePermissionSet, type: NodePermissionTypes, expiration: string): NodePermissionSet {
    const new_node_permissions = {
      ...node_permission, ...{
        type,
        // NOTE: Reset expiration date to "" (empty string) if the type is edit_and_share
        expiration: (type === NodePermissionType.edit_and_share) ? "" : expiration
      }
    };
    const data = Object.prototype.hasOwnProperty.call(NodePermissionData, type) ? NodePermissionData[type] : null;
    !!data && Object.assign(new_node_permissions, data);
    return new_node_permissions;
  }

  // Description: Create a set of permissions for collections created to house headless directories with ACL nodes
  //  Case: for users that are recipients of ACL Nodes - need to disable share at top level
  static createEffectiveRootNodePermissions(user_id: string, collection_id: string, global_local_sync?: boolean): NodePermissionSet {
    return {
      expiration: "",
      is_owner: false,
      label: "",
      pending: false,
      read: true,
      share: false,
      sync: true,
      type: NodePermissionType.edit,
      user_id,
      view_only: false,
      write: false,
      locked: false,
      effective_root_node: true,
      global_local_sync
    };
  };

  // Description: Build type from Dir.getACLList()
  static getNodePermissionType(grants?: Grant[]): NodePermissionTypes {
    let node_permission_type: NodePermissionTypes = NodePermissionType.view_only;
    if (!!grants) {
      // const reader = _.find(root_grants?.grants, (grant: Grant) => grant.role === Node.ACLRole.READER);
      let view_only = false;
      const acl = _.map(grants, (grant: Grant) => {
        if (grant.role === Node.ACLRole.READER) {
          view_only = grant.view_only;
        };
        return grant.role;
      });
      const is_reader = acl.includes(Node.ACLRole.READER);
      const is_writer = acl.includes(Node.ACLRole.WRITER);
      const is_acl_viewer = acl.includes(Node.ACLRole.ACL_READER);
      const is_sharer = acl.includes(Node.ACLRole.ACL_WRITER);
      node_permission_type = view_only ? NodePermissionType.view_only :
        is_reader && is_writer && is_acl_viewer && is_sharer ? NodePermissionType.edit_and_share :
          is_reader && is_writer && is_acl_viewer ? NodePermissionType.edit :
            is_reader ? NodePermissionType.read_only : NodePermissionType.view_only;
    };
    return node_permission_type;
  }
}

// Description: Data for Node permissions
const NodePermissionData = {
  edit_and_share: {
    label: NodePermissionLabels.edit_and_share,
    read: true,
    write: true,
    share: true,
    sync: true,
    view_only: false
  },
  edit: {
    label: NodePermissionLabels.edit,
    read: true,
    write: true,
    share: false,
    sync: true,
    view_only: false
  },
  read_only: {
    label: NodePermissionLabels.read_only,
    read: true,
    write: false,
    share: false,
    sync: true,
    view_only: false
  },
  view_only: {
    label: NodePermissionLabels.view_only,
    read: true,
    write: false,
    share: false,
    sync: false,
    view_only: true
  },
  unshare: {
    label: NodePermissionLabels.unshare,
    read: false,
    write: false,
    share: false,
    sync: false,
    view_only: false
  },
  invalid: {
    label: "invalid",
    read: false,
    write: false,
    share: false,
    sync: false,
    view_only: false
  }
};
