import { useEffect, useRef, useState } from "react";
import { ErrorStackDataType, MessageDisplayType, RenameCollectionEventPayload } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { FSMessage, FSStatus, Node } from "src/common/keys/protos/collections_pb";
import {
    Account, AuthenticationConstants, COLLECTION_PROTOCOL_VERSIONS, CollectionAPI, CollectionFilesyncAPI, DirectoryEntity, DriveErrorMessages, DriveRequest, ErrorStackItem, Helpers, LocalAccountStorage, Message, MessageAnchors,
    MessageHandlerDisplayType, PermissionSetType, RenameValidationErrorMessages, UUID, account_types, decryptRootName, encryptName,
    filesyncSocketApi, getDirSymmKey, getEnumKey, useAppDispatch, useAppSelector, useGetPermissions, DirectoryWrappedKey, GrantSet,
    GrantSetType
} from "src/common";
import { DriveCallbackAsyncFunction, appActions, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";

interface LinkInfo {
    link_id: string;
    version: string;
    parent_id: string;
    collection_protocol_version?: number;
}

const is_full_account = LocalAccountStorage.getStorageItem(AuthenticationConstants.ACCOUNT_TYPE_SESSION_KEY) === account_types.full;

// Description: Hook used to handle collection rename events (only for web builds)
export function useCollectionRenameNotification(current_account: Account, is_web: boolean = false) {
    const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
    const default_collection = useAppSelector((state: RootState) => state.drive.root_info);
    const renamed_collections = useAppSelector((state: RootState) => state.app.renamed_collections);
    const current_directory = useAppSelector((state: RootState) => state.drive.current_directory);
    const [current_collection, setCurrentCollection] = useState<RenameCollectionEventPayload>();
    const [latest_rename_events, setLatestRenameEvents] = useState<RenameCollectionEventPayload[]>([]);
    const [duplicate_event_ids, setDuplicateEventIds] = useState<string[]>([]);
    const [parent_info, setParentInfo] = useState<DirectoryWrappedKey>();
    const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
    const [grants, setGrants] = useState<GrantSetType[] | undefined>();
    const [sendRequest] = filesyncSocketApi.useSendRequestMutation();
    const dispatch = useAppDispatch();

    const version_mismatch_retry_ref = useRef<boolean>(false);
    function setVersionMismatchRetryRef(_retry: boolean) {
        version_mismatch_retry_ref.current = _retry;
    }
    const permissions_ref = useRef(permissions);
    function setPermissionsRef(_permissions?: PermissionSetType) {
        permissions_ref.current = _permissions;
    }
    const link_info_ref = useRef<LinkInfo | undefined>();
    function setLinkInfoRef(_link_info?: LinkInfo) {
        link_info_ref.current = _link_info;
    }

    // Description: Handle duplicate rename events
    useEffect(() => {
        (is_web && renamed_collections.length > 0) &&
            handleDuplicateEvents(renamed_collections);
    }, [renamed_collections]);

    // Description: Pop the last renamed collection event from the stack to be handled next
    useEffect(() => {
        (is_web && !!latest_rename_events.length) &&
            handleInitRenameCollection(latest_rename_events[0]);
    }, [latest_rename_events]);

    // Description: Pop the duplicate rename events and mark them as handled
    useEffect(() => {
        if (!!duplicate_event_ids.length) {
            const _duplicate_event = duplicate_event_ids[0];
            markEventAsCompleted(_duplicate_event);
            setDuplicateEventIds(prevIds => prevIds.slice(1));
        }
    }, [duplicate_event_ids]);

    // Description: Set the permissions, get the links for the collection to be renamed and destroy the permissions
    useEffect(() => {
        if (!!permissions) {
            setPermissionsRef(permissions);
            destroyPermissions();
            !!current_collection &&
                getLinks(current_collection);
        }
    }, [permissions]);

    // Description: Update the rename event after fetching all data we need
    useEffect(() => {
        if (!!link_info_ref.current && !!current_collection && !!parent_info) {
            ((link_info_ref.current.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 && !!grants) ||
                link_info_ref.current.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1) &&
                updateRename(current_collection, parent_info, grants);
        }
    }, [grants, parent_info]);

    // ------------------------------------
    // Handle Notifications
    // ------------------------------------
    // Description: Filters unique rename events and marks the duplicate events as handled
    function handleDuplicateEvents(renamed_collections: RenameCollectionEventPayload[]) {
        const rename_events_map: Map<string, RenameCollectionEventPayload> = new Map();
        const duplicate_ids: string[] = [];

        renamed_collections.forEach(event => {
            const existing_event = rename_events_map.get(event.collection_id);

            if (!existing_event || (event.last_update && event.last_update > existing_event.last_update)) {
                if (existing_event) {
                    duplicate_ids.push(existing_event.id);
                }
                rename_events_map.set(event.collection_id, event);
            } else {
                duplicate_ids.push(event.id);
            }
        });

        setLatestRenameEvents(Array.from(rename_events_map.values()));
        setDuplicateEventIds(duplicate_ids);
        dispatch(appActions.resetRenamedCollection());
    }

    // Description: Marks the event as handled
    async function markEventAsCompleted(event_id: string): Promise<void> {
        await CollectionAPI.putUsersEventsByEventId(Account.getAccountIdentifiers(current_account), {
            event_id,
            body: {
                user_id: current_account.user_id,
                request: {},
            },
        });
    }

    // ------------------------------------
    // Handle Rename events
    // ------------------------------------
    // Description: Fetches the permissions, links and sets the current collection to be renamed
    // @param: _latest_rename_events: RenameCollectionEventPayload where collection_id is in UUID
    //         NOTE: SET current_collection WITH THE B64 collection ID
    function handleInitRenameCollection(_latest_rename_events: RenameCollectionEventPayload) {
        const collection_id = new UUID({ uuid: _latest_rename_events.collection_id }).B64();
        const _current_collection = Object.assign({}, _latest_rename_events, { collection_id });
        setCurrentCollection(_current_collection);
        getPermissions(collection_id, default_permissions);
    }
    // Description: Fetch the links to extract the link ID and version when updating the collection name
    async function getLinks(_current_collection: RenameCollectionEventPayload) {
        const _collection_id = _current_collection.collection_id;
        const default_collection_permissions = default_permissions.find(permission => permission.collection_id.B64() === default_collection?.collection_id);
        const request = (!!default_collection_permissions && !!default_collection?.collection_id) ?
            await CollectionFilesyncAPI.findLinkRequest(current_account, default_collection_permissions, default_collection?.collection_id, _collection_id) : null;
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const links = message.getLinks()?.getLinksList();
                if (!!links && links.length > 0) {
                    const link = links.pop();
                    if (!!link && !!default_collection_permissions && !!permissions_ref.current) {
                        const collection_protocol_version = link.getCollectionProtocolVersion();
                        setLinkInfoRef(
                            {
                                link_id: link.getId_asB64(),
                                version: link.getVersion_asB64(),
                                parent_id: link.getParentId_asB64(),
                                collection_protocol_version
                            }
                        );
                        getParentInfo(_current_collection);
                        (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2) && getACLTree(_current_collection);
                    }
                    else {
                        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link_entity }, message);
                    }
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link_entity }, message);
            }
        }
        handleSendRequest(request, callback, undefined, DriveErrorMessages.error_fetching_link_entity);
    }

    // Description: Get the ACL tree to fetch the root node ID, which we use to fetch the directory of the renamed collection to fetch its grants
    async function getACLTree(_current_collection: RenameCollectionEventPayload) {
        const _collection_id = _current_collection.collection_id;
        const request = await CollectionFilesyncAPI.getACLTreeRequest(_collection_id);
        const drive_request = !!permissions_ref.current ? await DriveRequest.initializeRequest(request, current_account, permissions_ref.current) : null;
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const directory_list = message.getAclTree()?.getDirsList();
                const acl_node_grants: GrantSetType[] = [];
                _.forEach(directory_list, (directory: FSMessage.Dir) => {
                    const node_grants: Node.Grant[] = directory.getAclList();
                    const maintainer_id = directory.getMaintainerId_asB64();
                    const current_grants = GrantSet.getCurrentUsersGrantSets(_collection_id, maintainer_id, node_grants, current_account.user_id);
                    !!current_grants && acl_node_grants.push(current_grants);
                });
                setGrants(acl_node_grants);
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree }, message);
            }
        }
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_tree);
    }

    // Description: Checks if the parent directory is the default collection, if not, fetch the parent directory
    function getParentInfo(_current_collection: RenameCollectionEventPayload) {
        const parent_id = link_info_ref.current?.parent_id;
        if (!!parent_id && default_collection) {
            if (parent_id === default_collection.id) {
                if (!!default_collection.wrapped_dir_key && !!default_collection.version) {
                    const parent_info: DirectoryWrappedKey = {
                        wrapped_dir_key: default_collection.wrapped_dir_key,
                        version: default_collection.version
                    };
                    setParentInfo(parent_info);
                } else {
                    handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_parent_directory });
                }
            } else {
                fetchParent(parent_id, default_collection.collection_id);
            }
        }
    }

    // Description: Fetches the parent directory to get the wrapped key and version to rename the collection
    async function fetchParent(parent_id: string, _collection_id: string) {
        const _permissions = default_permissions.find(permission => permission.collection_id.B64() === default_collection?.collection_id);
        const request = await CollectionFilesyncAPI.getDirectoryInformationRequest(_collection_id, parent_id);
        const drive_request = !!permissions_ref.current ? await DriveRequest.initializeRequest(request, current_account, _permissions) : null;
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_parent_directory);
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const dir = message.getDir();
                if (!!dir) {
                    const wrapped_key = dir.getWrappedDirKey();
                    const version = dir.getVersion_asB64();
                    if (!!wrapped_key && !!version) {
                        const parent_info: DirectoryWrappedKey = {
                            wrapped_dir_key: wrapped_key,
                            version
                        };
                        setParentInfo(parent_info);
                    } else {
                        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_parent_directory }, message);
                    }
                } else {
                    handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_parent_directory }, message);
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_parent_directory }, message);
            }
        }
    }

    // Description: Decrypts the new name from then rename event, handles local conflicts, renames the collection and marks the event as completed
    async function updateRename(_current_collection: RenameCollectionEventPayload, _parent_info: DirectoryWrappedKey, _grants?: GrantSetType[]) {
        const _collection_id = _current_collection.collection_id;
        const old_name = current_directory?.entries.find(entry => entry.linked_collection_id === _collection_id)?.name;
        // Decrypt the new name
        let new_name = (!!permissions_ref.current) ? await decryptRootName(Helpers.b64Decode(_current_collection.name), permissions_ref.current, current_account, _current_collection.key_version) : null;
        // Handle file name conflicts if any
        new_name = (!!new_name) ? handleNameConflict(new_name, old_name, current_directory) : null;
        const default_collection_permissions = default_permissions.find(permission => permission.collection_id.B64() === default_collection?.collection_id);
        let drive_request = null;
        if (!!permissions_ref.current && !!default_collection?.collection_id && !!link_info_ref.current && !!default_collection_permissions && !!default_collection?.wrapped_dir_key && !!new_name) {
            const link_id = link_info_ref.current.link_id;
            const link_version = link_info_ref.current.version;
            // Encrypt the new name and send the request
            const key = await getDirSymmKey(_parent_info.wrapped_dir_key, current_account, default_collection_permissions);
            if (!!key) {
                const encrypted_name = await encryptName(new_name, key);
                const new_version = randomBytes(32);
                drive_request = (!!encrypted_name && !!link_version && !!default_collection?.version) ?
                    await CollectionFilesyncAPI.renameEntry(current_account,
                        default_collection_permissions,
                        default_collection?.collection_id,
                        link_id, link_version, new_version,
                        _parent_info.version,
                        encrypted_name,
                        undefined,
                        _grants) : null;
            }
        }
        async function callback(message: FSMessage, args: any) {
            if (message.getStatus() === FSStatus.OK) {
                markEventAsCompleted(_current_collection.id);
                // NOTE: Remove the first element from latest_rename_events
                setLatestRenameEvents(prevEvents => prevEvents.slice(1));
                setVersionMismatchRetryRef(false);
                reset();
            } else if (message.getStatus() === FSStatus.VERSION_MISMATCH && !version_mismatch_retry_ref.current && is_full_account && !!_current_collection) {
                setVersionMismatchRetryRef(true);
                getLinks(_current_collection);
            } else {
                reset();
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, args.old_name) }, message);
            }
        }
        handleSendRequest(drive_request, callback, { old_name, new_name }, RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, old_name ?? ""));
    }

    // Description: Handles name conflicts if any
    function handleNameConflict(new_name: string, old_name?: string, current_directory?: DirectoryEntity) {
        if (!!old_name && !!current_directory) {
            const new_name_exists = current_directory?.entries.find(entry => entry.name === new_name);
            if (new_name_exists) {
                new_name = new_name + " (conflict - " + String(old_name) + ")";
            }
        }
        return new_name;
    }

    // Description: handles sending the request and handling errors if no request is passed
    function handleSendRequest<T>(
        request: DriveRequest | null,
        callback: DriveCallbackAsyncFunction<T>,
        callback_args?: any,
        stack_error: string = DriveErrorMessages.error_sending_request
    ) {
        if (!!request) {
            request.setCallback((response) => callback(response, callback_args));
            sendRequest(request);
        } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
        }
    }

    // Description: handles request errors and if permissions, user key or sealed name are not found
    function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
        const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
        const stack = new ErrorStackItem("[useCollectionRenameNotification Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
        dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    }

    // Description: reset the state
    function reset() {
        setLinkInfoRef(undefined);
        setVersionMismatchRetryRef(false);
        setParentInfo(undefined);
        setCurrentCollection(undefined);
        setGrants(undefined);
        destroyPermissions();
    }
}
