import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Card, Image } from "react-bootstrap";
import { IActionHandler, CreateUserData, MessageDisplayType } from "@preveil-api";
import {
  QueryParamKeys, QueryParamValues, normalizeQueryUserId, useAppDispatch, useAppSelector, account_types, GlobalErrorMessages, MessageHandlerDisplayType,
  KeyStorageUser, CreateAccountSteps, AccountErrorMessages, Message, DefaultRoutes, MessageAnchors, StatusCode
} from "src/common";
import { uiActions, accountActions } from "src/store";
import { Loading, ProgressCircles, useAuthContext } from "src/components";
import { EmailForm, VerificationForm, UserInformationForm, PasswordForm, PhoneForm, SMSCodeVerificationForm, Pick2FAForm, TOTPForm, ExpressUserAlert, ErrorPanel } from ".";
import { authenticator } from "otplib";
import google from "src/assets/images/account/Google.png";
import authy from "src/assets/images/account/Authy.png";
import duo from "src/assets/images/account/Duo.png";
import apple from "src/assets/images/account/Apple.png";
import microsoft from "src/assets/images/account/Microsoft.png";
import okta from "src/assets/images/account/Okta.svg";
import _ from "lodash";

const two_fa_methods = [
  { name: "Google", value: google },
  { name: "Apple", value: apple },
  { name: "Microsoft", value: microsoft },
  { name: "Authy", value: authy },
  { name: "Duo", value: duo },
  { name: "Okta", value: okta }
];
function CreateExpressAccount() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user_id, setUserId] = useState<string>();
  const [expires, setExpires] = useState<string>(new Date().toString());
  const [keyStorageUser, setKeyStorageUser] = useState<KeyStorageUser>();
  const [create_user_data, setCreateUserData] = useState<CreateUserData>();
  const [totp_secret, setTOTPSecret] = useState<string>();
  const [verification_error_count, setVerificationErrorCount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { loginComplete } = useAuthContext();
  const current_account = useAppSelector((state) => state.account.current_account);
  const error = useAppSelector((state) => state.ui.errors);
  const step = useAppSelector((state) => state.account.status);
  const [max_step_number, setMaxStepNumber] = useState<number>(6);

  function handleInitCreateExpressAccount() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    !!query_user_id && setUserId(query_user_id);
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }
    dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_EMAIL));
  }

  // Description: Handle all children component actions and store it
  const CreateAccountRequests = {
    // Description: Already validated email 
    handleSubmitEmail: (params: { user_id: string; expires: string; status: string; }) => {
      setUserId(params.user_id);
      setExpires(params.expires);
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_VERIFICATION_CODE));
    },
    handleSubmitVerificationCode: (_create_user_data: CreateUserData) => {
      if (!!_create_user_data) {
        setCreateUserData(_create_user_data);
        !!error && dispatch(uiActions.handleMessageDismiss());
        dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_USER_INFORMATION));
      } else {
        CreateAccountRequests.handlePageError({ message: AccountErrorMessages.create_submit_verification_code });
      }
    },
    handleSubmitVerificationCodeError: (status: number) => {
      if (status === StatusCode.FORBIDDEN_DOMAIN && verification_error_count === 4) {
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_code_reset)));
        setExpires(new Date().toString());
        setVerificationErrorCount(0);
        dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_EMAIL));
      } else {
        setVerificationErrorCount(verification_error_count + 1);
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_verification_code)));
      }
    },
    handleSubmitUserInformation: (display_name: string) => {
      setCreateUserData(Object.assign({}, create_user_data, { display_name }));
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_PASSWORD));
    },
    // Description: Create a new KSS User to pass to phone component then on submit send to KSS 
    handleSubmitPassword: (password: string) => {
      if (!!create_user_data && !!create_user_data.account_claim_receipt) {
        const secret = create_user_data.secret;
        const key_version = Number(create_user_data.key_version);
        dispatch(accountActions.setComponentStatus(CreateAccountSteps.LOADING));
        return KeyStorageUser.initKeyStorageUser({
          user_id: create_user_data.user_id,
          account_claim_receipt: create_user_data.account_claim_receipt,
          display_name: create_user_data.display_name || "",
          password
        }, secret, key_version)
          .then((keystorage_user: KeyStorageUser) => {
            setKeyStorageUser(keystorage_user);
            setTOTPSecret(authenticator.generateSecret(20));
            dispatch(accountActions.setComponentStatus(CreateAccountSteps.PICK_2FA_METHOD));
          }).catch((stack: unknown) => {
            CreateAccountRequests.handlePageError({ message: AccountErrorMessages.create_submit_password, stack });
          });
      } else {
        CreateAccountRequests.handlePageError({ message: AccountErrorMessages.create_submit_password });
      }
    },
    // Description: handles submission of phone number and resend code actions
    // Note: for resend SMS verification click (phone_number = undefined)
    handleSubmitPhone: (params: { expires: string; phone_number?: string; }) => {
      const phone_number = !!params.phone_number ? params.phone_number : keyStorageUser?.phone_number;
      // Note: Step 6 will be set on reducer side
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.LOADING));
      if (!!phone_number && !!keyStorageUser) {
        setExpires(params.expires);
        keyStorageUser.setPhoneNumber(phone_number);
        dispatch(accountActions.setNewKSSUser(keyStorageUser));
      } else {
        CreateAccountRequests.handlePageError({ message: AccountErrorMessages.create_express_account_default });
      }
    },
    handleSubmitSMSVerificationCode: (sms_code: string) => {
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.LOADING));
      if (!!keyStorageUser && !!sms_code) {
        !!error && dispatch(uiActions.handleMessageDismiss());
        keyStorageUser.setSMSCode(sms_code);
        dispatch(accountActions.setNewKSSUser(keyStorageUser));
      } else {
        CreateAccountRequests.handlePageError({ message: AccountErrorMessages.create_express_account_validation });
      }
    },

    handleSubmitTOTPVerificationCode: () => {
      if (!!keyStorageUser && !!totp_secret) {
        keyStorageUser.setTOTPSecret(totp_secret);
        dispatch(accountActions.setNewKSSUser(keyStorageUser));
      } else {
        CreateAccountRequests.handlePageError({ message: AccountErrorMessages.create_express_account_validation });
      }
    },

    // Description: Reset all form states and go back to first step
    handleResetForms: () => {
      setUserId(undefined);
      setKeyStorageUser(undefined);
      setCreateUserData(undefined);
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_EMAIL));
    },
    handlePageError: (params: { message: string, stack?: any, displayType?: MessageDisplayType }) => {
      CreateAccountRequests.handleResetForms();
      const displayType = params.displayType || MessageHandlerDisplayType.toastr;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, displayType), params.stack));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    },
    handleChangeStep: (step: number) => {
      dispatch(accountActions.setComponentStatus(step));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((CreateAccountRequests as any)[callback] instanceof Function) {
      (CreateAccountRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      CreateAccountRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  const stepDisplayNumbers: { [key: number]: number } = {
    [CreateAccountSteps.SUBMIT_EMAIL]: 1,
    [CreateAccountSteps.SUBMIT_VERIFICATION_CODE]: 2,
    [CreateAccountSteps.SUBMIT_USER_INFORMATION]: 3,
    [CreateAccountSteps.SUBMIT_PASSWORD]: 4,
    [CreateAccountSteps.PICK_2FA_METHOD]: 5,
    [CreateAccountSteps.SUBMIT_PHONE]: 6,
    [CreateAccountSteps.SUBMIT_TOTP_CODE]: 6,
    [CreateAccountSteps.SUBMIT_SMS_CODE]: 7,
  };

  function CreateAccountForms() {
    // Update the max step number based on the user's choice
    useEffect(() => {
      if (step === CreateAccountSteps.PICK_2FA_METHOD) {
        setMaxStepNumber(6);
      } else if (step === CreateAccountSteps.SUBMIT_PHONE) {
        setMaxStepNumber(step + 1);
      }
    }, [step]);

    switch (step) {
      case CreateAccountSteps.SUBMIT_EMAIL:
      case CreateAccountSteps.SUBMIT_ERROR:
        return <EmailForm handleAction={handlePageActions} user_id={user_id}>
          <>
            <h3 className="border-bottom">Welcome to PreVeil Express!</h3>
            <p>Please enter your email address that will serve as your PreVeil <b>User ID.</b></p>
            <p>PreVeil will send an email to this address for you to create an account.</p>
          </>
        </EmailForm>;
      case CreateAccountSteps.SUBMIT_VERIFICATION_CODE:
        return !!user_id ? <VerificationForm handleAction={handlePageActions} user_id={user_id} expires={expires} /> :
          <ErrorPanel handleAction={handlePageActions} />;
      case CreateAccountSteps.SUBMIT_USER_INFORMATION:
        // NOTES:  if the user is joining an organization, give instructions to get a new invitation
        return (!!create_user_data && !!create_user_data.org_name) ?
          <ExpressUserAlert org_name={create_user_data.org_name} handleAction={handlePageActions} /> :
          <UserInformationForm handleAction={handlePageActions} />;
      case CreateAccountSteps.SUBMIT_PASSWORD:
        return <PasswordForm handleAction={handlePageActions} />;
      case CreateAccountSteps.PICK_2FA_METHOD:
        return <Pick2FAForm handleAction={handlePageActions} />;
      case CreateAccountSteps.SUBMIT_TOTP_CODE:
        return !!user_id && !!totp_secret ? <TOTPForm handleAction={handlePageActions} user_id={user_id} totp_secret={totp_secret} /> :
          <ErrorPanel handleAction={handlePageActions} />;
      case CreateAccountSteps.SUBMIT_PHONE:
        return <PhoneForm handleAction={handlePageActions} />;
      case CreateAccountSteps.SUBMIT_SMS_CODE:
        return <SMSCodeVerificationForm handleAction={handlePageActions} expires={expires} />;
      default:
        return <Loading className="in-place" />;
    }
  }

  // Description: load CreateAccountEmailForm (First onload event)
  useEffect(() => {
    handleInitCreateExpressAccount();
    return () => { // On unmount destroy the KSS store info
      dispatch(uiActions.handleMessageDismiss());
      dispatch(accountActions.destroyAccountFormStates());
    };
  }, []);

  // Description: Update the CreateAccount status when current account is set in state
  useEffect(() => {
    (!!current_account) &&
      loginComplete(current_account, account_types.express,
        `${DefaultRoutes.mail_default}?${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.new}`);
  }, [current_account]);

  function twoFAInfo() {
    return <div className="twofa-info ms-2">
      <p>PreVeil supports leading authenticator applications</p>
      {
        _.map(two_fa_methods, (method, i) => {
          return <Image data-tooltip-id="pv-tooltip"
            data-tooltip-place="bottom"
            data-tooltip-content={method.name} key={i} src={method.value} />;
        })
      }
    </div>;
  }

  return step > 0 ?
    <Row className="create-account-card">
      <Col md={7}>
        <Card className="pv-card">
          <ProgressCircles total_steps={max_step_number + 1} current_step={stepDisplayNumbers[step]} />
          <CreateAccountForms />
        </Card>
      </Col>
      <Col className="right-panel">
        {step === CreateAccountSteps.SUBMIT_TOTP_CODE && twoFAInfo()}
      </Col>
    </Row> : <Loading />;
}

export default React.memo(CreateExpressAccount);
