import keyMirror from "keymirror";
import {
  AppConfiguration, NodePermissionSet, NodePermissionType, NodePermissionTypes, NodePermissionSetRules, NodePermissionSetRuleKeys, PreveilDownloads
} from "src/common";
import _ from "lodash";

// Description: Features that require toggling between build mode and permission considerations
export const DriveFeature = keyMirror({
  Upload: null,
  CreateFolder: null,
  Move: null,
  Delete: null,
  Remove: null,
  Refresh: null,
  Download: null,
  Share: null,
  CopyLink: null,
  Sync: null,
  Lock: null,
  Rename: null,
  EditFile: null,
  Trash: null,
  VersionHistory: null,
  SelectiveSync: null,
  Details: null,
  DuplicateFile: null
});
export type DriveFeatureType = keyof typeof DriveFeature;


export interface FeatureRule {
  is_active: boolean;
  permission_required: NodePermissionTypes[];
  permission_keys?: NodePermissionSetRuleKeys[]; // NOTE: Use this key to check external permissions not associated with node_permissions
  name_restrictions?: string[];
  unlock_required?: boolean;
}

export class Feature {
  _active: boolean = false;
  rules?: FeatureRule;// { is_active: boolean; permission_required: NodePermissionTypes[] };
  constructor(
    public feature: DriveFeatureType,
    public permissions?: NodePermissionSet,
    public name?: string
  ) {
    const feature_set = AppConfiguration.buildForWeb() ? WebFeatureSet : AppFeatureSet;
    this.rules = Object.prototype.hasOwnProperty.call(feature_set, feature) ? feature_set[feature] : undefined;
    this._setActive();
  }

  // Description: Gets if the object is active for this build mode
  get active(): boolean {
    return this._active;
  }

  // Description: Sets if the object is active for this build mode
  private _setActive() {
    if (!!this.rules) {
      this._active = !!this.rules ? this.rules.is_active : true;
      if (this._active && !!this.permissions) {
        this._active = this.rules.permission_required.includes(this.permissions.type);

        // NOTE: Look at build mode first and then ONLY LOOK AT PERMISSIONS if the feature is enabled
        if (!!this.rules.permission_keys) {
          // Return inactive keys
          const _inactive = _.filter(this.rules.permission_keys, (permission_key: NodePermissionSetRuleKeys) =>
            (_.has(this.permissions, permission_key) && !this.permissions[permission_key]));
          this._active = this.active && _inactive.length <= 0;
        }

        // NOTE: Look at restricting action by name
        if (!!this.name && !!this.rules.name_restrictions) {
          this._active = this.active && !this.rules.name_restrictions.includes(this.name);
        }

        // NOTE: Look at whether the action requires the item to be unlocked
        if (!!this.rules.unlock_required) {
          this._active = this.active && !this.permissions.locked;
        }

        // NOTE: Handle effective_root_nodes look at sync flag for these nodes as the type is hard set to view_only
        if (!!this.permissions.effective_root_node) {
          this._active = this.active && !EffectiveRootNodeDisable.includes(this.feature);
        }

        // NOTE: Handle effective_root_nodes children nodes and give owner permissions to these nodes
        if (!!this.permissions.disjointed_maintainer) {
          this._active = this.active && (this.permissions.is_owner || !EffectiveRootNodeChildDisable.includes(this.feature));
        }
      }
    }
  }

  // Description: Shortcut to check build mode and permissions
  static hidden(feature: DriveFeatureType, permissions?: NodePermissionSet, name?: string) {
    return !(new Feature(feature, permissions, name).active);
  }
}

// Description: add rules to disable for EffectiveRoot Nodes (for disjointed root acl nodes)
const EffectiveRootNodeDisable: DriveFeatureType[] = [
  DriveFeature.Share,
  DriveFeature.Rename,
  DriveFeature.Move,
  DriveFeature.CreateFolder,
  DriveFeature.Upload,
  DriveFeature.Details
];

// Description: add rules to disable actions in EffectiveRoot Nodes (for disjointed root acl nodes) children nodes
const EffectiveRootNodeChildDisable: DriveFeatureType[] = [
  DriveFeature.Rename,
  DriveFeature.Move
];

// Description: Set permissions and web build mode is_active 
export const WebFeatureSet: { [key: string]: FeatureRule } = {
  Details: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit, NodePermissionType.read_only, NodePermissionType.view_only]
  },
  Upload: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  CreateFolder: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  Move: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    unlock_required: true
  },
  Delete: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    unlock_required: true
  },
  Remove: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  Refresh: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  Download: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit, NodePermissionType.read_only]
  },
  Share: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    permission_keys: [NodePermissionSetRules.share]
  },
  CopyLink: {
    is_active: false,
    permission_required: []
  },
  Sync: {
    is_active: false,
    permission_required: [],
    permission_keys: [NodePermissionSetRules.sync, NodePermissionSetRules.global_local_sync]
  },
  Lock: {
    is_active: false,
    permission_required: [],
  },
  Rename: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    unlock_required: true
  },
  EditFile: {
    is_active: false,
    permission_required: [],
    unlock_required: true
  },
  VersionHistory: {
    is_active: false,
    permission_required: []
  },
  SelectiveSync: {
    is_active: false,
    permission_required: [],
    permission_keys: [NodePermissionSetRules.global_local_sync],
  },
  DuplicateFile: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  }
};

// NOTE: Rules for App build mode
export const AppFeatureSet: { [key: string]: FeatureRule } = {
  Details: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit, NodePermissionType.read_only, NodePermissionType.view_only]
  },
  Upload: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  CreateFolder: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  Move: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    unlock_required: true
  },
  Delete: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    unlock_required: true
  },
  Remove: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  Refresh: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  Download: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit, NodePermissionType.read_only]
  },
  Share: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    permission_keys: [NodePermissionSetRules.share],
    name_restrictions: [PreveilDownloads]
  },
  CopyLink: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  },
  Sync: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit, NodePermissionType.read_only],
    permission_keys: [NodePermissionSetRules.sync, NodePermissionSetRules.global_local_sync]
  },
  Lock: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
  },
  Rename: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    unlock_required: true
  },
  EditFile: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    unlock_required: true
  },
  VersionHistory: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit, NodePermissionType.read_only, NodePermissionType.view_only]
  },
  SelectiveSync: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit],
    permission_keys: [NodePermissionSetRules.global_local_sync]
  },
  DuplicateFile: {
    is_active: true,
    permission_required: [NodePermissionType.edit_and_share, NodePermissionType.edit]
  }
};