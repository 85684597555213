import { all, fork, call, put, takeEvery, select } from "redux-saga/effects"; // call, put, 
import {
  CollectionServerUser, IFetchResponse, Event, SetApprovalGroupEventPayload, SubmitShardsToExportGroupEventPayload,
  RecoveryShard, ApproversList, ExportShard, JSONOrgInfo, RenameCollectionEventPayload
} from "@preveil-api";
import { AppSagaActionTypes, appActions, HandleRekeyAndSetApprovalGroupParams } from "./slice";
import {
  Account, AppConfiguration, CollectionAPI, CryptoAPI, dayjs, GetUsersEventsApiResponse, GlobalErrorMessages, Message,
  MessageHandlerDisplayType, generateRecoverySecrets, verifyEvent, AccountIdentifiers, createExportShards, AccountUserKey,
  rekeyData, KeyStorageData, RekeyData, KeyStorageAPI, Helpers, getPendingEventsSorted, EventHandlingErrorMessages, EventHandlingSuccessMessages, EVENT_TYPES
} from "src/common";
import { accountActions, uiActions } from "../";

// ----------------------------------------------------------------------------------
// Build information: Get crypto build information and return build mode with config
// ----------------------------------------------------------------------------------
function* fetchCryptoBuildRequest(action: ReturnType<typeof appActions.getBuildVersion>): Generator {
  const response = (yield call(CryptoAPI.getAppConfiguration)) as IFetchResponse;
  if (!response.isError) {
    // Success Block:
    yield put(appActions.getBuildVersionComplete({ ...response.data, ...action.payload }));
  } else {
    // Note: on error data will be of type MessageItem
    // yield put(appActions.AppFetchError(response));
    yield put(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default), response));
  }
}

function* watchFetchCryptoBuildRequest() {
  yield takeEvery(AppSagaActionTypes.FETCH_BUILD_REQUEST, fetchCryptoBuildRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get list of user events (including expired and handled) on Web/Express
//  NOTES:      - Triggered when we receive a NEW_EVENT topic over notify_ws
//              - Also triggered on login, in this case, the payload contains `password` and `auth_token`
//              - If triggered on login, we also need to update the state with the account_ids
// -----------------------------------------------------------------------------------------------------------------------------

// Description: Fetches the events from the collection server and calls their handler functions
function* fetchEvents(action: ReturnType<typeof appActions.getEvents>): Generator {
  const is_login = !!action.payload.password && !!action.payload.auth_token;
  const account_ids = action.payload.account_ids;
  const events_response = (yield call(CollectionAPI.getUsersEvents,
    account_ids, { user_id: account_ids.user_id, exclude_handled: true })) as IFetchResponse<GetUsersEventsApiResponse>;
  if (!!events_response.isError) {
    yield call(handleEventError, EventHandlingErrorMessages.fetching_events, is_login && account_ids, events_response);
  } else if (AppConfiguration.buildForWeb()) {
    // Handle rename and subsume events on web
    yield handleCollectionRenameEvents(events_response.data.events);
    yield handleSubsumeEvents(action.payload, events_response.data.events, account_ids, is_login);
  }
}

// Description: Filters the collection rename events and adds them to the state
function* handleCollectionRenameEvents(events: Event[]): Generator {
  const unhandled_events = (yield call(getPendingEventsSorted, events, [EVENT_TYPES.RENAME_COLLECTION])) as Event[];
  const renamed_collections: RenameCollectionEventPayload[] = [];

  for (const event of unhandled_events) {
    const parsed_payload = JSON.parse(event.payload);
    if (parsed_payload.type !== EVENT_TYPES.RENAME_COLLECTION)
      continue;
    const parsed_rename_event: RenameCollectionEventPayload = {
      ...parsed_payload,
      id: event.id
    };
    renamed_collections.push(parsed_rename_event);
  }
  yield put(appActions.addRenamedCollections(renamed_collections));
  yield put(appActions.setEventComplete());
}

function* handleSubsumeEvents(payload: any, events: Event[], account_ids: AccountIdentifiers, is_login: boolean): Generator {
  let unhandled_events: Event[] = [];
  if (is_login) {
    unhandled_events = (yield call(getPendingEventsSorted, events, [], [EVENT_TYPES.REKEY_AND_SET_APPROVAL_GROUP, EVENT_TYPES.RENAME_COLLECTION])) as Event[];
  } else {
    unhandled_events = (yield call(getPendingEventsSorted, events, [], [EVENT_TYPES.RENAME_COLLECTION])) as Event[];
  }
  if (unhandled_events.length === 0) {
    if (is_login) yield put(accountActions.getKssUserSuccess(account_ids));
    return;
  }
  const user_ids = [...new Set([
    { user_id: account_ids.user_id.toLowerCase(), key_version: -1 },
    ...unhandled_events.map(event => ({ user_id: event.user_id.toLowerCase(), key_version: event.key_version }))
  ])];
  const find_users_response = (yield call(CollectionAPI.postFindUsers, account_ids, user_ids, true)) as IFetchResponse<{ users: CollectionServerUser[] }>;
  if (!!find_users_response.isError) {
    return yield call(handleEventError, EventHandlingErrorMessages.fetching_requester, is_login && account_ids, find_users_response);
  }
  const users_by_id = Account.parseCollectionServerUserList(find_users_response.data.users);
  const current_user = users_by_id[account_ids.user_id.toLowerCase()];
  if (!current_user) {
    return yield call(handleEventError, EventHandlingErrorMessages.fetching_user, is_login && account_ids, find_users_response);
  }
  for (let i = 0; i < unhandled_events.length; i++) {
    const event = unhandled_events[i];
    if (!event.parsed_payload) return yield call(handleEventError, EventHandlingErrorMessages.parsing_payload, account_ids, event);
    const updated_account_ids = ((yield select(state => state.account.account_ids)) || account_ids) as AccountIdentifiers;
    const verified = (yield call(verifyEvent, event, users_by_id[event.user_id.toLowerCase()].public_key)) as boolean;
    if (!verified) {
      yield call(handleEventError, EventHandlingErrorMessages.unverified, is_login && updated_account_ids, event);
      continue;
    }
    const expiration = dayjs.utc(event.parsed_payload.expiration);
    if (dayjs.utc() > expiration) {
      const expired_message = `Event expired on ${expiration.toString()}, current time: ${dayjs.utc().toString()}`;
      yield call(handleEventError, expired_message, is_login && updated_account_ids, event);
      continue;
    }
    switch (event.parsed_payload.type) {
      case EVENT_TYPES.SET_APPROVAL_GROUP:
        yield call(handleSetApprovalGroup, updated_account_ids, event, is_login);
        break;
      case EVENT_TYPES.SUBMIT_SHARDS_TO_EXPORT_GROUP:
        yield call(handleSubmitExportShards,
          updated_account_ids, current_user, payload.user_keys, event, is_login);
        break;
      case EVENT_TYPES.REKEY_AND_SET_APPROVAL_GROUP:
        const params = payload as HandleRekeyAndSetApprovalGroupParams;
        params.account_ids = updated_account_ids;
        yield call(handleRekeyAndSetApprovalGroup, current_user, params, event);
    }
  }
  yield put(appActions.setEventComplete());
}

function* watchFetchEvents() {
  yield takeEvery(AppSagaActionTypes.FETCH_EVENTS, fetchEvents);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Handle set_approval_group event
// -----------------------------------------------------------------------------------------------------------------------------
function* handleSetApprovalGroup(account_ids: AccountIdentifiers, event: Event, is_login: boolean): Generator {
  const payload = event.parsed_payload as SetApprovalGroupEventPayload;
  if (payload.for_user_id.toLowerCase() !== account_ids.user_id.toLowerCase()) {
    return yield call(handleEventError, EventHandlingErrorMessages.wrong_user, is_login && account_ids, event);
  }
  const approver_ids = payload.data.approvers.map(u => ({ user_id: u.user_id, account_version: u.account_version }));
  const find_users_response = (yield call(CollectionAPI.postFindUsers, account_ids, approver_ids, true)) as IFetchResponse<{ users: CollectionServerUser[] }>;
  if (!!find_users_response.isError || find_users_response.data.users.length !== payload.data.approvers.length) {
    return yield call(handleEventError, EventHandlingErrorMessages.fetching_recovery_users, is_login && account_ids, find_users_response);
  }
  try {
    const approvers = (yield call(generateRecoverySecrets, account_ids.user_key, payload.data, find_users_response.data.users)) as RecoveryShard[];
    const request = {
      approvers,
      optionals_required: payload.data.optionals_required
    };
    yield call(handleEvent, account_ids, event, request);
  } catch (error) {
    yield call(handleEventError, EventHandlingErrorMessages.key_shards, is_login && account_ids, error);
  }
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Handle submit_shards_to_export_group event
// -----------------------------------------------------------------------------------------------------------------------------
function* handleSubmitExportShards(
  account_ids: AccountIdentifiers,
  current_user: CollectionServerUser,
  user_keys: AccountUserKey[],
  event: Event,
  is_login: boolean
): Generator {
  const org_id = current_user.entity_id;
  if (!org_id) return yield call(handleEventError, EventHandlingErrorMessages.no_org, is_login && account_ids, event);
  const payload = event.parsed_payload as SubmitShardsToExportGroupEventPayload;
  const params = {
    entityId: org_id,
    groupId: payload.data.group_id.String(),
    version: payload.data.group_version
  };
  const approval_group_response = (yield call(CollectionAPI.getUsersOrgsByEntityIdGroupsAndGroupId, account_ids, params)) as IFetchResponse<ApproversList>;
  if (!!approval_group_response.isError || approval_group_response.data.approvers.length === 0) {
    return yield call(handleEventError, EventHandlingErrorMessages.fetching_export_group, is_login && account_ids, approval_group_response);
  }
  const approver_ids = approval_group_response.data.approvers.map(approver => ({ user_id: approver.user_id, key_version: -1 }));
  const find_users_response = (yield call(CollectionAPI.postFindUsers, account_ids, approver_ids, true)) as IFetchResponse<{ users: CollectionServerUser[] }>;
  if (!!find_users_response.isError || find_users_response.data.users.length !== approval_group_response.data.approvers.length) {
    return yield call(handleEventError, EventHandlingErrorMessages.fetching_export_approvers, is_login && account_ids, find_users_response);
  }
  try {
    const approvers = (yield call(createExportShards, user_keys, find_users_response.data.users, approval_group_response.data)) as ExportShard[];
    yield call(handleEvent, account_ids, event, { approvers });
  } catch (error) {
    yield call(handleEventError, EventHandlingErrorMessages.key_shards, is_login && account_ids, error);
  }
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Handle rekey_and_set_approval_group event
// -----------------------------------------------------------------------------------------------------------------------------
function* handleRekeyAndSetApprovalGroup(
  current_user: CollectionServerUser,
  params: HandleRekeyAndSetApprovalGroupParams,
  event: Event
): Generator {
  const account_ids = params.account_ids;
  const payload = event.parsed_payload as SetApprovalGroupEventPayload;
  if (payload.for_user_id.toLowerCase() !== account_ids.user_id.toLowerCase()) {
    return yield call(handleEventError, EventHandlingErrorMessages.wrong_user, account_ids, event);
  }
  const recovery_approver_ids = payload.data.approvers.map(u => ({ user_id: u.user_id, account_version: u.account_version }));
  const find_recovery_approvers_response = (yield call(CollectionAPI.postFindUsers,
    account_ids, recovery_approver_ids, true)) as IFetchResponse<{ users: CollectionServerUser[] }>;
  if (!!find_recovery_approvers_response.isError || find_recovery_approvers_response.data.users.length !== payload.data.approvers.length) {
    return yield call(handleEventError, EventHandlingErrorMessages.fetching_recovery_users, account_ids, find_recovery_approvers_response);
  }
  const approver_users = find_recovery_approvers_response.data.users.filter(u => u.user_id.toLowerCase() !== account_ids.user_id.toLowerCase());
  const org_info_response = (yield call(CollectionAPI.getOrganizationInfo, account_ids, current_user.entity_id)) as IFetchResponse<JSONOrgInfo>;
  if (!!org_info_response.isError) {
    return yield call(handleEventError, EventHandlingErrorMessages.fetching_org_info, account_ids, org_info_response);
  }
  const export_group_info = org_info_response.data.roled_approval_groups.export_approval_group;
  let export_approver_users: CollectionServerUser[] | undefined;
  let export_group: ApproversList | undefined;
  if (export_group_info) {
    const get_export_group_params = {
      entityId: org_info_response.data.id,
      groupId: export_group_info.group_id,
      version: export_group_info.version
    };
    const export_group_response = (yield call(CollectionAPI.getUsersOrgsByEntityIdGroupsAndGroupId,
      account_ids, get_export_group_params)) as IFetchResponse<ApproversList>;
    if (!!export_group_response.isError || export_group_response.data.approvers.length === 0) {
      return yield call(handleEventError, EventHandlingErrorMessages.fetching_export_group, account_ids, export_group_response);
    }
    export_group = export_group_response.data;
    const export_approver_ids = export_group.approvers.map(u => ({ user_id: u.user_id, account_version: u.account_version }));
    const find_export_approvers_response = (yield call(CollectionAPI.postFindUsers,
      account_ids, export_approver_ids, true)) as IFetchResponse<{ users: CollectionServerUser[] }>;
    if (!!find_export_approvers_response.isError || find_export_approvers_response.data.users.length !== export_group_response.data.approvers.length) {
      return yield call(handleEventError, EventHandlingErrorMessages.fetching_export_approvers, account_ids, find_export_approvers_response);
    }
    export_approver_users = find_export_approvers_response.data.users;
  }
  const rekey_data = (yield call(rekeyData, params.user_keys[0])) as RekeyData;
  const proposed_key = rekey_data.proposed_key;
  const wrapped_data = (yield call(KeyStorageData.wrapKSSData,
    params.password, proposed_key, account_ids.device_key, proposed_key.protocol_version, account_ids.device_id)) as string;
  const rekey_user_response = (yield call(KeyStorageAPI.rekeyUser, account_ids, wrapped_data, params.auth_token)) as IFetchResponse;
  if (!!rekey_user_response.isError) {
    return yield call(handleEventError, EventHandlingErrorMessages.rekey, account_ids, rekey_user_response);
  }
  const keystorage_user = { user_id: account_ids.user_id, password: params.password };
  const new_account_ids = (yield call(KeyStorageData.unwrapKSSData, keystorage_user, wrapped_data)) as AccountIdentifiers;
  try {
    const approvers = (yield call(generateRecoverySecrets, new_account_ids.user_key, payload.data, approver_users)) as RecoveryShard[];
    let export_approvers: ExportShard[] | undefined;
    if (export_approver_users && export_group) {
      export_approvers = (yield call(createExportShards, [new_account_ids.user_key, ...params.user_keys], export_approver_users, export_group)) as ExportShard[];
    }
    const request = {
      approvers,
      optionals_required: payload.data.optionals_required,
      public_key: Helpers.b64Encode(proposed_key.public_user_key.serialize()),
      wrapped_last_key: Helpers.b64Encode(rekey_data.wrapped_last_key),
      export_approvers,
      export_group_id: export_group_info?.group_id,
      export_group_version: export_group_info?.version
    };
    yield call(handleEvent, account_ids, event, request, new_account_ids);
  } catch (error) {
    return yield call(handleEventError, EventHandlingErrorMessages.key_shards, account_ids, error, new_account_ids);
  }
}

// Description: Handles the event and displays success or failure message
function* handleEvent(account_ids: AccountIdentifiers, event: Event, request: object, new_account_ids?: AccountIdentifiers): Generator {
  const params = {
    event_id: event.id,
    body: {
      user_id: account_ids.user_id,
      request
    }
  };
  const response = (yield call(CollectionAPI.putUsersEventsByEventId, account_ids, params)) as IFetchResponse;
  if (!!response.isError) return yield call(handleEventError, EventHandlingErrorMessages.handling, new_account_ids || account_ids, event);
  let success_message = "";
  switch (event.parsed_payload?.type) {
    case EVENT_TYPES.SET_APPROVAL_GROUP:
      success_message = EventHandlingSuccessMessages.approval_group;
      break;
    case EVENT_TYPES.SUBMIT_SHARDS_TO_EXPORT_GROUP:
      success_message = EventHandlingSuccessMessages.submit_shards;
      break;
    case EVENT_TYPES.REKEY_AND_SET_APPROVAL_GROUP:
      success_message = EventHandlingSuccessMessages.rekey;
  }
  yield all([
    put(uiActions.handleSetMessage(new Message(success_message))),
    put(accountActions.getKssUserSuccess(new_account_ids || account_ids))
  ]);
}

// Description: Logging errors
function* handleEventError(text: string, account_ids: AccountIdentifiers | false, stack?: any, new_account_ids?: AccountIdentifiers): Generator {
  const message = new Message(text, MessageHandlerDisplayType.logger);
  if (!account_ids) return yield put(uiActions.handleRequestErrors(message, stack));
  yield all([
    put(uiActions.handleRequestErrors(message, stack)),
    put(accountActions.getKssUserSuccess(new_account_ids || account_ids))
  ]);
}

// ----------------------------------------------------------------
// We can also use `fork()` here to split our saga into multiple watchers.
export function* appSaga() {
  yield all([
    // fork(watchPingCryptoRequest),
    fork(watchFetchCryptoBuildRequest),
    fork(watchFetchEvents)
  ]);
}

// Exporting Sagas for testing
export { fetchCryptoBuildRequest };