import React, { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { PublicRoutes, QueryParamKeys, normalizeQueryUserId, useLogout, AppConfiguration } from "src/common";
import { Icon } from "..";
import SettingsImage from "src/assets/images/device-lock.svg";

function ExpiredKeyComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user_id, setUserId] = useState<string>();
  const { handleStateLogout } = useLogout();

  useEffect(() => {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    !!query_user_id && setUserId(query_user_id);
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }
    handleStateLogout();
  }, []);

  return (
    <div className="mx-auto max-600 mt-4">
      <h2>Account access locked on this device</h2>
      <Card className="pv-card mb-2">
        <Card.Img variant="top" src={SettingsImage} />
        <Card.Body>
          {
            !!user_id &&
            <h2 className="mb-2">
              <span>Account: </span>
              <span className="account">{user_id}</span>
            </h2>
          }
          {
            AppConfiguration.buildForWeb() ?
              <>
                <p className="semi-bold fs-3">
                  To regain access to your account on this device, please &nbsp;
                  <Link title="Already a PreVeil Express user? Sign in" to={`${PublicRoutes.login_route}`}>Sign In</Link> again.
                </p>
              </> :
              <>
                <p className="semi-bold fs-3">
                  To regain access to your account on this device, please copy your private key from one
                  of your other PreVeil devices.
                </p>
                <p>This device will remain locked until unlocked from one of your other devices.</p>
                <Button href={`/${PublicRoutes.copy_account_route}${!!user_id ? `?${QueryParamKeys.USER_ID_QUERY_KEY}=${user_id}` : ""}`}>
                  Copy Private Key</Button>
              </>
          }
        </Card.Body>
      </Card>
      <span>
        <Icon className="pv-icon-question" />
        <span>
          Learn more about locked devices{" "}
          <a target="_blank" rel="noreferrer" href="https://preveil.com/support">
            preveil.com/support
          </a>
        </span>
      </span>
    </div>
  );
}

export default React.memo(ExpiredKeyComponent);
