import { useCallback, useEffect, useState } from "react";
import { ErrorStackDataType, MessageDisplayType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import { FSMessage, SealedContent, FSRole, FSStatus, Node } from "src/common/keys/protos/collections_pb";
import {
    Account, useAppDispatch, Message, MessageHandlerDisplayType, DriveErrorMessages,
    useAppSelector, filesyncSocketApi, DriveRequest, PermissionSet, Helpers, CollectionFilesyncAPI, RenameValidationErrorMessages, useGetPermissions, useGetGrants, GrantSetType,
    MessageAnchors, getEnumKey, ErrorStackItem, encryptName, GrantSet, EntryItem, DriveEntryType, DirectoryEntity, CollectionEntity, COLLECTION_PROTOCOL_VERSIONS,
    PermissionSetType, useRenameItemMutation, UUID, AppConfiguration, RenameJob, DirectoryInfo, useGetDirectoryInfo
} from "src/common";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import _ from "lodash";


// Description: Hook used to rename a collection
export function useRename(current_account: Account, collection_info: CollectionEntity, current_directory: DirectoryEntity, entry: EntryItem) {
    const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
    const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
    const collection_id = entry.linked_collection_id ? entry.linked_collection_id : entry.collection_id;
    const [new_name, setNewName] = useState<string>();
    const [rename_error, setRenameError] = useState<boolean>();
    const [rename_success, setRenameSuccess] = useState<boolean>();
    const [sendRequest] = filesyncSocketApi.useSendRequestMutation();
    const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
    const { grants, getGrants, destroyGrants } = useGetGrants(current_account);
    const { directory_info, fetchDirectoryInfo } = useGetDirectoryInfo(current_account);
    const [app_rename] = useRenameItemMutation();
    const dispatch = useAppDispatch();

    // -----------------------------
    // Web Build Mode
    // -----------------------------
    // Description: Rename if permissions and grants are set for V2 collections
    useEffect(() => {
        // NOTE: If V1 only need permissions if V2 wait for grants to be populated
        if (!!permissions && !!new_name && (collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ||
            (collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 && !!grants))) {
            (entry.type === DriveEntryType.LINK) ? renameCollection(new_name, permissions, grants) :
                (!!directory_info && handleInitRenameEntry(new_name, permissions, directory_info, grants));
        }
    }, [permissions, grants, directory_info]);

    // Description: Initialize rename
    // FOR DIR and FILES => getParentDirectoryInformation > getParentInfo(dir, _new_name, _permissions, _grants)  > GetMaintainersScopedName > Rename
    // For ACL nodes need getACLTree for all grants
    async function handleInitRenameEntry(_new_name: string, _permissions: PermissionSetType, parent_info: DirectoryInfo, _grants?: GrantSetType) {
        const maintainer_scoped_name = (!!_grants && entry.id === collection_info.maintainer_id) ?
            await getEncryptedMaintainerScopedName(_new_name, parent_info.wrapped_dir_key.getKeyVersion() || 0, _grants) : undefined;

        const _parent_info = Object.assign({}, parent_info, { maintainer_scoped_name });
        (collection_info.is_maintainer && !!_grants) ?
            getACLTree(_new_name, _parent_info, _permissions, _grants) :
            renameEntry(_new_name, _parent_info, _permissions, !!_grants ? [_grants] : undefined);
    }

    // Description: Used to rename collection via setCollectionAttributes
    async function renameCollection(_new_name: string, _permissions: PermissionSetType, _grants?: GrantSetType) {
        const encrypted_name = await getEncryptedName(_new_name, _permissions);
        const drive_request = !!encrypted_name ? await CollectionFilesyncAPI.setCollectionAttributes(current_account, collection_id, encrypted_name, _permissions, _grants) : null;
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                setRenameSuccess(true);
            } else {
                handlePageErrorMessage(
                    RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name),
                    { stack_message: RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name) },
                    message,
                    MessageHandlerDisplayType.toastr
                );
            }
        }
        handleSendRequest(drive_request, callback, RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name));
    }

    // Description: Renames items (files/folders that aren't shared)
    async function renameEntry(_new_name: string, _parent_info: DirectoryInfo, _permissions: PermissionSetType, maintainer_grants?: GrantSetType[]) {
        let drive_request = null;
        if (!!entry.version && !!_parent_info.symmetric_key) {
            const encrypted_name = await encryptName(_new_name, _parent_info.symmetric_key);
            const new_version = randomBytes(32);
            drive_request = await CollectionFilesyncAPI.renameEntry(
                current_account,
                _permissions,
                collection_id,
                entry.id,
                entry.version,
                new_version,
                _parent_info.version,
                encrypted_name,
                _parent_info.maintainer_scoped_name,
                maintainer_grants
            );
        }

        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                setRenameSuccess(true);
            } else {
                handlePageErrorMessage(
                    RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name),
                    { stack_message: RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name) },
                    message,
                    MessageHandlerDisplayType.toastr
                );
            }
        }
        handleSendRequest(drive_request, callback, _new_name);
    }

    // Description: Encrypts the name using the ACLs reader key - use the collection_info.maintainer_id
    async function getEncryptedMaintainerScopedName(_new_name: string, wrapped_key_version: number, maintainers_grants: GrantSetType): Promise<SealedContent | undefined> {
        const grant = GrantSet.getGrantbyRole(maintainers_grants, wrapped_key_version);
        const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
        if (!!reader_user_key) {
            const maintainer_scoped_name = new SealedContent();
            const new_encrypted_name = await reader_user_key.public_user_key.public_key.seal(Helpers.utf8Encode(_new_name));
            maintainer_scoped_name.setKeyVersion(reader_user_key.key_version);
            maintainer_scoped_name.setContent(new_encrypted_name);
            return maintainer_scoped_name;
        }
    }

    // Description: Build encrypted name from new name and return null on failure
    async function getEncryptedName(_new_name: string, _permissions: PermissionSetType): Promise<SealedContent | undefined> {
        const permission_set = !!_permissions ? PermissionSet.init(_permissions) : null;
        const permission = permission_set?.permission(FSRole.READER) || permission_set?.permission(FSRole.ACCESS);
        const user_key = !!permission ? await permission.key(current_account) : null;
        const sealed_name = !!user_key ? (await user_key).encryption_key.seal(Helpers.utf8Encode(_new_name)) : null;
        const encrypted_name = new SealedContent();
        if (!!user_key && !!sealed_name) {
            encrypted_name.setKeyVersion(user_key.key_version);
            encrypted_name.setContent(await sealed_name);
            return encrypted_name;
        }
    }

    // Description: Get  ACL NODE INFORMATION
    async function getACLTree(_new_name: string, parent_info: DirectoryInfo, _permissions: PermissionSetType, _grants: GrantSetType) {
        const request = await CollectionFilesyncAPI.getACLTreeRequest(collection_id);
        const drive_request = !!permissions ? await DriveRequest.initializeRequest(request, current_account, permissions) : null;
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                // NOTE: Handle successful acl_tree
                const directory_list = message.getAclTree()?.getDirsList();
                const acl_node_grants: GrantSetType[] = [_grants];
                _.forEach(directory_list, (directory: FSMessage.Dir) => {
                    const node_grants: Node.Grant[] = directory.getAclList();
                    const maintainer_id = directory.getMaintainerId_asB64();
                    const current_grants = GrantSet.getCurrentUsersGrantSets(collection_id, maintainer_id, node_grants, current_account.user_id);
                    (!!current_grants && current_grants.id !== _grants.id) && acl_node_grants.push(current_grants);
                });
                renameEntry(_new_name, parent_info, _permissions, acl_node_grants);
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree }, message);
            }
        }
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_tree);
    }

    // -----------------------------
    // APP Build Mode
    // -----------------------------
    // Description:  - Renames collections and entries via FS
    function handleAppRename(_new_name: string) {
        const request_id = new UUID().String();
        const directory_id = (entry.id !== current_directory.id) ? current_directory.id : entry.parent_id;
        if (!!directory_id) {
            app_rename({
                user_id: current_account.user_id,
                collection_id: entry.collection_id,
                directory_id,
                entity_id: entry.id,
                type: entry.type,
                new_name: _new_name,
                request_id
            }).unwrap()
                .then(() => {
                    // Wait for rename job status if its a collection or ACL node
                    if (entry.type === DriveEntryType.LINK || (!!collection_info.maintainer_id && entry.id === collection_info.maintainer_id)) {
                        const rename_job: RenameJob = {
                            id: request_id,
                            old_name: entry.name,
                            new_name: _new_name
                        };
                        dispatch(uiActions.setRenameJob(rename_job));
                    } else {
                        setRenameSuccess(true);
                    }
                })
                .catch(() => {
                    handlePageErrorMessage(
                        RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name),
                        { stack_message: RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name) },
                        undefined,
                        MessageHandlerDisplayType.toastr
                    );
                });
        } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_app_rename });
        }
    }

    // -----------------------------
    // HOOK General
    // -----------------------------
    // Description: Sends Request and handles error when the request object is null, pass it custom stack message
    function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
        if (!!request) {
            request.setCallback(callback);
            sendRequest(request);
        } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
        }
    }

    // Description: Handles request errors and if permissions, user key or sealed name are not found
    function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
        const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
        const stack = new ErrorStackItem("[useRename Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
        dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
        setRenameError(true);
    }

    // Description: Reset state on complete
    function reset() {
        setNewName(undefined);
        setRenameError(undefined);
        setRenameSuccess(undefined);
        destroyPermissions();
        destroyGrants();
    }

    // Description: Callback for collection rename
    const rename = useCallback((name: string) => {
        if (!AppConfiguration.buildForWeb()) {
            handleAppRename(name);
        } else {
            const parent_id = (entry.id !== current_directory.id || !entry.parent_id) ? current_directory.id : entry.parent_id;
            (entry.type !== DriveEntryType.LINK) &&
                fetchDirectoryInfo(collection_id, parent_id, collection_info.maintainer_id, collection_info.collection_protocol_version);
            setNewName(name);
            getPermissions(collection_id, default_permissions);

            // NOTE: For V2 collections Get maintainer's grants
            collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 &&
                getGrants(collection_info.maintainer_id || collection_info.id, collection_id, default_permissions, default_grants);
        }
    }, []);

    return {
        rename,
        reset,
        rename_success,
        rename_error
    };
}
