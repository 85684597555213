import React from "react";
import { Button, Col, Row, InputGroup, Form } from "react-bootstrap";
import { Icon } from "src/components";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";

type AllProps = {
  is_loading: boolean;
  filter_param: string;
  handleActions: ActionHandlerFunction;
  total_items_filtered?: number;
  total_items: number;
  label: string;
  is_add_button_disabled: boolean;
  is_delete_disabled: boolean;
  is_refresh_disabled: boolean;
}

function CloudLockSettingsToolbar(props: AllProps) {
  const { filter_param, handleActions, total_items_filtered, label, total_items, is_add_button_disabled, is_delete_disabled, is_refresh_disabled, is_loading } = props;
  const users_to_show_total = total_items > 0;
  const disabled= total_items === 0;
  return (
    <Row className="toolbar admin">
      <Col xs="auto" className="d-flex ps-2 ms-1 col-sm-6">
        <InputGroup size="sm">
          <InputGroup.Text>
            <Icon className="ficon-filter" />
          </InputGroup.Text>
          <Form.Control
            disabled={disabled}
            value={filter_param}
            onChange={(e) =>
              handleActions({
                actionType: AdminUIActionTypes.SearchParam,
                params: e.target.value,
              })
            }
            type="text"
            placeholder="Filter..."
            aria-label="Filter"
          />
          {filter_param && (
            <Button
              variant="icon"
              size="sm"
              className="input-group-btn"
              onClick={() => {
                handleActions({
                  actionType: AdminUIActionTypes.Reset,
                });
              }}
            >
              <Icon className="ficon-x-circle" />
            </Button>
          )}
        </InputGroup>
        <Button
          className="ms-1 action-toolbar-buttons"
          variant="icon"
          size="sm"
          onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal })}
          disabled={is_add_button_disabled}
          title="Add user to sync permission list"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Add user to sync permission list"
        >
          <Icon className="ficon-plus" />
        </Button>
        <Button
          className="action-toolbar-buttons"
          variant="icon"
          size="sm"
          onClick={() => handleActions({ actionType: AdminUIActionTypes.Delete })}
          disabled={is_delete_disabled}
          title="Delete Selected"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Delete Selected"
        >
          <Icon className="ficon-trash-2" />
        </Button>
      </Col>
      {users_to_show_total && (
        <Col className="text-end px-0">
          <>
            {filter_param && filter_param.length >= 1 && (
              <span className="m-1">
                <b>{total_items_filtered}</b> of
              </span>
            )}
            <span className="total-addresses">
              <b>{total_items}</b>
              {label}
            </span>
          </>
        </Col>
      )}
      <Col xs="auto" className="px-0 refresh ms-auto">
        <Button
          className="action-toolbar-buttons"
          variant="icon"
          size="sm"
          onClick={() => handleActions({ actionType: AdminUIActionTypes.GetRefreshRequest })}
          disabled={is_refresh_disabled}
          title="Refresh"
          data-tooltip-id="pv-tooltip"
          data-tooltip-content="Refresh"
        >
          {is_loading ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(CloudLockSettingsToolbar);